import { Avatar, Badge, Button } from 'antd';
import { PushpinOutlined, PushpinFilled } from '@ant-design/icons';
import React, { useState } from 'react';
import { Active, ClientState, ContextItemViewModel } from './Models';
import NameIcon from '../Shared/NameIcon';

import './ContextItemSidebar.less'
import { useNavigate } from 'react-router-dom';
import { getImageUrl, isEmptyOrSpaces, logError } from '../Services/Helpers';
import { useHub } from '../Services/HubProxy';

export default function ContextItemSidebar(props: { model: ContextItemViewModel; states: ClientState[]; isCollapsed: boolean; }) {
    const hub = useHub();
    let navigate = useNavigate();
    const [collapsed, setCollapsed] = useState(false);
    const [isPinned, setIsPinned] = useState(props.model.IsPinned);
    const [contextActive, setContextActive] = useState(props.model.State);
    const [stateActive, setStateActive] = useState(false);
    const [unreadCount, setUnreadCount] = useState(props.model.UnreadMessages);
    const [unreadMessageIDs, setUnreadMessageIDs] = useState<string[]>([]);
    const [title, setTitle] = useState(props.model.Title);
    const [shorthandSymbol, setShorthandSymbol] = useState(props.model.ShorthandSymbol);
    const [thumbnail, setThumbnail] = useState(props.model.Thumbnail);
    const [color, setColor] = useState(props.model.Color);
    const [stateColor, setStateColor] = useState("orange");

    React.useEffect(() => {
        setCollapsed(props.isCollapsed);
    }, [props.isCollapsed]);

    React.useEffect(() => {
        if (props.model) {
            setTitle(props.model.Title);
            setShorthandSymbol(props.model.ShorthandSymbol);
            setThumbnail(props.model.Thumbnail);
            setColor(props.model.Color);
        }
    }, [props.model]);

    hub.subContextClientStatesResult(props.model.ID, (state) => {
        try {
            if (state) {
                if (state.State === "online") {
                    if (contextActive !== Active.Active) {
                        setContextActive(Active.Active);
                        setStateColor("#52c41a");
                        setStateActive(true);
                        setUnreadMessageIDs([]);
                    }
                } else if (state.State === "activebackground") {
                    if (contextActive !== Active.ActiveBackground) {
                        setContextActive(Active.ActiveBackground);
                        setStateColor("#e2b639");
                        setStateActive(true);
                        setUnreadMessageIDs([]);
                    }
                } else {
                    if (contextActive !== Active.None) {
                        setContextActive(Active.None);
                        setStateActive(false);
                        setUnreadMessageIDs([]);
                    }
                }
            }
        } catch (error) {
            logError(error);
        }
    });

    hub.subIncomingMessageResult(props.model.ID, (message) => {
        try {
            if (message) {
                let msgID = unreadMessageIDs.find(x => {
                    if (x === message.ID) {
                        return true;
                    }
                });
                if (msgID) {
                    return;
                }
                unreadMessageIDs.push(message.ID);
                setUnreadMessageIDs(unreadMessageIDs);
                const unread = unreadCount;
                setUnreadCount(unread + 1);
            }
        } catch (error) {
            logError(error);
        }
    });

    hub.subUnreadResetMessage(props.model.ID, () => {
        try {
            setUnreadCount(0);
        } catch (error) {
            logError(error);
        }
    });

    const onBadgeClick = () => {
        navigate("/chat/" + props.model.ID);
        hub.resetUnread(props.model.ID);
    }

    const onPinClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
    }

    return (
        <div className="user-badge sol-it-select-item"
            onClick={onBadgeClick}
            style={collapsed ? { paddingRight: "0px", display: "flex" } : { display: "flex" }}>
            <span>
                <Badge dot={stateActive ? true : undefined} className='sidebar-state-badge' style={collapsed ? { top: "35px", right: "4px", width: "12px", height: "12px" } : { top: "35px", right: "0px", width: "12px", height: "12px" }}
                    color={stateColor} size="default">
                    <Badge count={unreadCount} style={collapsed ? { right: '7px', top: '2px' } : { top: '2px' }}>
                        <Avatar src={!isEmptyOrSpaces(thumbnail) ? getImageUrl(thumbnail) : ''} 
                            style={{ backgroundColor: !isEmptyOrSpaces(color) ? color : "orange", verticalAlign: 'middle' }} size="large">
                            {
                                !isEmptyOrSpaces(shorthandSymbol) ? shorthandSymbol : title.substring(0, 2)
                            }
                        </Avatar>
                    </Badge>
                </Badge>
            </span>
            {
                (collapsed === false) ?
                    (
                        <>
                            <span className='context-item-sidebar-title'>{title}</span>
                            {/*<div className="context-pin">
                                <Button ghost={true} shape='circle' onClick={onPinClick}>
                                    {
                                        (isPinned) ?
                                            (
                                                <PushpinFilled rotate={90} />
                                            )
                                            :
                                            (
                                                <PushpinOutlined />
                                            )
                                    }
                                </Button>
                                </div>*/}
                        </>
                    ) : ''
            }
        </div >
    )
};