import { useState } from 'react';
import { acceptOffer, hangup, initWebRTC, offer, registerAnswerEventCallback, registerLeaveEventCallback, registerLocalAudioCallback, registerOfferEventCallback, registerRemoteAudioCallback, registerStartOfferCallback } from '../Shared/Voice';
import { logError } from './Helpers';

export class CallerModel {
    CallKey!: string;
    ContextIDs!: string[];
    Data?: string;
}

export class Call {
  
    callbackIncomingCall: ((caller: CallerModel) => void) | null = null;
    subIncomingCall(callback: (caller: CallerModel) => void) {
        this.callbackIncomingCall = callback;
    }

    invokeIncomingCall(caller: CallerModel) {
        try {
            if (this.callbackIncomingCall) {
                this.callbackIncomingCall(caller);
            }   
        } catch (error) {
            logError(error);
        }
    }

    callbackLeaveCall: ((caller: CallerModel) => void) | null = null;
    subLeaveCall(callback: (caller: CallerModel) => void) {
        this.callbackLeaveCall = callback;
    }

    callbackAnswerCall: ((caller: CallerModel) => void) | null = null;
    subAnswerCall(callback: (caller: CallerModel) => void) {
        this.callbackAnswerCall = callback;
    }

    callbackRemoteAudio: ((stream: any) => void) | null = null;
    subRemoteAudio(callback: (stream: any) => void) {
        this.callbackRemoteAudio = callback;
    }

    callbackLocalAudio: ((stream: any) => void) | null = null;
    subLocalAudio(callback: (stream: any) => void) {
        this.callbackLocalAudio = callback;
    }

    callbackStartingOffer: ((caller: CallerModel) => void) | null = null;
    subStartingOffer(callback: (caller: CallerModel) => void) {
        this.callbackStartingOffer = callback;
    }

    init() {
        try {
            registerRemoteAudioCallback((src: any) => {
                try {
                    if (this.callbackRemoteAudio) {
                        this.callbackRemoteAudio(src);
                    }   
                } catch (error) {
                    logError(error);
                }
            });
            registerLocalAudioCallback((src: any) => {
                try {
                    if (this.callbackLocalAudio) {
                        this.callbackLocalAudio(src);
                    }   
                } catch (error) {
                    logError(error);
                }
            });
            registerOfferEventCallback((data: any, contextIDs: string[], callKey: string) => {
                try {
                    if (this.callbackIncomingCall) {
                        this.callbackIncomingCall({
                            CallKey: callKey,
                            ContextIDs: contextIDs,
                            Data: data,
                        });
                    }   
                } catch (error) {
                    logError(error);
                }
            });
            registerLeaveEventCallback((data: any, contextIDs: string[], callKey: string) => {
                try {
                    if (this.callbackLeaveCall) {
                        this.callbackLeaveCall({
                            CallKey: callKey,
                            ContextIDs: contextIDs,
                            Data: data,
                        });
                    }   
                } catch (error) {
                    logError(error);
                }
            });
            registerAnswerEventCallback((data: any, contextIDs: string[], callKey: string) => {
                try {
                    if (this.callbackAnswerCall) {
                        this.callbackAnswerCall({
                            CallKey: callKey,
                            ContextIDs: contextIDs,
                            Data: data,
                        });
                    }   
                } catch (error) {
                    logError(error);
                }
            });
            registerStartOfferCallback((data: any, contextIDs: string[], callKey: string) => {
                try {
                    if (this.callbackStartingOffer) {
                        this.callbackStartingOffer({
                            CallKey: callKey,
                            ContextIDs: contextIDs,
                            Data: data,
                        });
                    }   
                } catch (error) {
                    logError(error);
                }
            });
            initWebRTC();
        } catch (error) {
            logError(error);
        }
    }

    async hangup(caller: CallerModel) {
        try {
            await hangup();
        } catch (error) {
            logError(error);
        }
    }

    accept(caller: CallerModel) {
        try {
            acceptOffer(caller);
        } catch (error) {
            logError(error);
        }
    }
    
    start(callKey: string, contextIDs: string[]) {
        try {
            offer(callKey, contextIDs);
        } catch (error) {
            logError(error);
        }
    }
}

const call: Call = new Call();

export function useCall() {
    const [c] = useState(call);
    return c;
}
