import React, { useState } from 'react';
import { Table, Tag, Space, Button, Input } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

import './SessionView.less'
import { useHub } from '../Services/HubProxy';
import { ConnectSession } from './Models';
import { isEmptyOrSpaces, logError } from '../Services/Helpers';

const { Search } = Input;

export default function SessionView() {
  const hub = useHub();
  const [sessions, setSessions] = useState<ConnectSession[]>([]);
  const [searchText, setSearchText] = useState<string>("");

  hub.subConnectedSessionsResult((data) => {
    try {
      setSessions(data);
    } catch (error) {
      logError(error);
    }
  });

  const onLoadSessions = () => {
    hub.queryConnectedSessions();
  }

  const onFilterType = (value: any, record: any): boolean => {
    try {
      return record.Type.indexOf(value) === 0;
    } catch (error) {
      logError(error);
    }
    return false;
  }

  const getFilteredItems = (): ConnectSession[] => {
    try {
      return sessions.filter(s => s.ClientId?.toLowerCase().includes(searchText.trim())
        || s.ServerId?.toLowerCase().includes(searchText.trim())
        || s.Type?.toLowerCase().includes(searchText.trim()));
    } catch (error) {
      logError(error);
    }
    return [];
  }

  const onSearch = (value: any) => {
    try {
      if (isEmptyOrSpaces(value)) {
        setSearchText(value);
      } else {
        setSearchText(value.toLowerCase());
      }
    } catch (error) {
      logError(error);
    }
  }

  const columns: any = [
    {
      title: 'Type',
      dataIndex: 'Type',
      key: 'type',
      filters: [
        {
          text: 'Listener',
          value: 'listener',
        },
        {
          text: 'Sender',
          value: 'sender',
        },
      ],
      filterSearch: true,
      onFilter: onFilterType,
    },
    {
      title: 'Server',
      dataIndex: 'ServerId',
      key: 'serverid',
    },
    {
      title: 'ClientId',
      dataIndex: 'ClientId',
      key: 'clientid',
    },
    {
      title: 'DeviceId',
      dataIndex: 'DeviceId',
      key: 'deviceid',
    },
  ];

  return (
    <div className='session-wrapper'>
      <div style={{ display: 'flex' }}>
        <Space>
          <Button style={{ marginBottom: '15px' }} type="primary" shape="default" icon={<ReloadOutlined />} onClick={onLoadSessions} />
          <Search style={{ marginBottom: "10px" }} placeholder="Suche" onSearch={onSearch} enterButton allowClear />
        </Space>
      </div>
      <Table scroll={{ y: 'calc(100vh - 300px)' }} columns={columns} dataSource={getFilteredItems()} />
    </div>
  );
};