import React, { useState } from 'react';

import './MessageContentText.less'
import { ChatItemViewModel } from './Models';

export default function MessageContentText(props: { model: ChatItemViewModel; }) {
    /*const [contexts, setContexts] = useState<ContextItemViewModel[]>([]);
    const [clientStates, setClientStates] = useState<ClientState[]>([]);

    React.useEffect(() => {
        setContexts(props.items);
    }, [props.items]);*/

    return (
        <div className='chat-text-content' dangerouslySetInnerHTML={{ __html: props.model.RawContent }}>
            
        </div>
    );
};