import React, { useState } from 'react';
import { Table, Tag, Space, Button, Popconfirm, Input } from 'antd';
import { ReloadOutlined, EditOutlined, DeleteOutlined, QuestionCircleOutlined, PlusOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

import './UserManagmentView.less'
import { useHub } from '../Services/HubProxy';
import { isEmptyOrSpaces, logError } from '../Services/Helpers';
import ContextEdit from '../Context/ContextEdit';
import { ContextItemViewModel } from '../Context/Models';
import { UserViewModel } from '../User/Model';
import AddLocalUser from './AddLocalUser';
import EditContextVisibility from './EditContextVisibility';
import { UserManagmentViewItem } from './Models';
import EditUserPassword from '../User/EditUserPassword';
import { PasswordIcon } from '../Shared/CustomIcons';

const { Search } = Input;

export default function UserManagmentView() {
  const hub = useHub();
  const [items, setItems] = useState<UserManagmentViewItem[]>([]);
  const [context, setContext] = useState<ContextItemViewModel | undefined>();
  const [showEditContext, setShowEditContext] = useState(false);
  const [showEditContextId, setShowEditContextId] = useState("");
  const [showAddUser, setShowAddUser] = useState(false);
  const [showEditContextVisibility, setShowEditContextVisibility] = useState(false);
  const [searchText, setSearchText] = useState<string>("");
  const [showEditUserPW, setShowEditUserPW] = useState(false);
  const [userId, setUserId] = useState<string>("");

  hub.subUsersResult((data) => {
    try {
      setItems(data);
    } catch (error) {
      logError(error);
    }
  });

  hub.subContextEntryUserManagmentResult((data) => {
    try {
      if (data.ID === showEditContextId) {
        setContext(data);
        setShowEditContext(true);
        setShowEditContextId("");
      }
    } catch (error) {
      logError(error);
    }
  });

  const onLoadItems = () => {
    hub.queryUserManagment();
  }

  const onShowRecord = (record: UserManagmentViewItem) => {
    try {
      const contextId = record.Id;
      setShowEditContextId(contextId);
      hub.queryContextManagmentEntry(contextId);
    } catch (error) {
      logError(error);
    }
  }

  const onDeleteRecord = (record: UserManagmentViewItem) => {
    try {
      //hub.queryContextManagmentEntryDelete(record.Id);
    } catch (error) {
      logError(error);
    }
  }

  const onAddNewUser = () => {
    try {
      setShowAddUser(true);
    } catch (error) {
      logError(error);
    }
  }

  const onEditContextVisible = (record: UserManagmentViewItem) => {
    try {
      const contextId = record.Id;
      setShowEditContextId(contextId);
      setShowEditContextVisibility(true);
    } catch (error) {
      logError(error);
    }
  }

  const getFilteredItems = (): UserManagmentViewItem[] => {
    try {
      return items.filter(s => s.Id?.toLowerCase().includes(searchText.trim())
        || s.Name?.toLowerCase().includes(searchText.trim())
        || s.Domain?.toLowerCase().includes(searchText.trim()));
    } catch (error) {
      logError(error);
    }
    return [];
  }

  const onSearch = (value: any) => {
    try {
      if (isEmptyOrSpaces(value)) {
        setSearchText(value);
      } else {
        setSearchText(value.toLowerCase());
      }
    } catch (error) {
      logError(error);
    }
  }

  const columns: any = [
    {
      title: 'Id',
      dataIndex: 'Id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'Name',
      key: 'name',
    },
    {
      title: 'Domain',
      dataIndex: 'Domain',
      key: 'domain',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: UserManagmentViewItem) => (
        <Space size="middle">
          <Button type="primary" shape="default" icon={<EditOutlined />} onClick={() => onShowRecord(record)} />
          <Button type="primary" shape="default" icon={<EyeInvisibleOutlined />} onClick={() => onEditContextVisible(record)} />
          <Button type="primary" shape="default" icon={<PasswordIcon />} onClick={() => onChangeUserPW(record)} />
          {/*<Popconfirm
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            title={<><p>{"Sicher diesen Benutzer löschen?"}</p><p>{"Hier wird der Login für den Benutzer gelöscht."}</p></>}
            onConfirm={() => onDeleteRecord(record)}
            okText="Ja"
            cancelText="Nein"
          >
            <Button type="primary" danger shape="default" icon={<DeleteOutlined />} />
      </Popconfirm>*/}
        </Space>

      ),
    },
  ];

  const onChangeUserPW = (record: UserManagmentViewItem) => {
    try {
        setUserId(record.Id);
        setShowEditUserPW(true);
    } catch (error) {
      logError(error);
    }
  }

  const onCloseEditPW = () => {
    try {
      setUserId("");
      setShowEditUserPW(false);
    } catch (error) {
      logError(error);
    }
  };

  return (
    <div className='message-context-statistics-wrapper'>
      <div style={{ display: 'flex' }}>
        <Space>
          <Button style={{ marginBottom: '15px' }} type="primary" shape="default" icon={<ReloadOutlined />} onClick={onLoadItems} />
          <Button style={{ marginBottom: '15px', marginLeft: '15px' }} type="primary" shape="default" icon={<PlusOutlined />} onClick={onAddNewUser}>Neuen Benutzer hinzufügen</Button>
          <Search style={{ marginBottom: "10px" }} placeholder="Suche" onSearch={onSearch} enterButton allowClear />
        </Space>
      </div>
      <Table scroll={{ y: 'calc(100vh - 300px)' }} columns={columns} dataSource={getFilteredItems()} />
      <ContextEdit hideContextSelect={false} model={context} show={showEditContext} closeCallback={() => setShowEditContext(false)} />
      <AddLocalUser show={showAddUser} closeCallback={() => setShowAddUser(false)} />
      <EditUserPassword show={showEditUserPW} closeCallback={() => onCloseEditPW()} userName={userId} />
      <EditContextVisibility contextId={showEditContextId} show={showEditContextVisibility} closeCallback={() => setShowEditContextVisibility(false)} />
    </div>
  );
};