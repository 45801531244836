export class LoginViewModel {
    HandshakeKey!: string;
    Token!: string;
    Url?: string;
    User!: string;
    Password!: string;
}

export class UserLogoutRequest {
    userID!: string;
    deviceID!: string;
    sessionID!: string;
}

export enum PermissionRoleRights {
    Extern = 0,
    User = 1,
    Moderator = 2,
    Administrator = 4,
    RootAdministrator = 8
}

export class UserViewModel {
    ID!: string;
    Name!: string;
    ExternalID?: string;
    Avatar?: Uint8Array;
    AvatarColor?: string;
    AvatarName?: string;
    Domain!: string;
    Host!: string;
    RoleRights: PermissionRoleRights = PermissionRoleRights.Extern;
    Rights?: string;
    ShorthandSymbol?: string;
    Thumbnail?: string;
    Color?: string;
}

export class CurrentUserViewModel extends UserViewModel {
    Authenticated: boolean = false;
    TokenJSON?: string;
    VisibleContextIds?: string;
    IsLocalUser: boolean = false;
}

export class UserLoginResponse {
    SessionID?: string;
    User!: CurrentUserViewModel;
    VisibleContextIds?: string;
}

export class UserAfterLogin {
    
}