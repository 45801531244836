import { useState } from "react";
import { logError } from "../Services/Helpers";

export default class NativeWrapper {
    isDesktop() {
        try {
            if (window.electronNativeAPI) {
                return window.electronNativeAPI.desktop;
            }
        } catch (error) {
            logError(error);
        }
        return false;
    }

    isPWA() {
        try {
            // For iOS
            if (window.navigator.standalone) return true

            // For Android
            if (window.matchMedia('(display-mode: standalone)').matches) return true
        } catch (error) {
            logError(error);
        }
        return false
    }

    logAPI() {
        console.log(window.electronNativeAPI);
    }

    notification(title, body) {
        try {
            if (!this.isDesktop()) {
                Notification.requestPermission(function (status) {
                    console.log('Notification permission status:', status);
                });
            }
            new Notification(title, { body: body });
        } catch (error) {
            logError(error);
        }
    }

    setNotificationCount(count) {
        try {
            if (window.electronNativeAPI) {
                window.electronNativeAPI.setNotificationCount(count);
            }
        } catch (error) {
            logError(error);
        }
    }

    hookUrlOpen() {
        try {
            if (window.electronNativeAPI) {
                document.body.addEventListener('click', event => {
                    if (event.target.tagName.toLowerCase() === 'a'
                        && event.target.protocol !== 'file:'
                        && event.target.origin !== 'http://localhost:3000'
                        && event.target.origin !== 'https://croco.sol-it.at'
                    ) {
                        event.stopImmediatePropagation();
                        event.preventDefault();
                        window.electronNativeAPI.openLink(event.target.href);
                    }
                });
            }
        } catch (error) {
            logError(error);
        }
    }

    listenerNodeError() {
        try {
            if (window.electronNativeAPI) {
                window.electronNativeAPI.handleNodeError((event, value) => {
                    console.log(value);
                })
            }

        } catch (error) {
            logError(error);
        }
    }

    reload() {
        try {
            if (window.electronNativeAPI) {
                window.electronNativeAPI.reload();
            }
        } catch (error) {
            logError(error);
        }
    }

    devtools() {
        try {
            if (window.electronNativeAPI) {
                window.electronNativeAPI.devtools();
            }
        } catch (error) {
            logError(error);
        }
    }
}

const nativeWrapper = new NativeWrapper();

export function useNative() {
    const [native] = useState(nativeWrapper);

    return native;
}