import { UserViewModel } from "../User/Model";

export class MessageHistorySearch {
    SearchKeyValues: string[] = [];
}

export enum MessageType {
    Text = 0,
    Status = 1,
    Image = 2,
    File = 3
}


export class ChatItemSendState {
    ContextID!: string;
    MessageID!: string;
    IsSending: boolean = false;
    TransferOK: boolean = false;
    TransferMessage?: string = undefined;
}

export class ChatItemViewModel {
    ID!: string;
    Time!: Date;
    ContextID!: string;
    Sender?: UserViewModel;
    RawContent!: string;
    Type!: MessageType;
    ShowHeaderInfo: boolean = true;
    IsCurrentUser!: boolean;
    Edit?: Date = undefined;
    EditPreviousRawContext?: string = undefined;
    EditClientId?: string = undefined;
    RichData?: string = undefined;
}

export class MessageCount {
    ContextID!: string;
    Count!: number;
    Timestamp: Date = new Date();
}