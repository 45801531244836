import React, { useState } from 'react';
import { Avatar, Badge, Button, Divider, Layout, Menu, message, Input, Row, Col } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  MenuOutlined,
  DownloadOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import './App.less';
import Login from './User/Login';
import { BrowserRouter, Link, Route, Routes, Navigate, useInRouterContext, useNavigate, useLocation } from 'react-router-dom';
import { LoginViewModel, UserLoginResponse, UserViewModel } from './User/Model';
import { getImageUrl, isEmptyOrSpaces, logError, playRinging } from './Services/Helpers';
import { useLocalStorage } from './Services/LocalStorage';
import Dashboard from './User/Dashboard';
import ChatView from './Chat/ChatView';
import ContextSidebar from './Context/ContextSidebar';
import { Active, ContextItemViewModel } from './Context/Models';
import { useHub } from './Services/HubProxy';
import ChangeLog from './Info/ChangeLog';
import RequiredAuth from './Shared/RequiredAuth';
import { db } from './Services/DB';
import AdminView from './Administration/AdminView';
import ContextEdit from './Context/ContextEdit';
import notification, { NotificationPlacement } from 'antd/lib/notification';
import { CallerModel, useCall } from './Services/Call';
import CallerInfo from './Info/CallerInfo';

const { Header, Sider, Content } = Layout;
const { Search } = Input;

export default function App() {
  let navigate = useNavigate();
  const hub = useHub();
  const storage = useLocalStorage();
  const call = useCall();
  call.init();
  const [collapsed, setCollapsed] = useState(false);
  const [validLogin, setValidLogin] = useState(false);
  const [contexts, setContexts] = useState<ContextItemViewModel[]>([]);
  const [contextsSidebar, setContextsSidebar] = useState<ContextItemViewModel[]>([]);
  const [currentUser, setCurrentUser] = useState<UserViewModel | null>(null);
  const [stateColor, setStateColor] = useState("orange");
  const [stateActive, setStateActive] = useState(false);
  const [loginResponse, setLoginResponse] = useState<UserLoginResponse | undefined>(undefined);
  const [editContext, setEditContext] = useState<ContextItemViewModel | undefined>();
  const [showEditContext, setShowEditContext] = useState(false);
  const [sidebarSearchValue, setSidebarSearchValue] = useState("");

  const callerNotificationCloseCallback = async (caller: CallerModel) => {
    try {
      notification.close("1");
      await call.hangup(caller);
    } catch (error) {
      logError(error);
    }
  }

  const openCallerNotification = (caller: CallerModel, startingCall: boolean) => {
    notification.open({
      key: "1",
      message: `Call => ${JSON.stringify(caller.ContextIDs)}`,
      description: (
        <CallerInfo caller={caller} startingCall={startingCall} closeCallback={callerNotificationCloseCallback}/>
      ),
        placement: 'topRight',
        duration: null,
        onClose: () => {
          console.log('Close Caller notification');
        },
        btn: undefined,
        closeIcon: <></>,
    });
  };

  call.subIncomingCall((caller: CallerModel) => {
    try {
      openCallerNotification(caller, false);
      playRinging();
    } catch (error) {
      logError(error);
    }
  });

  call.subLeaveCall((caller: CallerModel) => {
    try {
      notification.close("1");
    } catch (error) {
      logError(error);
    }
  });

  call.subAnswerCall((caller: CallerModel) => {
    try {
      openCallerNotification(caller, false);
    } catch (error) {
      logError(error);
    }
  });

  call.subStartingOffer((caller: CallerModel) => {
    try {
      openCallerNotification(caller, true);
    } catch (error) {
      logError(error);
    }
  });

  const onReceiveLogin = async (response: UserLoginResponse) => {
    try {
      if (response.User.TokenJSON !== undefined
        && response.User.TokenJSON !== null
        && response.User.TokenJSON !== ""
        && response.User !== null
        && response.User.ID !== null
        && response.User.ID !== ""
        && response.User.Name !== null
        && response.User.Name !== ""
        && response.User.ID !== null
        && response.User.ID !== "") {
        storage.setClientId(response.User.ID);
        storage.setHomeUrl(response.User.Host);
        storage.setToken(response.User.TokenJSON);
        storage.setUser(response.User);
        setCurrentUser(response.User);
        setValidLogin(true);
        hub.contextListRequestAndLoadCache(true);
        navigate("/dashboard");
        message.success('Erfolgreich angemeldet.');
      } else {
        storage.setUser(undefined);
        setLoginResponse(response);
        navigate("/");
        message.error('Anmeldung fehlgeschlagen.');
      }
    } catch (error) {
      logError(error);
    }
  }

  hub.subReceiveLoginResult(onReceiveLogin);

  hub.subReceiveContextListResult((contexts) => {
    try {
      setContextsSidebar(contexts);
      if (isEmptyOrSpaces(sidebarSearchValue)) {
        setContexts(contexts);
      }
    } catch (error) {
      logError(error);
    }
  });

  hub.subCurrentUserClientState((userState) => {
    try {
      /*
      const now = new Date();
      const dif = Math.abs(now.getTime() - userState.Timestamp.getTime()); 
      if (dif < 100) {
        setStateColor("#52c41a");
      } else if (dif < 500) {
        setStateColor("#cdff36");
      } else {
        setStateColor("#ff8936");
      }*/

      // TODO: because of the current cache implementation which provides `ClientStatesReceived` data the time calculation no longer is correct
      setStateColor("#52c41a");
      setStateActive(true);
    } catch (error) {
      logError(error);
    }
  });

  React.useEffect(() => {
    //onUpdateValidLoginUser();
    //console.log(`Effect for currentUser`);
  }, [currentUser]);


  const onLogin = (userName: string, password: string) => {
    var login = new LoginViewModel();
    login.User = userName;
    login.Password = password;
    hub.login(login);
  }

  const onLogout = () => {
    //console.log("Logout user should be done!");
    hub.logout({
      deviceID: storage.getDeviceId(),
      sessionID: storage.getToken() || "",
      userID: storage.getClientId()
    });
    setCurrentUser(null);
    setValidLogin(false);
    setContexts([]);
    setStateColor("#ff8936");
    setStateActive(false);
    storage.setUser(undefined);
    storage.setToken("");
    storage.setClientId("");
    storage.setHomeUrl("");
    storage.setNotificationVolume(0.5);
    db.clean();
    navigate("/");
  }


  const onMenuClick = () => {
    navigate("/dashboard");
  }

  const onCollapseToggleClick = () => {
    setCollapsed(!collapsed);
  }

  const onNewContextClick = () => {
    try {
      var entry: ContextItemViewModel = {
        ID: "",
        ChildContexts: [],
        Domain: "",
        HomeServer: "",
        IsPinned: false,
        KnownType: "",
        OrderBy: 0,
        State: Active.Active,
        Title: "",
        UnreadMessages: 0,
        IsHidden: false,
        OrderChanged: 0,
      };
      setEditContext(entry);
      setShowEditContext(true);
    } catch (error) {
      logError(error);
    }
  }

  const onChangeSearch = (value: string) => {
    try {
      if (isEmptyOrSpaces(value)) {
        // reset
        setContexts(contextsSidebar);
      } else {
        // filter user input include "hidden"
        let filteValue = value.trim().toLowerCase();
        db.contexts.toArray().then(contexts => {
          if (contexts && contexts.length > 0) {
            var filteredContexts = contexts.filter(s => s.Domain?.toLowerCase().includes(filteValue)
        || s.ShorthandSymbol?.toLowerCase().includes(filteValue)
        || s.Title?.toLowerCase().includes(filteValue));
            setContexts(filteredContexts);      
          }
        });
      }
    } catch (error) {
      logError(error);
    }
  }

  const onSearch = (value: string) => {
    setSidebarSearchValue(value);
    onChangeSearch(value);
  }

  const onSearchChange = (e: any) => {
    if (isEmptyOrSpaces(e.target.value) && !isEmptyOrSpaces(sidebarSearchValue)) {
      setSidebarSearchValue("");
      onChangeSearch("");
    }
  }

  return (
    <Layout>
      <Sider breakpoint="sm"
        collapsedWidth={60}
        onBreakpoint={broken => {
          setCollapsed(broken);
        }} trigger={null} collapsible collapsed={collapsed} className="main-sider">
        {
          (validLogin) ?
            (
              <>
                <div className='current-user-panel'>
                  <Badge dot={stateActive ? true : undefined} className='sidebar-state-badge' style={{ top: "35px", right: "0px", width: "12px", height: "12px" }}
                    color={stateColor} size="default">
                    <Avatar src={!isEmptyOrSpaces(currentUser?.Thumbnail) ? getImageUrl(currentUser?.Thumbnail) : ''}
                      style={{ backgroundColor: !isEmptyOrSpaces(currentUser?.Color) ? currentUser?.Color : "orange", verticalAlign: 'middle' }} size="large">
                      {
                        !isEmptyOrSpaces(currentUser?.ShorthandSymbol) ? currentUser?.ShorthandSymbol : currentUser?.Name.substring(0, 2)
                      }
                    </Avatar>
                  </Badge>
                  {
                    !collapsed ?
                      (
                        <>
                          <span className='current-user-panel-name'>{currentUser?.Name}</span>
                          <Button onClick={onMenuClick} shape="circle" ghost icon={<MenuOutlined />} />
                        </>
                      ) : ''
                  }
                </div>
                <div style={{ display: 'flex' }}>
                  {
                    collapsed === false ?
                      (
                        <>
                        <Search placeholder="Suche" onSearch={onSearch} onChange={onSearchChange} enterButton allowClear={true} />
                        <Button onClick={onNewContextClick} type="primary" shape="default" icon={<PlusOutlined />}/>
                        </>
                      ) : ''
                  }
                </div>
                <Divider style={{ margin: "5px" }} />
                <ContextSidebar items={contexts} isCollapsed={collapsed} searchValue={sidebarSearchValue} />
                <div>
                  <Button style={{ width: "100%" }} onClick={onCollapseToggleClick} type="primary" shape="default" icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />} />
                </div>
              </>
            )
            :
            (
              <>
                <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
                  <Menu.Item key="1" icon={<UserOutlined />}>
                    <Link to="/">
                      Anmelden
                    </Link>
                  </Menu.Item>
                </Menu>
              </>
            )
        }

      </Sider>
      <Layout className="site-layout">
        <Content className="site-layout-background">
          <Routes>
            <Route path="/dashboard" element={
              <RequiredAuth children={<Dashboard onLogout={onLogout} />} validLogin={validLogin} />
            }>
            </Route>
            <Route path="/changelog" element={
              <ChangeLog />
            }>
            </Route>
            <Route path="/chat/:contextId" element={
              <RequiredAuth children={<ChatView />} validLogin={validLogin} />
            }>
            </Route>
            <Route path="/administration" element={
              <RequiredAuth children={<AdminView />} validLogin={validLogin} />
            }>
            </Route>
            <Route path="*" element={
              <Login onLogin={onLogin} loginResponse={loginResponse} />
            }>
            </Route>
          </Routes>
          <ContextEdit hideContextSelect={false} model={editContext} show={showEditContext} closeCallback={() => setShowEditContext(false)} />
        </Content>
      </Layout>
    </Layout>
  );
}