import React, { useState } from 'react';
import { Table, Tag, Space, Button, Input } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

import './MessageContextStatisticsView.less'
import { useHub } from '../Services/HubProxy';
import { MessageContextStatistic } from './Models';
import { isEmptyOrSpaces, logError } from '../Services/Helpers';

const { Search } = Input;

export default function MessageContextStatisticsView() {
  const hub = useHub();
  const [items, setItems] = useState<MessageContextStatistic[]>([]);
  const [searchText, setSearchText] = useState<string>("");

  hub.subMessageContextStatisticResult((data) => {
    try {
      setItems(data);
    } catch (error) {
      logError(error);
    }
  });

  const onLoadItems = () => {
    hub.queryMessageContextStatistics();
  }

  const onFilterType = (value: any, record: any): boolean => {
    try {
      return record.RawType.indexOf(value) === 0;
    } catch (error) {
      logError(error);
    }
    return false;
  }

  const getFilteredItems = (): MessageContextStatistic[] => {
    try {
      return items.filter(s => s.ContextId?.toLowerCase().includes(searchText.trim())
        || s.ReverseContextId?.toLowerCase().includes(searchText.trim())
        || s.RawType?.toLowerCase().includes(searchText.trim()));
    } catch (error) {
      logError(error);
    }
    return [];
  }

  const onSearch = (value: any) => {
    try {
      if (isEmptyOrSpaces(value)) {
        setSearchText(value);
      } else {
        setSearchText(value.toLowerCase());
      }
    } catch (error) {
      logError(error);
    }
  }

  const columns: any = [
    {
      title: 'ContextId',
      dataIndex: 'ContextId',
      key: 'contextid',
    },
    {
      title: 'ReverseContextId',
      dataIndex: 'ReverseContextId',
      key: 'reversecontextid',
    },
    {
      title: 'MessageCount',
      dataIndex: 'MessageCount',
      key: 'messagecount',
      defaultSortOrder: 'descend',
      sorter: {
        compare: (a: any, b: any) => a.MessageCount - b.MessageCount,
      },
    },
    {
      title: 'RawType',
      dataIndex: 'RawType',
      key: 'rawtype',
      filters: [
        {
          text: 'Text',
          value: 'text',
        },
        {
          text: 'Image',
          value: 'image',
        },
        {
          text: 'Status',
          value: 'status',
        },
        {
          text: 'File',
          value: 'file',
        },
      ],
      filterSearch: true,
      onFilter: onFilterType,
    },
  ];

  return (
    <div className='message-context-statistics-wrapper'>
      <div style={{ display: 'flex' }}>
        <Space>
        <Button style={{ marginBottom: '15px' }} type="primary" shape="default" icon={<ReloadOutlined />} onClick={onLoadItems} />
        <Search style={{ marginBottom: "10px" }} placeholder="Suche" onSearch={onSearch} enterButton allowClear />
        <span>Nachrichten Anzahl: {getFilteredItems().reduce((sum, current) => sum + current.MessageCount, 0)}</span>
        </Space>
      </div>
      <Table rowKey='Key' scroll={{ y: 'calc(100vh - 300px)' }} columns={columns} dataSource={getFilteredItems()} />
    </div>
  );
};