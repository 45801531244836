import React, { useState } from 'react';
import { Table, Space, Button, Popconfirm, Input } from 'antd';
import { ReloadOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

import './ContextManagmentView.less'
import { useHub } from '../Services/HubProxy';
import { ContextManagmentEntry } from './Models';
import { isEmptyOrSpaces, logError } from '../Services/Helpers';
import ContextEdit from '../Context/ContextEdit';
import { ContextItemViewModel } from '../Context/Models';

const { Search } = Input;

export default function ContextManagmentView() {
  const hub = useHub();
  const [items, setItems] = useState<ContextManagmentEntry[]>([]);
  const [context, setContext] = useState<ContextItemViewModel | undefined>();
  const [showEditContext, setShowEditContext] = useState(false);
  const [showEditContextId, setShowEditContextId] = useState("");
  const [searchText, setSearchText] = useState<string>("");

  hub.subContextManagmentResult((data) => {
    try {
      setItems(data);
    } catch (error) {
      logError(error);
    }
  });

  hub.subContextEntryResult((data) => {
    try {
      if (data.ID === showEditContextId) {
        setContext(data);
        setShowEditContext(true);
        setShowEditContextId("");
      }
    } catch (error) {
      logError(error);
    }
  });

  const onLoadItems = () => {
    hub.queryContextManagment();
  }

  const onShowRecord = (record: any) => {
    try {
      setShowEditContextId(record.Id);
      hub.queryContextManagmentEntry(record.Id);
    } catch (error) {
      logError(error);
    }
  }

  const onDeleteContext = (record: any) => {
    try {
      hub.queryContextManagmentEntryDelete(record.Id);
    } catch (error) {
      logError(error);
    }
  }

  const getFilteredItems = (): ContextManagmentEntry[] => {
    try {
      return items.filter(s => s.Domain?.toLowerCase().includes(searchText.trim())
        || s.Type?.toLowerCase().includes(searchText.trim())
        || s.Title?.toLowerCase().includes(searchText.trim())
        || s.Id?.toLowerCase().includes(searchText.trim()));
    } catch (error) {
      logError(error);
    }
    return [];
  }

  const onSearch = (value: string) => {
    try {
      if (isEmptyOrSpaces(value)) {
        setSearchText(value);
      } else {
        setSearchText(value.toLowerCase());
      }
    } catch (error) {
      logError(error);
    }
  }

  const columns: any = [
    {
      title: 'Id',
      dataIndex: 'Id',
      key: 'id',
    },
    {
      title: 'Title',
      dataIndex: 'Title',
      key: 'title',
    },
    {
      title: 'Type',
      dataIndex: 'Type',
      key: 'type',
    },
    {
      title: 'Children',
      dataIndex: 'Children',
      key: 'children',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: any) => (
        <Space size="middle">
          <Button type="primary" shape="default" icon={<EditOutlined />} onClick={() => onShowRecord(record)} />
          <Popconfirm
            title="Sicher diesen Kontext löschen?"
            onConfirm={() => onDeleteContext(record)}
            okText="Ja"
            cancelText="Nein"
          >
            <Button type="primary" danger shape="default" icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>

      ),
    },
  ];

  return (
    <div className='message-context-statistics-wrapper'>
      <div style={{ display: 'flex' }}>
        <Space>
          <Button style={{ marginBottom: '15px' }} type="primary" shape="default" icon={<ReloadOutlined />} onClick={onLoadItems} />
          <Search style={{ marginBottom: "10px" }} placeholder="Suche" onSearch={onSearch} enterButton allowClear />
        </Space>
      </div>
      <Table rowKey='Id' scroll={{ y: 'calc(100vh - 300px)' }} columns={columns} dataSource={getFilteredItems()} />
      <ContextEdit hideContextSelect={false} model={context} show={showEditContext} closeCallback={() => setShowEditContext(false)} />
    </div>
  );
};