import { Collapse } from 'antd';
import React, { useState } from 'react';
import { dateOnlyFormat } from '../Services/Helpers';

import './ChangeLog.less'
import { ChangeLogEntry } from './Models';

const { Panel } = Collapse;

export default function ChangeLog() {
    /*const [contexts, setContexts] = useState<ContextItemViewModel[]>([]);
    const [clientStates, setClientStates] = useState<ClientState[]>([]);

    React.useEffect(() => {
        setContexts(props.items);
    }, [props.items]);*/

    const [entries, setEntries] = useState<ChangeLogEntry[]>([
        {
            Key: 1,
            VersionTitle: "Croco 0.0.1-rc7",
            Date: new Date(2022, 0, 1),
            Items: [
                "Anzeige des Änderungsprotokoll in der Login und Dashboard Ansicht",
                "Verbesserung der Kommunikation von Webapp und Host Server",
                "Verbesserung des Loggings in der Service Infrastruktur",
                "Service Health Status prüfung wird jetzt nur noch durchgeführt in den Ansichten Login und Dashboard",
                "Anzahl der erhaltenen Nachrichten wird jetzt im Browser Tab Titel angezeigt wenn Croco nicht der aktive Tab ist",
                "Überarbeitung wie Log Einträge erstellt werden um mehr details zu erhalten",
                "Allgemeine Code verbesserungen"
            ]
        },
        {
            Key: 2,
            VersionTitle: "Croco 0.0.1-rc8",
            Date: new Date(2022, 0, 1),
            Items: [
                "Verbesserung des Loggings",
                "Verbesserung des Authentifizierung von Benutzer Tokens",
                "Korrektur eines Fehlers beim Abmelden wodurch die Verbindung nicht richtig geschlossen wurde",
                "Die WebApp ignoriert jetzt fehler beim Abruf der Healthstates",
                "Korrektur für eine Fehlende ID bei aktiven Benutzer",
                "Beim Angemeldeten Benutzer wird jetzt der Verbindungsstatus angezeigt",
                "Der Benutzer Verbindungsstatus zeigt beim Hover die Dauer des letzten Status Roundtrip Paketes an",
                "Exchange Handler wird jetzt auf Disposed wenn die Unterliegende Hub Verbindung zu einem Client nicht mehr hergestellt werden kann",
                "Neue Benutzer Kontext Status für die Anzeige wenn ein Benutzer Angemeldet ist jedoch die WebApp nicht im Browser Vordergrund ist",
                "Nach dem Automatischen wiederverbinden eines Benutzers wird jetzt auch der Nachrichtenverlauf erneut geladen. (Sollte ein Chat geladen sein wird dieser leider zurzeit noch nicht aktualisiert, ein wechsel zwischen den aktiven Chats zeigt die veränderung dann an.)"
            ]
        },
        {
            Key: 3,
            VersionTitle: "Croco 0.0.1-rc9",
            Date: new Date(2022, 0, 1),
            Items: [
                "Anzeige einen Nachrichten Sendungsstatus, dieser Status ist aufgeteilt in Metadata und Inhalt.",
                "Verbesserung des Loggings",
                "Update Nuget des UI Frameworks",
                "Korrektur Benutzer Status änderungen",
                "Verbesserung wie Benutzer Kontext Status änderung verteilt werden",
                "Anpassung/Styling nativer WASM Fehler meldungen",
                "Update Nuget für Logging",
                "Chat Kontext Ansichten können jetzt auch geladen/aktualisert werden wenn diese Aktive sind",
                "Verbesserung bei dem Verbindungsaufbau und Wiederherstellen der Verbindung"
            ]
        },
        {
            Key: 4,
            VersionTitle: "Croco 0.1.0-rc1",
            Date: new Date(2022, 0, 1),
            Items: [
                "Verbesserung der Aktualisierung der Kontextliste",
                "Kontext Einträge haben jetzt die ersten Zwei buchstaben im Icon",
                "Update auf .NET 6",
                "Die Benutzer Kommunikation wird jetzt mit Tokens Authentifizier anstellen einer Session",
                "Die Service Kommunikation wird jetzt mit Tokens Authentifizier anstellen einer Session",
                "Servie kommunikation und API aufrufe Benötigen jetzt eine gültige Authentifizierung (via Token)",
                "Verwendung von Definierte Typen für die Serialisierung der Kommunikation (Verhinderung von Serialiserugs Attacken)",
                "Anpassung wie die Kommunkiation zwischen Servicen erfolgt.",
                "Verbesserung der Verbindungswiederherstellung",
                "Verbesserung der System Stabilität",
                "Ungelesene Nachricht Anzeige wird jetzt automatisch Entfernt wenn in dem Kontext eine Nachricht gesendet wird",
                "Aktive Kontext Einträge in der Sidebar werden jetzt automatisch höher aufgelistet",
                "Gruppen können jetzt erstellt werden",
                "Implementierung von Gruppen Chats"
            ]
        },
        {
            Key: 5,
            VersionTitle: "Croco 0.1.1",
            Date: new Date(2022, 0, 1),
            Items: [
                "Gruppen Kontexte können jetzt erstellt und bearbeitet werden (Gruppen in Gruppen funktioniert noch nicht).",
                "Verbesserung der Anzeige der Namen Icons",
                "Gruppen Kontexte werden jetzt nur für die zugeordneten Benutzer angezegit",
                "Verbesserung der Kontext handhabung bei den Servicen",
                "Allgemeine Code verbesserung",
            ]
        },
        {
            Key: 6,
            VersionTitle: "Croco 0.1.2",
            Date: new Date(2022, 0, 1),
            Items: [
                "Bilder können jetzt von der Zwischenablage in die Nachrichteneingabe kopiert werden.",
                "Croco Branding",
                "Allgemeine Verbesserung der Usability",
                "Verbesserung der Render Performance",
                "Verbesserung der Client Performance",
                "Verbesserung der Service Performance für die Abfrage von Kontexten",
                "Diverse Fehler verhinderungen",
                "Allgemeine Code Verbesserungen",
            ]
        },
        {
            Key: 7,
            VersionTitle: "Croco 0.1.3",
            Date: new Date(2022, 0, 1),
            Items: [
                "Korrektur das die Chat Kontexte in der Sidebar wieder anhand der Aktivitäten Sortiert werden.",
                "Markup renderer aktualisierung."
            ]
        },
        {
            Key: 8,
            VersionTitle: "Croco 0.1.4",
            Date: new Date(2022, 0, 1),
            Items: [
                "Verbesserung des Responsive Designs für die mobile Verwendung.",
            ]
        },
        {
            Key: 9,
            VersionTitle: "Croco 0.2.0",
            Date: new Date(2022, 0, 1),
            Items: [
                "Integration von PWA funktionalität.",
                "Client Software Struktur änderungen.",
                "Basis implementierung für Benachrichtigungen.",
                "Aktualiserungen von Abhängigkeiten (Markup & Blazor)",
                "Temporäres deaktiveren der Sha validierung von Code.",
            ]
        },
        {
            Key: 10,
            VersionTitle: "Croco 0.2.1",
            Date: new Date(2022, 0, 1),
            Items: [
                "Verhinderung das der Client einfriert wenn der Server nicht mehr erreichbar ist",
                "Verbesserung der Offline funktionalität.",
                "Eine Eingabe hilfe ist jetzt verfügbar neben der Senden Schaltfläche (zurzeit nur Emojis).",
                "Verbesserung für das Aktualiseren von Client Versionen",
                "Verhinderung eines Fehlers das zu Zustellungsproblemen führte wenn ein Benutzer auf mehreren Geräten Angemeldet war.",
                "Sidebar Kontexte sortierungen werden jetzt am Client gespeichert",
                "Kontexte in der Sidebar können jetzt angepinnt werden wodurch diese immer an den Obersten Positionen sind (Hover/Focus auf einen Sidebar Kontext um die Pin funktion zu sehen).",
                "Client Logging wurde wieder integriert",
            ]
        },
        {
            Key: 11,
            VersionTitle: "Croco 0.3.0",
            Date: new Date(2022, 0, 1),
            Items: [
                "Korrektur eines Fehlers wodurch die Client Verbindung nicht gefunden werden konnte beim einem Login versuch.",
                "Anpassung für die Nachrichten Status aktualisierung für Logins auf mehreren Geräten",
                "Laden des Chat Verlaufs wenn notwendig (Ein bekanntes Problem ist das beim nachladen an das Ende der Liste gescrollt wird)",
                "Überarbeitung der Chat Listen Anzeige",
                "Nachrichten vom gleichen Absender werden jetzt mit dem gleichen Zeitstempel angezeigt",
                "Korrektur eines Fehlers beim Logout welcher dazu führte das Verbindungen nicht korrekt geschlossen wurden",
                "Korrektur der Kontext Pin funktion",
                "Verbesserung des Responsive Designs für die Eingabe auf Mobilen Geräten",
                "Performance Verbesserung der Eingabe Hilfe (Emojis)",
                "Filter für Emojis",
            ]
        },
        {
            Key: 12,
            VersionTitle: "Croco 0.3.1",
            Date: new Date(2022, 1, 23),
            Items: [
                "Überarbeitung der Chat Ansicht Liste",
                "Verbesserung der ServieWorker aktualisierung",
                "Neue Versions Einträge haben jetzt ein Datum",
            ]
        },
        {
            Key: 13,
            VersionTitle: "Croco 0.3.2",
            Date: new Date(2022, 1, 28),
            Items: [
                "Änderung wie Nachrichten gespeichert werden im zusammenhang mit Kontexten.",
                "Neue Nachrichten Aktionen \"Metadaten\".",
                "Neue Nachrichten Aktionen \"Zitiren\" nur für Text Nachrichten.",
                "Eingabehilfe wird jetzt durch einen Klick auf ein anderes Element geschlossen.",
                "Nachrichten Verlauf kann jetzt ohne Einschränkungen geladen werden (immer maximal 80 Nachrichten pro Server request)",
                "Lade Anzeige bei der Server Abfrage von Nachrichten",
                "Neue Implementierung der render Elemente für Nachrichten",
                "Neue Liste für die Anzeige von Nachrichten",
                "Verhinderung eines Fehlers beim Abmelden ohne den Client zu Schließen",
                "Verbesserung des Stylings für Nachrichten",
                "JS & CSS aufteilung auf eigene Dateien"
            ]
        },
        {
            Key: 14,
            VersionTitle: "Croco 0.3.3",
            Date: new Date(2022, 1, 1),
            Items: [
                "Korrektur eines Fehlers bei der Abfrage von Gruppen Nachrichten.",
                "Verbesserung des Service Workers.",
            ]
        },
        {
            Key: 15,
            VersionTitle: "Croco 0.3.4",
            Date: new Date(2022, 2, 1),
            Items: [
                "Performance Verbesserung bei der Chat Eingabe",
                "Verbesserung der Usabiolity der Chat Eingabe",
                "Verbesserung der Benutzer Verbindungsstatus Anzeige und Abfrage.",
                "Verbindungsstatus wird jetzt auf den Servern gespeichert.",
            ]
        },
        {
            Key: 16,
            VersionTitle: "Croco 0.4.0",
            Date: new Date(2022, 2, 15),
            Items: [
                "Neuer Client auf basis von ReactJS",
            ]
        },
        {
            Key: 17,
            VersionTitle: "Croco 0.4.1",
            Date: new Date(2022, 2, 16),
            Items: [
                "Verhinderung eines Fehlers wodurch die Chat Ansicht unnötigerweise immer wieder neugeladen wurde.",
                "Reduzierung der Render Vorgänge der Kontext Sidebar und Chat Ansicht.",
                "Nachrichten können jetzt Zitirt werden",
                "Rendern von Klickbaren Links (starten mit \"http|s\", \"tel:\" oder \"mailto:\").",
                "Verwendung der Web Lock API um das Verhalten zu Verbessern wenn der Browser im Hintergrund ist.",
                "Verbesserung der Anmelde Ansicht.",
                "Aktualisierung der Webseiten Informationen.",
            ]
        },
        {
            Key: 18,
            VersionTitle: "Croco 0.4.2",
            Date: new Date(2022, 2, 17),
            Items: [
                "Basis implementierung für Electron (Standalone App für Desktop Umgebungen).",
                "Native Bindings für Electron.",
                "Native Desktop Benachrichtigungen (Electron).",
                "Tray Icon mit neue Nachrichten Symbol (roter punkt) für Windows, MacOS und Linux (Debian).",
                "Taskbar Icon overlay für die Anzeige von neue Nachrichten Symbol (roter punkt) für Windows 10/11.",
            ]
        },
        {
            Key: 19,
            VersionTitle: "Croco 0.5.0",
            Date: new Date(2022, 2, 22),
            Items: [
                "Electron Desktop Client.",
                "Integration für Emoji's.",
                "Verbesserung der Eingabe.",
                "Basis Kontext menü integration.",
                "Zitiren fügt jetzt eine Zeilenumbruch ein.",
                "Einfache Automatische Sorting für die Sidebar Kontexte.",
            ]
        },
        {
            Key: 20,
            VersionTitle: "Croco 0.5.1",
            Date: new Date(2022, 2, 23),
            Items: [
                "Benachrichtigungen werden jetzt auch nachgeliefert für Nachrichten die der Benutzer erhalten hat wenn die App nicht gestartet war.",
                "Interne Verbesserung wie Nachrichten gespeichert werden.",
                "Korrektur eines Fehlers wodurch die App Datenbank in einen unerwarteten Versetzt wurde.",
                "Übersetzungen für das Kontext Menü",
                "Korrektur für die Funktion zum Neuladen des Fensters."
            ]
        },
        {
            Key: 21,
            VersionTitle: "Croco 0.6.0",
            Date: new Date(2022, 2, 29),
            Items: [
                "Basis integration für Datenabfragen über die Cubes Schnittstelle (mobileCubes API).",
                "Forname, Nachname und Bild werden für Cubes Benutzer jetzt bei den Kontexten angezeigt.",
                "Backend Änderungen für die Verwendung von mobileCubes Token per Benutzer.",
                "Backend für einen Cubes Cache.",
                "Korrektur wodurch die Lautstärke nicht gespeichert wurde wenn Sie auf 0 gesetzt wurde.",
                "Integration der Basis für ein Administrations menü (Im Dashboard neben der Abmelden Schaltfläche).",
                "Administrations Anzeige für aktive Sessions auf dem Edge Server.",
                "Administrations Anzeige der Aktuellen Nachrichten Kontext Statistik.",
                "Die Kontext Sidebar kann jetzt auf und zugeklappt werden.",
                "Verbesserung des Responsive Design.",
                "Verhinderung von doppelten Benachrichtigungen für die gleiche Nachricht.",
                "Cache wird jetzt aktualsiert nach einem App update.",
                "Verhinderung von Leerzeichen am Ende eines Benutzernamens.",
            ]
        },
        {
            Key: 22,
            VersionTitle: "Croco 0.6.1",
            Date: new Date(2022, 2, 31),
            Items: [
                "Die Chat Ansicht zeigt jetzt im Titel die zugehörigen Kontexte nahand von Avataren an.",
                "Chat Nachrichten zeigen jetzt den Kontext Avatar und Titel an (anstelle des internen Namens).",
                "Korrektur der Eigenschaften für Untergeordnete Kontexte.",
                "Verbesserung des Login flows für Service.",
                "Backend Anpassungen für Benutzer Rollen und Berechtigungen.",
                "Verbesserung des Styling bei der Zugeklappten Sidebar.",
                "Dashboard und Login zeigt jetzt eine \"App herunterladen\" Schaltfläche an wenn Croco im Browser auf Windows/Linux geöffnet wird.",
                "Korrektur wodruch die Anzeige des Änderungsprotokoll die Sortierung gewechselt hat..",
                "Dialog für das Bearbeiten von Kontexten (Zurzeit nicht funktionsfähig).",
            ]
        },
        {
            Key: 23,
            VersionTitle: "Croco 0.6.2",
            Date: new Date(2022, 3, 4),
            Items: [
                "Integration für das Bearbeiten von Kontext Gruppen.",
                "Kontext Gruppen Änderungen werden zu Anderen Benutzern gepushed.",
                "Anpassung für das Einfügen eines Urls kopiert aus dem MS Edge.",
                "Korrektur der Datumsanzeige im Änderungsprotokol.",
                "Verbesserung der Bild Anzeige für Avatars.",
                "Administration für Kontexte."
            ]
        },
        {
            Key: 24,
            VersionTitle: "Croco 0.6.3",
            Date: new Date(2022, 3, 5),
            Items: [
                "Kontexte können jetzt erstellt werden (Löschen ist zurzeit nur im Administrator bereich möglich).",
                "Cubes Projekte können jetzt bei Kontexten gesucht und hinzugefügt werden.",
                "Erweiterung der Cubes API für die Verwendung der Projekt suche \"SolIT.Data.Pms.ProjectQuery\".",
                "Die Lokalen Client Datenbanken löschen jetzt Nachrichten älter als 14 Tage wenn diese nicht Aktive Verwendet werden.",
                "Kontext Server Synchroniseriung im den Client cache aktuell zu halten (gelöschte Kontexte werden aus dem Cache Entfernt).",
                "Verbesserung des Loggings für die Server Datenbanken.",
                "Verhinderung einer Race Condition beim Einfügen von Datensätzen.",
                "Verhinderung eines Fehlers wodurch Verbindungen geleakt wurden.",
                "Verbesserung des Loggings für offnen Verbindungen.",
                "Kontext Suche verwendet jetzt den alias Benutzer Token (mobileCubes Token).",
            ]
        },
        {
            Key: 25,
            VersionTitle: "Croco 0.7.0",
            Date: new Date(2022, 3, 7),
            Items: [
                "In der Benutzer Verwaltung kann die Sichtbarkeit von Benutzer Kontexten festgelegt werden.",
                "Integration von Croco Benutzern.",
                "Croco Benutzer Verwaltung im Administrator bereich.",
                "Neue Benutzer können im Administrator bereich erstellt werden.",
                "Croco Benutzer können im Dashboard Ihre Profil Bild, Name und Kürzel anpassen",
                "Croco Benutzer können im Dashboard ihr Passwort ändern.",
                "Eigene Nachrichten können jetzt bearbeitet werden.",
                "Kontext Verwaltung hat jetzt eine Suche.",
                "Session Ansicht hat jetzt eine Suche.",
                "Kontext Nachrichten Statistik hat jetzt eine Suche.",
                "Kontext Nachrichten Statistik zeigt jetzt die gesamte Anzahl der Nachrichten anhand der Suche an.",
            ]
        },
        {
            Key: 26,
            VersionTitle: "Croco 0.7.1",
            Date: new Date(2022, 3, 8),
            Items: [
                "PWA ist wieder verfügbar.",
                "Verbesserung des Responsive Designs bei der Eingabe.",
                "Neue Schaltfläche für die Datei Auswahl bei der Eingabe (Als alternative zu [STRG] + [C] & [STRG] + [V] im Eingabe Feld).",
                "Korrektur eines Fehlers wodurch Erstellte Kontexte einen Falschen Typen bekommen haben was dazu führte das Gruppen Nachrichten in diesem Kontext nicht zugestellt werden konnten.",
                "Verbesserung der Betriebsystem erkennung für die Anzeige des korrekten Herunterladen Links.",
            ]
        },
        {
            Key: 27,
            VersionTitle: "Croco 0.8.0",
            Date: new Date(2022, 3, 11),
            Items: [
                "Neuer Administrationsbereich für Berechtigungen.",
                "Integration von Rollen basierter Berechtigung.",
                "Der Administrationsbereich wird jetzt nur noch für die Rollen RootAdministrator und Administrator angezeigt.",
                "Im Administrationsbereich kann jetzt auch das Passwort von Benutzern geändert werden.",
                "Verbesserung der URL erkennung in Text Nachrichten (Jetzt werden auch Url erkannt innerhalb von Texten und auch mehrere).",
                "Kontexte in der Sidebar haben jetzt eine Maximal größe.",
                "Chat Kopfbereich zeigt jetzt maximal eine Zeile für den Kontext Titel an.",
                "Verhinderung eines Fehler wodurch Safari (< Version 15.4) und Internet Explorer Croco nicht laden konnte.",
                "Anpassung für das Automatische Zoomen von Safari auf iOS Geräten.",
                "Verhinderung von Fehlern durch nicht korrekt verworfene Sessions.",
                "Verbesserung des Loggins.",
                "Link für Cubes Dokument und QR Code wird jetzt beim Login und im Dashboard angeezeigt.",
                "Lokale Benutzernamen beim Anmelden sind nicht mehr Case Sensitive.",
            ]
        },
        {
            Key: 28,
            VersionTitle: "Croco 0.8.1",
            Date: new Date(2022, 3, 19),
            Items: [
                "Im Dashboard kann jetzt ein Alternativer Ton für Nachrichten ausgewählt werden.",
                "Korrektur des Client Status wodurch \"offline\" nicht korrekt gesetzt wurde.",
                "Verbesserung des internen JSON Parsers.",
                "Fehlerkorrektur anhand von Logging.",
                "Verbesserung des Loggins.",
            ]
        },
        {
            Key: 29,
            VersionTitle: "Croco 0.8.2",
            Date: new Date(2022, 3, 20),
            Items: [
                "Korrektur wodurch es beim Eigenen Benutzernamen zu einem Textüberlauf führen konnte und dadurch die Dashboard Schaltfläche verschoben wurde.",
                "Korrektur wodurch wenn Croco im Browser verwendet wurde der Status nicht korrekt übertragen wurden wenn sich der Browser im Hintergrund befand.",
                "Korrektur wodurch bei der Desktop App der Status nicht korrekt übertragen wurde wenn die App im Hintergrund war.",
                "Bei der Desktop App kann über das Notifikation Icon Menü \"Immer im Vordergrund\" gesetzt werden.",
                "Die Desktop App speichert/läde die letzt Fenster position/größe.",
                "Verbesserung des Loggings.",
            ]
        },
        {
            Key: 30,
            VersionTitle: "Croco 0.8.3",
            Date: new Date(2022, 3, 26),
            Items: [
                "Anpassung wie Nachrichten gesendet und der Status aktualisert wird (für eine bessere UX).",
                "Eigene Nachrichten können jetzt einen lokalen Übertragungsstatus anzeigen (Lade Icon = in übertragung, Grüner Hacken = erfolgreich, Rotes Dreieck = fehler).",
                "Verbesserung der korrektheit des Ungelesenen Nachrichten Zählers.",
                "Verbesserung der Server Rückmeldung bei Anmelde versuchen.",
                "Verhinderung eines Fehlers beim Anmelden wodurch dem Benutzer ein endloses laden angezeigt wurde.",
                "Performance verbesserung beim der internen Service Kommunikation.",
                "Performance verbesserung zwischen Client und Service.",
                "Client performance verbesserung.",
                "Erweiterung des Loggings zur frühzeitigen erkennung von langsamen (>250ms) Funktionen.",
                "Verbesserung des Loggings für den Nachrichtenverlauf.",
                "Verhinderung von unnötigen abfragen bevor der Benutzer angemeldet ist.",
                "Aktualisierung von SignalR.",
            ]
        },
        {
            Key: 31,
            VersionTitle: "Croco 0.9.0",
            Date: new Date(2022, 4, 9),
            Items: [
                "Neue implementierung der Nachrichteneingabe.",
                "Korrektur eines Fehlers wodurch bei der Nachrichten eingabe der Zeiger an den Anfang (oder andere Positionen) sprang.",
                "Bilder werden jetzt bevor Sie versendet werden können in einer Vorschau angezeigt (werden jetzt auch erst durch die Schaltfläche \"Senden\" geschickt).",
                "Verbesserung des Loggings.",
                "Verbesserung des Scrolling verhaltens der Chatansichten.",
                "Nachrichten vom Type \"Image\" können jetzt nicht mehr Bearbeitet oder Zitiert werden.",
                "Korrektur wodruch manchmal das Bearbeiten und Zitieren nicht funktionierte bzw. mehrmals Ausgewählt werden musste bevor es funktionierte.",
                "Das Eingabefeld hat jetzt eine Maximale größe von 300px bevor es zu Scrollen beginnt.",
                "Verhinderung eines Memory leaks.",
                "Die Pfeiltasten rauf und runter können jetzt in der Eingabe verwendet werden um eine Nachricht im Verlauf zu bearbeiten.",
                "Verbesserung das die Benachrichtigungen und Ton für neue Nachrichten angezeigt/abgespielt werden.",
                "Desktop/Native Benachrichtigungen zeigen für Text Nachrichten jetzt den Klar Text an (nicht mehr den Html).",
                "Desktop/Native Benachrichtigungen zeigen jetzt den Kontext Titel an und nicht mehr die ID.",
                "Desktop/Native Benachrichtigungen zeigen jetzt für Bild Nachrichten den Text \"Neues Bild\" an.",
            ]
        },
        {
            Key: 32,
            VersionTitle: "Croco 0.9.1",
            Date: new Date(2022, 4, 11),
            Items: [
                "Server performance verbesserung.",
                "Reduzierung der Datenübertragung zwischen Client und Server.",
                "Server drosselung für diverse Abfragen.",
                "Verbesserung des Loggings.",
                "Verhinderung eines Fehlers wodurch Verbindungs nicht korrekt geschlossen wurden.",
                "Verhinderung eines Fehlers welcher zu Server spam führte.",
            ]
        },
        {
            Key: 33,
            VersionTitle: "Croco 0.9.2",
            Date: new Date(2022, 4, 12),
            Items: [
                "Server performance verbesserung.",
                "Verbesserung des Loggings.",
            ]
        },
        {
            Key: 34,
            VersionTitle: "Croco 0.10.0",
            Date: new Date(2022, 5, 2),
            Items: [
                "Kontext Sidebar ist jetzt persistent und wird mit dem Server Synchronisiert.",
                "Kontext können jetzt gesucht werden.",
                "Verbesserung der Sidebar Kontext sortierung.",
                "Verbesserung der Chat Ansicht aktualisierung.",
                "Verhinderung eines Fehlers wodurch der Aktive Chat neugeladen wurde wenn eine Nachricht bei einem anderen Chat erhalten wurde.",
                "Verbesserung der Kontext Sidebar.",
                "Verbesserung der Client Nachrichten abfrage.",
                "Verbesserung der Server einschränkung bei Client Daten abfragen.",
                "Nicht Angemeldete Benutzer werden jetzt zur Anmelde Seite weitergeleitet auch wenn eine Unterseite über den URL aufgerufen wird.",
                "Aktualisierung der Diagnistic Library.",
            ]
        },
        {
            Key: 35,
            VersionTitle: "Croco 0.11.0",
            Date: new Date(2022, 5, 9),
            Items: [
                "Integration für Anrufe.",
                "Im Dashboard kann jetzt der Klingelton Eingestellt werden.",
                "Verbesserung der Kontext Status speicherung.",
                "Integration von Server Cache Funktionen.",
                "Verbesserung des Loggings.",
                "Korrektur eines Fehlers wodurch Server Cache Zeiten falsch berechnet wurden.",
                "Performance Verbesserungen.",
            ]
        },
        {
            Key: 36,
            VersionTitle: "Croco 0.11.1",
            Date: new Date(2022, 5, 10),
            Items: [
                "Verhinderung eines Fehlers bei der Cache verwendung.",
                "Verbesserung des Caches.",
                "Verbesserung der Kommunikation zwischen Servicen.",
                "Verbesserung des Loggings.",
            ]
        },
        {
            Key: 37,
            VersionTitle: "Croco 0.11.2",
            Date: new Date(2022, 5, 13),
            Items: [
                "Verhinderung eines Fehlers wodurch der Kommunikationschannel zwischen Servicen unnötigerweise immer wieder neu erstellt wurde.",
                "Verbesserung der Kommunikation zwischen Servicen.",
                "Verbesserung des Loggings.",
                "Automatische Tägliche Aufräum funktion für \"Nachrichten Benutezr Status Einträge\".",
                "Kontext Cache verbesserung."
            ]
        },
        {
            Key: 37,
            VersionTitle: "Croco 0.11.3",
            Date: new Date(2022, 5, 14),
            Items: [
                "Verbesserung der Nachrichten Metadata auflösung.",
                "Verbesserung der Kommunikation zwischen Servicen.",
                "Verbesserung des Caches.",
                "Verbesserung des Login flows.",
                "Verbesserung des Loggings.",
            ]
        }
    ].reverse());

    return (
        <div className='changelog-wrapper'>
            <h3>Versionsverlauf</h3>
            <Collapse defaultActiveKey={['0']} bordered={false} accordion={true}>
                {
                    entries.map((entry, i) =>
                        <Panel header={(<span>{entry.VersionTitle}<span className='changelog-entry-title-date'>{dateOnlyFormat(entry.Date)}</span></span>)} key={i}>
                            <ul>
                                {
                                    entry.Items.map(item =>
                                        <li>{item}</li>
                                    )
                                }
                            </ul>
                        </Panel>
                    )
                }
            </Collapse>
        </div>
    );
};