import { LocalStorage } from "./LocalStorage";

export function logError(message: any) {
    console.error(message);
}

export function playAudioFile(src: string, volume: number) {
    try {
        let audio = new Audio(src);
        audio.volume = volume;
        audio.play();
    } catch (error) {
        logError(error);
    }
}

export function playNotificationVolume(volume: number) {
    try {
        if (volume > 0) {
            const soundName = new LocalStorage().getNotificationSound();
            playAudioFile(`/assets/${soundName}`, volume);
        }
    } catch (error) {
        logError(error);
    }
}

export function playNotification() {
    try {
        var volume: number = new LocalStorage().getNotificationVolume();
        if (volume > 0) {
            const soundName = new LocalStorage().getNotificationSound();
            playAudioFile(`/assets/${soundName}`, volume);
        }
    } catch (error) {
        logError(error);
    }
}

export function playRingingVolume(volume: number) {
    try {
        if (volume > 0) {
            const soundName = new LocalStorage().getRingingSound();
            playAudioFile(`/assets/${soundName}`, volume);
        }
    } catch (error) {
        logError(error);
    }
}

export function playRinging() {
    try {
        var volume: number = new LocalStorage().getRingingVolume();
        if (volume > 0) {
            const soundName = new LocalStorage().getRingingSound();
            playAudioFile(`/assets/${soundName}`, volume);
        }
    } catch (error) {
        logError(error);
    }
}

export function isToday(date: Date) {
    const today = new Date();
    return date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
}

export function dateFormatToday(date: Date) {
    try {
        if (isToday(date)) {
            const options: Intl.DateTimeFormatOptions = {
                hour12: false,
                hour: '2-digit',
                minute: '2-digit',
            }
            return date.toLocaleTimeString("de-DE", options);
        } else {
            const options: Intl.DateTimeFormatOptions = {
                year: "numeric",
                month: "2-digit",
                day: "2-digit"
            }
            let dateString = date.toLocaleDateString("de-DE", options);
            const optionsTime: Intl.DateTimeFormatOptions = {
                hour12: false,
                hour: '2-digit',
                minute: '2-digit',
            }
            return dateString + ' ' + date.toLocaleTimeString("de-DE", optionsTime);
        }
    } catch (error) {
        logError(error);
    }
    return '';
}

export function dateOnlyFormat(date: Date) {
    try {
        const options: Intl.DateTimeFormatOptions = {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
        }
        return date.toLocaleDateString("de-DE", options);
    } catch (error) {
        logError(error);
    }
    return '';
}

export function secondIndex(value: string, find: string) {
    const first = value.indexOf(find);
    if (first === -1) {
        return -1;
    }
    return value.indexOf(find, first + 1);
}

export function isEmptyOrSpaces(str: any) {
    if (str === undefined || str === null) {
        return true;
    }
    return str.match(/^ *$/) !== null;
}

export function isWindows() {
    try {
        if (navigator.platform.indexOf("Win") !== -1) {
            return true;
        }
        return (navigator.appVersion.indexOf("Win") !== -1);
    } catch (error) {
        logError(error);
    }
    return false;
}

export function isLinux() {
    try {
        if (navigator.platform.indexOf("Linux") !== -1) {
            return true;
        }
        return (navigator.appVersion.indexOf("Linux") !== -1);
    } catch (error) {
        logError(error);
    }
    return false;
}

export function getBase64(img: Blob, callback: any) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

export function getImageUrl(baseString: string | undefined) {
    if (baseString) {
        try {
            if (baseString.startsWith("data:image")) {
                return baseString;
            } else {
                return "data:image/png;base64," + baseString;
            }
        } catch (error) {
            logError(error);
        }
    }
    return baseString;
}

export async function getDigest(value: string): Promise<string> {
    const msgUint8 = new TextEncoder().encode(value);
    const pw_digest = await window.crypto.subtle.digest('SHA-256', msgUint8);
    const hashArray = Array.from(new Uint8Array(pw_digest));
    return btoa(hashArray.map(function (v) { return String.fromCharCode(v) }).join(''));
}

export function getTicks(date: Date): any {
    return (621355968e9 + (date).getTime() * 1e4);
}


export function getDateFromTicks(ticks: number): Date {
    if (ticks === 0) {
        return new Date('2000-01-01');
    }
    var timeMS: number = ((ticks - 621355968e9) / 1e4);
    return new Date(timeMS);
}