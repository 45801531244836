import { Button, Col, Row } from 'antd';
import {
    PhoneOutlined,
    StopOutlined
} from '@ant-design/icons';
import { useRef, useState } from 'react';
import { logError } from '../Services/Helpers';

import './CallerInfo.less'
import { CallerModel, useCall } from '../Services/Call';

export default function CallerInfo(props: { caller: CallerModel, startingCall: boolean, closeCallback: (caller: CallerModel) => void }) {
    const [caller] = useState(props.caller);
    const [startingCall] = useState(props.startingCall);
    const call = useCall();

    const refLocalAudio = useRef<HTMLAudioElement | null>(null);
    const refRemoteAudio = useRef<HTMLAudioElement | null>(null);
    const [callStarted, setCallStarted] = useState(false);

    call.subLocalAudio((stream: any) => {
        try {
            if (refLocalAudio.current) {
                // should be the input (Microphone)
                refLocalAudio.current.srcObject = stream;
                console.log(stream.getAudioTracks());
            }
        } catch (error) {
            logError(error);
        }
    });

    call.subRemoteAudio((stream: any) => {
        try {
            if (refRemoteAudio.current) {
                setCallStarted(true);
                refRemoteAudio.current.srcObject = stream;
                console.log(stream.getAudioTracks());
            }
        } catch (error) {
            logError(error);
        }
    });

    const onAcceptCall = () => {
        try {
            console.log('Accept Call!');
            call.accept(caller);
        } catch (error) {
            logError(error);
        }
    }

    const onHangupCall = () => {
        try {
            console.log('Hangup!');
            props.closeCallback(caller);
        } catch (error) {
            logError(error);
        }
    }

    return (
        <div>
            <Row>
                <Col>
                    {
                        callStarted || startingCall ? '' :
                            (
                                <Button type='primary' shape="circle" className='call-accept' icon={<PhoneOutlined />} size="large" onClick={onAcceptCall} />
                            )
                    }
                    <Button style={{ marginLeft: '15px' }} type='primary' shape="circle" className='call-hangup' icon={<StopOutlined />} size="large" onClick={onHangupCall} />
                </Col>
            </Row>
            <Row>
                <Col>
                    {
                        callStarted ? <span>Input/Microphone:</span> : ''
                    }
                    <audio ref={refLocalAudio} id="localAudio" controls={callStarted} autoPlay></audio>
                </Col>
                <Col>
                    {
                        callStarted ? <span>Output:</span> : ''
                    }
                    <audio ref={refRemoteAudio} id="remoteAudio" controls={callStarted} autoPlay></audio>
                </Col>
            </Row>
        </div>
    );
};