import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CurrentUserViewModel } from '../User/Model';
import { isEmptyOrSpaces, logError } from './Helpers';

const key_device_id = "device_id";
const key_client_id = "client_id";
const key_token = "token";
const key_home_url = "home_url";
const key_notification_volume = "notification_volume";
const key_notification_sound = "notification_sound";
const notification_sound_default_value = "notification.mp3";
const key_native_notification_message = "native_notification_message";
const key_user = "user";

const key_ringing_volume = "ringing_volume";
const key_ringing_sound = "ringing_sound";
const ringing_sound_default_value = "ringing_1.mp3";

export class LocalStorage {

    setDeviceId(value: string) {
        localStorage.setItem(key_device_id, value);
    }

    getDeviceId() {
        var value = localStorage.getItem(key_device_id);
        if (value === null || value === "") {
            value = uuidv4();
            this.setDeviceId(value);
        }
        return value;
    }

    setClientId(value: string) {
        localStorage.setItem(key_client_id, value);
    }

    getClientId() {
        var value = localStorage.getItem(key_client_id);
        if (value === null || value === "") {
            value = uuidv4();
            this.setClientId(value);
        }
        return value;
    }

    setToken(value: string) {
        localStorage.setItem(key_token, value);
    }

    getToken() {
        return localStorage.getItem(key_token);
    }

    setHomeUrl(value: string) {
        localStorage.setItem(key_home_url, value);
    }

    getHomeUrl() {
        return localStorage.getItem(key_home_url);
    }

    setNotificationVolume(value: number) {
        localStorage.setItem(key_notification_volume, value.toString());
    }

    getNotificationVolume() {
        var value = localStorage.getItem(key_notification_volume);
        if (value === null || value === "") {
            this.setNotificationVolume(0.5);
            return 0.5;
        }
        try {
            var num: number | null = +value;
            if (num !== null) {
                return num;
            }
        } catch (error) {
            logError(error);
        }
        return 0.5;
    }

    setNotificationSound(value: string) {
        localStorage.setItem(key_notification_sound, value);
    }

    getNotificationSound() {
        var value = localStorage.getItem(key_notification_sound);
        if (value === null || value === "") {
            this.setNotificationSound(notification_sound_default_value);
            return notification_sound_default_value;
        }
        try {
            return value;
        } catch (error) {
            logError(error);
        }
        return notification_sound_default_value;
    }

    setRingingVolume(value: number) {
        localStorage.setItem(key_ringing_volume, value.toString());
    }

    getRingingVolume() {
        var value = localStorage.getItem(key_ringing_volume);
        if (value === null || value === "") {
            this.setRingingVolume(0.5);
            return 0.5;
        }
        try {
            var num: number | null = +value;
            if (num !== null) {
                return num;
            }
        } catch (error) {
            logError(error);
        }
        return 0.5;
    }

    setRingingSound(value: string) {
        localStorage.setItem(key_ringing_sound, value);
    }

    getRingingSound() {
        var value = localStorage.getItem(key_ringing_sound);
        if (value === null || value === "") {
            this.setRingingSound(ringing_sound_default_value);
            return ringing_sound_default_value;
        }
        try {
            return value;
        } catch (error) {
            logError(error);
        }
        return ringing_sound_default_value;
    }

    setNativeNotificationMessage(show: boolean) {
        localStorage.setItem(key_native_notification_message, show.toString());
    }

    getNativeNotificationMessage() {
        var value = localStorage.getItem(key_native_notification_message);
        try {
            if (value) {
                return Boolean(JSON.parse(value));
            }
        } catch (error) {
            logError(error);
        }
        return false;
    }

    setUser(value: CurrentUserViewModel | undefined) {
        try {
            if (value) {
                localStorage.setItem(key_user, JSON.stringify(value));
            } else {
                localStorage.setItem(key_user, "");
            }
            this.callbackUser.forEach(callback => {
                if (callback) {
                    callback();
                }
            });
        } catch (error) {
            logError(error);
        }
    }

    getUser() {
        try {
            const val = localStorage.getItem(key_user);
            if (!isEmptyOrSpaces(val) && val) {
                const user: CurrentUserViewModel = JSON.parse(val);
                return user;
            }
        } catch (error) {
            logError(error);
        }
        return null;
    }

    callbackUser: Map<String, (() => void) | null> = new Map();
    subUserChange(id: String, callback: () => void) {
        this.callbackUser.set(id, callback);
    }
}

const storage: LocalStorage = new LocalStorage();

export function useLocalStorage() {
    const [store] = useState(storage);

    return store;
}
