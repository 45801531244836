import { Form, Input, Button, Spin, Row, Col, Divider } from 'antd';
import React, { useState } from 'react';
import { connect, ConnectedProps } from "react-redux";

import { LoadingOutlined, ContainerOutlined, DownloadOutlined } from '@ant-design/icons';

import './Login.less'
import { RootState } from '../State/Store';
import { decrement, increment } from '../State/counterSlice';
import { UserLoginResponse } from './Model';
import { useNative } from '../Shared/NativeWrapper';
import { getDigest, isLinux, isWindows, logError } from '../Services/Helpers';
import { useHub } from '../Services/HubProxy';


const mapState = (state: RootState) => ({
    value: state.counter.value,
})

const mapDispatch = ({
    increment: () => ({ type: "counter/increment" }),
    decrement: () => ({ type: decrement().type }),
})

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>



export default function Login(props: { onLogin: (userName: string, password: string) => void; loginResponse: (UserLoginResponse | undefined); }) {
    const [loading, setLoading] = useState(false);
    const native = useNative();
    const hub = useHub();

    React.useEffect(() => {
        setLoading(false);
    }, [props.loginResponse]);

    const onFinish = async (values: any) => {
        setLoading(true);
        props.onLogin(values.username, await getDigest(values.password));//pw_encoded);
    };

    const onFinishFailed = (errorInfo: any) => {
        setLoading(false);
    };

    hub.subStartReconnecting(() => {
        try {
            setLoading(true);
        } catch (error) {
            logError(error);
        }
    });

    return (
        <div className="login-wrapper">
            <div id="login-logo" style={{ paddingBottom: "50px" }}>
                <img src="/logo_name.png" style={{ maxWidth: "250px" }} />
            </div>
            {
                isWindows() && !native.isDesktop() ?
                    (
                        <>
                            <Row style={{ marginBottom: '25px' }}>
                                <Col span="24">
                                    <Button size="large" type="primary" icon={<DownloadOutlined />} target="_blank" href={process.env.REACT_APP_WIN_INSTALLER_LINK}>
                                        App herunterladen
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    ) : ''
            }
            {
                isLinux() && !native.isDesktop() ?
                    (
                        <>
                            <Row style={{ marginBottom: '25px' }}>
                                <Col span="24">
                                    <Button size="large" type="primary" icon={<DownloadOutlined />} target="_blank" href={process.env.REACT_APP_LINUX_APPINFO_LINK}>
                                        App herunterladen
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    ) : ''
            }
            <h3>Anmeldung</h3>
            <div>
                <Spin indicator={<LoadingOutlined spin />} size="large" spinning={loading}>
                    <Form
                        name="basic"
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 18 }}
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Benutzer"
                            name="username"
                            rules={[{ required: true, message: 'Bitte geben Sie ihren Croco oder mobileCubes Benutzer ein!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Passwort"
                            name="password"
                            rules={[{ required: true, message: 'Bitte geben Sie ihr Passwort ein!' }]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 3, span: 18 }}>
                            <Button type="primary" htmlType="submit">
                                Anmelden
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </div>
            <Divider />
            <Row>
                <Col span="24">
                    <Button type="primary" icon={<ContainerOutlined />}>
                        Änderungsprotokoll
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col span="24">
                    <div style={{ marginTop: '30px' }}>
                        <img src="/cudeqr.jpg" style={{ maxWidth: "150px" }} />
                        <Button size="large" type="primary" target="_blank" href="https://intra.sol-it.at/CubesWebService/D/UI/vuwLJE5OTk7CMw">
                            Informationen zu Croco
                        </Button>
                    </div>

                </Col>
            </Row>
            <Row>
                <Col span="24">
                    {/*<SolIT.Client.Chat.Client.Health.ServiceHealthState />*/}
                </Col>
            </Row>
        </div >
    )
};