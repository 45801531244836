import React, { useState } from 'react';
import {
    Form, Input, Divider, Avatar, Tooltip, Modal, Upload, message
} from 'antd';
import { InfoCircleOutlined, CheckOutlined, CloseOutlined, UploadOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { ContextItemViewModel, ContextItemViewModelSelect, ContextModel } from '../Context/Models';
import { SketchPicker } from 'react-color';

import './ContextEdit.less'
import { getBase64, getImageUrl, isEmptyOrSpaces, logError } from '../Services/Helpers';
import { db } from '../Services/DB';
import { useHub } from '../Services/HubProxy';
import { useLocalStorage } from '../Services/LocalStorage';

const { Search } = Input;

export default function ContextEdit(props: { model: ContextItemViewModel | undefined; show: boolean; closeCallback: () => void; hideContextSelect: boolean }) {
    const storage = useLocalStorage();
    const hub = useHub();
    const [context, setContext] = useState<ContextItemViewModel>();
    const [showEditContext, setShowEditContext] = useState(props.show);
    const [items, setItems] = useState<ContextItemViewModelSelect[]>([]);
    const [serverSearch, setServerSearch] = useState<ContextItemViewModelSelect[]>([]);
    const [addedItems, setAddedItems] = useState<ContextItemViewModelSelect[]>([]);
    const [removedItems, setRemovedItems] = useState<string[]>([]);
    const [searchText, setSearchText] = useState("");
    const [contextName, setContextName] = useState("");
    const [contextShorthand, setContextShorthand] = useState("");
    const [contextColor, setContextColor] = useState("");
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [allowedSafe, setAllowedSafe] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);

    const [uploadLoading, setUploadLoading] = useState(false);
    const [uploadImageUrl, setUploadImageUrl] = useState("");
    const [hideContextSelect, setHideContextSelect] = useState(props.hideContextSelect);

    React.useEffect(() => {
        if (props.model) {
            setSearchText("");
            setConfirmLoading(false);
            setUploadLoading(false);
            setAddedItems([]);
            setServerSearch([]);
            setContext(props.model);
            setContextName(props.model.Title);
            if (props.model.ShorthandSymbol) {
                setContextShorthand(props.model.ShorthandSymbol);
            } else {
                setContextShorthand("");
            }
            if (props.model.Color) {
                setContextColor(props.model.Color);
            } else {
                setContextColor("");
            }

            if (props.model.Thumbnail && !isEmptyOrSpaces(props.model.Thumbnail)) {
                setUploadImageUrl(props.model.Thumbnail);
            } else {
                setUploadImageUrl("");
            }
            db.contexts.toArray().then(array => {
                let itemsArray = array as ContextItemViewModelSelect[];
                onUpdateContextItems(itemsArray);
            });
            if (!isEmptyOrSpaces(props.model.Title)) {
                setAllowedSafe(true);
            }
        }
    }, [props.model]);

    React.useEffect(() => {
        setShowEditContext(props.show);
    }, [props.show]);

    React.useEffect(() => {
        setHideContextSelect(props.hideContextSelect);
    }, [props.hideContextSelect]);

    hub.subContextSearchResult((searchResult: ContextItemViewModel[]) => {
        setSearchLoading(false);
        try {
            if (searchResult?.length > 0) {
                var newItems: ContextItemViewModelSelect[] = [];
                for (let index = 0; index < searchResult.length; index++) {
                    const element = searchResult[index];
                    newItems.push(element as ContextItemViewModelSelect);
                }
                setServerSearch(newItems);
            } else {
                setServerSearch([]);
            }
        } catch (error) {
            logError(error);
        }
    });

    const onUpdateContextItems = (itemsArray: ContextItemViewModelSelect[]) => {
        try {
            let selected: ContextItemViewModelSelect[] = [];
            let search: ContextItemViewModelSelect[] = [];
            for (let index = 0; index < itemsArray.length; index++) {
                const element = itemsArray[index];
                if (props.model) {
                    if (props.model.ID === element.ID) {
                        // element is the context self
                        continue;
                    } else {
                        if (props.model.ChildContexts && props.model.ChildContexts.length > 0) {
                            let fItem = props.model.ChildContexts.find(x => {
                                if (x.ID === element.ID) {
                                    return true;
                                }
                            });
                            if (fItem) {
                                let sItem = fItem as ContextItemViewModelSelect;
                                sItem.Selected = true;
                                selected.push(sItem);
                            } else {
                                search.push(element as ContextItemViewModelSelect);
                            }
                        } else {
                            search.push(element as ContextItemViewModelSelect);
                        }
                    }
                } else {
                    search.push(element as ContextItemViewModelSelect);
                }
            }
            if (props.model) {
                if (props.model.ChildContexts && props.model.ChildContexts.length > 0) {
                    for (let index = 0; index < props.model.ChildContexts.length; index++) {
                        const element = props.model.ChildContexts[index];
                        if (element.ID === storage.getClientId()) {
                            continue;
                        }
                        if (props.model.ID === element.ID) {
                            // element is the context self
                            continue;
                        }
                        let fIndex = selected.findIndex(x => {
                            if (x.ID === element.ID) {
                                return true;
                            }
                        });
                        if (fIndex === -1) {
                            // this context is not in the local cache
                            // we need to request the context from the server or use the model direct
                            let sItem = element as ContextItemViewModelSelect;
                            sItem.Selected = true;
                            selected.push(sItem);
                        }
                    }
                }
            }
            setAddedItems(selected);
            setItems(search);
        } catch (error) {
            logError(error);
        }
    }

    const getFilteredItems = (): ContextItemViewModelSelect[] => {
        if (isEmptyOrSpaces(searchText)) {
            return items;
        }
        return serverSearch;//.filter(s => s.Title.includes(searchText.trim()));
    }

    const onSearch = (value: any) => {
        try {
            setSearchText(value);
            if (isEmptyOrSpaces(value)) {
                return;
            }
            setSearchLoading(true);
            hub.queryContextSearch(value);
        } catch (error) {
            logError(error);
            setSearchLoading(false);
        }
    }

    const handleOk = () => {
        try {
            if (context) {
                setConfirmLoading(true);
                let model: ContextModel = {
                    ID: context.ID,
                    Domain: context.Domain,
                    HomeServer: context.HomeServer,
                    KnownType: context.KnownType,
                    Title: contextName,
                    Color: contextColor,
                    ShorthandSymbol: contextShorthand,
                    Subtitle: context.Subtitle,
                    Thumbnail: context.Thumbnail,
                    RelatedContexts: [],
                    RemovedContextIds: removedItems
                }

                if (addedItems.length > 0) {
                    for (let index = 0; index < addedItems.length; index++) {
                        const element = addedItems[index];
                        model.RelatedContexts.push({
                            RelatedContextId: element.ID,
                        })
                    }
                }

                if (!isEmptyOrSpaces(uploadImageUrl)) {
                    model.Thumbnail = uploadImageUrl;
                }

                hub.contextSaveRequest(model);
                setTimeout(() => {
                    setShowEditContext(false);
                    setConfirmLoading(false);
                }, 100);
            }
        } catch (error) {
            logError(error);
            setConfirmLoading(false);
        }
    };

    const onClickItem = (item: ContextItemViewModelSelect) => {
        try {
            // check for already added
            let fIndex = addedItems.findIndex(x => {
                if (x.ID === item.ID) {
                    return true;
                }
            });
            if (fIndex === -1) {
                item.Selected = true;
                setAddedItems(addedItems.concat(item));
            }
            setItems(items.filter(x => x !== item));
            if (removedItems.includes(item.ID)) {
                setRemovedItems(removedItems.filter(x => x !== item.ID));
            }
        } catch (error) {
            logError(error);
        }
    }

    const onClickRemoveItem = (item: ContextItemViewModelSelect) => {
        try {
            item.Selected = false;
            setAddedItems(addedItems.filter(x => x !== item));
            // check for already added
            let fIndex = items.findIndex(x => {
                if (x.ID === item.ID) {
                    return true;
                }
            });
            if (fIndex === -1) {
                setItems(items.concat(item));
            }
            if (!removedItems.includes(item.ID)) {
                setRemovedItems(removedItems.concat(item.ID));
            }
        } catch (error) {
            logError(error);
        }
    }

    const uploadButton = (
        <div>
            {uploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Bild</div>
        </div>
    );

    const beforeUpload = (file: any): boolean => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('Das Bild muss eine JPG/PNG Datei sein!');
            return false;
        }
        const isLt2M = file.size / 1024 < 256;
        if (!isLt2M) {
            message.error('Das Bild muss kleiner als 256KB sein!');
            return false;
        }
        if (isJpgOrPng && isLt2M) {
            getBase64(file, (imageUrl: any) => {
                setUploadImageUrl(imageUrl);
            });
        }
        return false;
    }

    const handleChange = (info: any) => {
        return;
    };

    const onAfterClose = () => {
        try {

        } catch (error) {
            logError(error);
        }
        props.closeCallback();
    }

    const onColorHandleClick = () => {
        setDisplayColorPicker(!displayColorPicker);
    };

    const onColorHandleClose = () => {
        setDisplayColorPicker(false);
    };

    const onColorHandleChange = (color: any) => {
        setContextColor(color.hex);
    };

    const onSetInputContextName = (value: string) => {
        try {
            setContextName(value);
            if (!isEmptyOrSpaces(value)) {
                setAllowedSafe(true);
            } else {
                setAllowedSafe(false);
            }
        } catch (error) {
            logError(error);
        }
    }

    return (
        <Modal
            forceRender
            className='context-edit-container'
            title="Kontext Bearbeiten"
            centered
            visible={showEditContext}
            onOk={handleOk}
            onCancel={() => setShowEditContext(false)}
            afterClose={() => onAfterClose()}
            confirmLoading={confirmLoading}
            okText="Speichern"
            cancelText="Abbrechen"
            destroyOnClose={true}
            okButtonProps={{ disabled: !allowedSafe }}
        >
            <div className='context-edit-wrapper'>
                <div className='context-edit-avatar-name'>
                    <div className='context-edit-avatar'>
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            onChange={handleChange}
                            customRequest={() => { }}
                        >
                            {uploadImageUrl ? <img src={uploadImageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                        </Upload>
                    </div>
                    <div className='context-edit-name' style={{ placeSelf: "flex-end", width: "100%" }}>
                        <Input placeholder="Kontext Name"
                            value={contextName} onChange={e => onSetInputContextName(e.target.value)}
                            suffix={
                                <Tooltip title="Vollständiger Kontext Name">
                                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                </Tooltip>
                            } />
                        <Input maxLength={3} className='context-shorthand' placeholder="Kürzel"
                            value={contextShorthand} onChange={e => setContextShorthand(e.target.value)}
                            suffix={
                                <Tooltip title="Abgekürzter Kontext Name">
                                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                </Tooltip>
                            } />
                        <div style={{ marginLeft: '10px' }}>
                            <div style={{
                                padding: '5px',
                                background: '#fff',
                                borderRadius: '1px',
                                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                                display: 'inline-block',
                                cursor: 'pointer',
                                height: '100%',
                            }} onClick={onColorHandleClick}>
                                <div style={{
                                    width: '36px',
                                    height: '100%',
                                    borderRadius: '2px',
                                    background: `${contextColor}`,
                                }} />
                            </div>
                            {displayColorPicker ? <div style={{
                                position: 'absolute',
                                zIndex: '2',
                            }}>
                                <div style={{
                                    position: 'fixed',
                                    top: '0px',
                                    right: '0px',
                                    bottom: '0px',
                                    left: '0px',
                                }} onClick={onColorHandleClose} />
                                <SketchPicker color={contextColor} onChange={onColorHandleChange} />
                            </div> : null}

                        </div>
                    </div>
                </div>
                {
                    hideContextSelect !== true ?
                        (
                            <>
                                <div className='context-items-search-result context-items-search-result-added'>
                                    {
                                        addedItems.map((item, i) =>
                                            <div onClick={() => onClickRemoveItem(item)} key={i} className={item.Selected ? "context-item-search context-item-search-selected" : "context-item-search"} style={{ display: "flex" }}>
                                                <span>
                                                    <Avatar size="small" src={!isEmptyOrSpaces(item.Thumbnail) ? getImageUrl(item.Thumbnail) : ''}
                                                        style={{ backgroundColor: !isEmptyOrSpaces(item.Color) ? item.Color : "orange", verticalAlign: 'middle' }}>
                                                        {
                                                            !isEmptyOrSpaces(item.ShorthandSymbol) ? item.ShorthandSymbol : item.Title.substring(0, 2)
                                                        }
                                                    </Avatar>
                                                </span>
                                                <span className="context-item-search-title">{item.Title}</span>
                                                <div className='context-item-serach-checked' hidden={!item.Selected}>
                                                    <CheckOutlined className='context-item-serach-checked-active' />
                                                    <CloseOutlined className='context-item-serach-checked-deactive' />
                                                </div>
                                            </div >
                                        )
                                    }
                                </div>
                                <Divider />
                                <h4>Kontexte hinzufügen</h4>
                                <Search style={{ marginBottom: "10px" }} placeholder="Kontext suche" onSearch={onSearch} enterButton allowClear loading={searchLoading} />
                                <div className='context-items-search-result'>
                                    {
                                        getFilteredItems().map((item, i) =>
                                            <div onClick={() => onClickItem(item)} key={i} className={item.Selected ? "context-item-search context-item-search-selected" : "context-item-search"} style={{ display: "flex" }}>
                                                <span>
                                                    <Avatar size="small" src={!isEmptyOrSpaces(item.Thumbnail) ? getImageUrl(item.Thumbnail) : ''}
                                                        style={{ backgroundColor: !isEmptyOrSpaces(item.Color) ? item.Color : "orange", verticalAlign: 'middle' }}>
                                                        {
                                                            !isEmptyOrSpaces(item.ShorthandSymbol) ? item.ShorthandSymbol : item.Title.substring(0, 2)
                                                        }
                                                    </Avatar>
                                                </span>
                                                <span className="context-item-search-title">{item.Title}</span>
                                                <div className='context-item-serach-checked' hidden={!item.Selected}>
                                                    <CheckOutlined className='context-item-serach-checked-active' />
                                                    <CloseOutlined className='context-item-serach-checked-deactive' />
                                                </div>
                                            </div >
                                        )
                                    }
                                </div>
                            </>
                        ) : 
                        (
                            <>
                            <br></br>
                            <p>Sollten die Änderungen nicht gleich übernommen werden verwenden Sie bitte die "Fenster neuladen".</p>
                            </>
                            
                        )
                }
            </div>
        </Modal>
    );
};