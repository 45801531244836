import { logError } from "../Services/Helpers";

export default function WebLocks() {
    try {
        // https://developer.mozilla.org/en-US/docs/Web/API/Web_Locks_API#advanced_use
        let resolve, reject;
        const p = new Promise((res, rej) => { resolve = res; reject = rej; });
        navigator.locks.request('croco_resource_lock', lock => {
            //console.log(`Is locked`);
            console.info("Lock created");
            return p;
        });
    } catch (error) {
        logError(error);
    }
}
