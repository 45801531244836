import React, { useState } from 'react';
import { ClientState, ContextItemViewModel } from '../Context/Models';
import ContextItemSidebar from './ContextItemSidebar';

import './ContextSidebar.less'

export default function ContextSidebar(props: { items: ContextItemViewModel[]; isCollapsed: boolean; searchValue: string }) {
    const [contexts, setContexts] = useState<ContextItemViewModel[]>([]);
    const [clientStates, setClientStates] = useState<ClientState[]>([]);
    const [collapsed, setCollapsed] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    React.useEffect(() => {
        setContexts(props.items);
    }, [props.items]);

    React.useEffect(() => {
        setCollapsed(props.isCollapsed);
    }, [props.isCollapsed]);

    React.useEffect(() => {
        setSearchValue(props.searchValue);
    }, [props.searchValue]);

    return (
        <div className='context-sidebar-list'>
            {
                contexts.map((item, i) =>
                    <ContextItemSidebar key={item.ID} model={item} states={clientStates} isCollapsed={collapsed} />
                )
            }
        </div>
    );
};