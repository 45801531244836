import React, { Key, useState } from 'react';
import { Table, Space, Button, Input } from 'antd';
import { ReloadOutlined, CrownOutlined } from '@ant-design/icons';

import './UserManagmentView.less'
import { useHub } from '../Services/HubProxy';
import { isEmptyOrSpaces, logError } from '../Services/Helpers';
import { PermissionRoleRights } from '../User/Model';
import { PermissionManagmentViewItem } from './Models';
import ChangePermissionRole from './ChangePermissionRole';

const { Search } = Input;

export default function PremissionManagmentView() {
  const hub = useHub();
  const [items, setItems] = useState<PermissionManagmentViewItem[]>([]);
  const [showPermissionRoleVisibility, setShowPermissionRoleVisibility] = useState(false);
  const [searchText, setSearchText] = useState<string>("");
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [selectedRowItems, setSelectedRowItems] = useState<PermissionManagmentViewItem[]>([]);

  hub.subPermissionManagmentResult((data) => {
    try {
      setItems(data);
    } catch (error) {
      logError(error);
    }
  });

  const onLoadItems = () => {
    hub.queryPermissionManagment();
  }

  const getFilteredItems = (): PermissionManagmentViewItem[] => {
    try {
      return items.filter(s => s.Id?.toLowerCase().includes(searchText.trim())
        || s.Key?.toLowerCase().includes(searchText.trim())
        || s.Rights?.toLowerCase().includes(searchText.trim())
        || s.Role?.toString() === searchText.trim());
    } catch (error) {
      logError(error);
    }
    return [];
  }

  const onSearch = (value: any) => {
    try {
      if (isEmptyOrSpaces(value)) {
        setSearchText(value);
      } else {
        setSearchText(value.toLowerCase());
      }
    } catch (error) {
      logError(error);
    }
  }

  const columns: any = [
    {
      title: 'Id',
      dataIndex: 'Id',
      key: 'id',
    },
    {
      title: 'Role',
      dataIndex: 'Role',
      key: 'role',
      render: (item: PermissionRoleRights) => <span>{PermissionRoleRights[item]}</span>
    },
    {
      title: 'Rights',
      dataIndex: 'Rights',
      key: 'rights',
    },
  ];

  const onSelectChange = (selectedRowKeys: Key[], selectedRows: PermissionManagmentViewItem[]) => {
    try {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRowItems(selectedRows);  
    } catch (error) {
      logError(error);
    }
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onShowPermissionRole = () => {
    try {
      setShowPermissionRoleVisibility(true);
    } catch (error) {
      logError(error);
    }
  }

  return (
    <div className='permission-managment-wrapper'>
      <div style={{ display: 'flex' }}>
        <Space>
          <Button style={{ marginBottom: '15px' }} type="primary" shape="default" icon={<ReloadOutlined />} onClick={onLoadItems} />
          <Search style={{ marginBottom: "10px" }} placeholder="Suche" onSearch={onSearch} enterButton allowClear />
          <Button disabled={selectedRowKeys.length === 0} style={{ marginBottom: '15px' }} type="primary" shape="default" icon={<CrownOutlined />} onClick={onShowPermissionRole}>Rolle für die Auswahl ändern</Button>
        </Space>
      </div>
      <Table rowKey="Id" scroll={{ y: 'calc(100vh - 300px)' }} columns={columns} dataSource={getFilteredItems()} rowSelection={rowSelection} />
      <ChangePermissionRole permissions={selectedRowItems} show={showPermissionRoleVisibility} closeCallback={() => setShowPermissionRoleVisibility(false)} />
    </div>
  );
};