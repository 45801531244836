import * as signalR from "@microsoft/signalr";
import { MessagePackHubProtocol } from "@microsoft/signalr-protocol-msgpack";
import { v4 as uuidv4 } from 'uuid';
import { getDateFromTicks, getTicks, isEmptyOrSpaces, logError, playNotification } from "./Helpers";
import { encode, decode } from "@msgpack/msgpack";
import { LoginViewModel, UserAfterLogin, UserLoginResponse, UserLogoutRequest, UserViewModel } from "../User/Model";
import { LocalStorage, useLocalStorage } from "./LocalStorage";
import { ClientTriggerAction, DataQueryResult, DataQuerySearch } from "../Shared/Model";
import { useState } from "react";
import { ChatItemSendState, ChatItemViewModel, MessageHistorySearch, MessageType } from "../Chat/Models";
import { Active, ClientState, ContextItemViewModel, ContextModel } from "../Context/Models";
import { ContextUserListSearch } from "../Context/Models";
import { db } from "./DB";
import NativeWrapper, { useNative } from "../Shared/NativeWrapper";
import { ConnectSession, ContextManagmentEntry, MessageContextStatistic, PermissionManagmentViewItem, PermissionVisibleContextViewModel, UserManagmentViewItem } from "../Administration/Models";
import { hubSendCallback, incomingMessage } from "../Shared/Voice";
import { Call, CallerModel } from "./Call";

const _hubGroupName: String = "client";

export default class HubProxy {
    native: NativeWrapper = new NativeWrapper();
    storage: LocalStorage = new LocalStorage();
    call: Call = new Call();
    connection: signalR.HubConnection = this.createHubConnection();
    newMessages: String[] = [];

    constructor() {
        this.connect();
        hubSendCallback((callerModel: CallerModel) => {
            try {
                let payload: ExchangeEntity = {
                    TransferId: uuidv4(),
                    Token: this.storage.getToken() || "",
                    ClientId: this.storage.getClientId(),
                    DeviceId: this.storage.getDeviceId(),
                    HomeUrl: undefined,
                    Key: "PeerMessage",
                    Entity: encode(callerModel)
                };
                this.onSend("PeerMessage", payload);
            } catch (error) {
                logError(error);
            }
        })
    }

    setStorage(storage: LocalStorage) {
        this.storage = storage;
    }

    createHubConnection() {
        if (process.env.REACT_APP_HUB_PROXY_URL) {
            let delays = [];
            for (let i = 0; i < 30; i++) {
                delays.push(1);
                delays.push(2);
                delays.push(4);
                delays.push(6);
                delays.push(12);
                delays.push(24);
            }

            return this.connection = new signalR.HubConnectionBuilder()
                .withUrl(process.env.REACT_APP_HUB_PROXY_URL, {
                    accessTokenFactory: () => {
                        return this.storage.getToken() || "";
                    }
                })
                .withHubProtocol(new MessagePackHubProtocol())
                //.configureLogging(signalR.LogLevel.Trace)
                .withAutomaticReconnect(delays)
                .build();
        } else {
            throw new Error("no hub proxy connection url was provied in the environment variable:\"REACT_APP_HUB_PROXY_URL\"");
        }
    }

    connect() {
        if (this.connection !== null) {
            try {
                this.connection.start().then(() => {
                    this.onAfterConnected();
                }).catch(err => {
                    logError(err);
                    // TODO: clean up hub and retry
                });
            } catch (error) {
                logError(error);
                // TODO: clean up hub
            }
        }
    }

    close(callback: () => void) {
        try {
            if (this.connection !== null) {
                this.connection.stop().then(() => {
                    console.warn("Close connection");
                    callback();
                });
            }
        } catch (error) {
            logError(error);
        }
    }

    onAfterConnected() {
        if (this.connection !== null) {
            this.connection.onreconnecting((data: Error | undefined) => {

            });
            this.connection.onreconnected((connectionId) => {

            });
            this.connection.onclose((data: Error | undefined) => {

            });
            this.connection.on("OutgoingFromProxy", (payload: ExchangeEntity) => {
                this.onReceivePayload(payload);
            });
            this.connection.on("peerMessage", (payload: ExchangeEntity) => {
                try {
                    const callerModel = decode(payload.Entity) as CallerModel;
                    incomingMessage(callerModel);
                } catch (error) {
                    logError(error);
                }
            });
            this.onSend("AddToGroup", _hubGroupName);
            this.reconnect();
            this.mainLoop();
        } else {
            // TODO: propagate disconnect
        }
    }

    mainLoop() {
        try {
            this.mainWorker();
            this.slowWorker();
            setTimeout(async () => {
                // execute actions after 30 seconds
                try {
                    await db.clearOldData();
                    this.queryContextEntriesServerSync();
                } catch (error) {
                    logError(error);
                }
            }, 30000)
        } catch (error) {
            logError(error);
        }
    }

    mainWorker() {
        setTimeout(() => {
            try {
                this.propagateClientState();
            } catch (error) {
                logError(error);
            }
            this.mainWorker();
        }, 25000)
    }

    slowWorker() {
        setTimeout(() => {
            try {
                this.queryNotReceived();
            } catch (error) {
                logError(error);
            }
            this.slowWorker();
        }, 10000) // request every 10 seconds from the server. client should always be aggresive and the server can throttle if needed
    }

    async onReceivePayload(payload: ExchangeEntity) {
        try {
            switch (payload.Key) {
                case "LoginResponse":
                    const login_response = decode(payload.Entity) as UserLoginResponse;
                    if (this.callbackLoginResponse) {
                        this.callbackLoginResponse(login_response);
                    }
                    if (login_response) {
                        this.storage.setUser(login_response.User);
                    }
                    break;
                case "TriggerReceived":
                    const trigger_received = decode(payload.Entity) as ClientTriggerAction;
                    //console.log(trigger_received);
                    break;
                case "Trigger":
                    const trigger = decode(payload.Entity) as string;
                    if (trigger === "contextchanges") {
                        this.contextListRequest();
                    } else {
                        console.warn(trigger);
                    }
                    break;
                case "UserAfterLogin":
                    const userAfterLogin = decode(payload.Entity) as UserAfterLogin;
                    // now the client should be ready and should have loaded all needed data and made ui updates
                    if (this.storage
                        && !isEmptyOrSpaces(this.storage.getClientId())
                        && !isEmptyOrSpaces(this.storage.getToken())) {
                            this.propagateClientState();
                    }
                    break;
                case "ContextUserListReceived":
                    const context_user_list_response = decode(payload.Entity) as ContextItemViewModel[];
                    await db.contexts.bulkPut(context_user_list_response);
                    await this.onTriggerSidebarContextChange();
                    break;
                case "ClientStatesReceived":
                    const context_client_states = decode(payload.Entity) as ClientState[];
                    if (context_client_states.length > 0) {
                        const currentContextID = this.storage.getClientId();

                        context_client_states.forEach(state => {
                            if (state.ContextId === currentContextID) {
                                if (this.callbackCurrentUserClientState) {
                                    this.callbackCurrentUserClientState(state);
                                }
                            } else {
                                if (this.callbackContextClientStatesResponse.has(state.ContextId)) {
                                    const callback = this.callbackContextClientStatesResponse.get(state.ContextId);
                                    if (callback) {
                                        callback(state);
                                    }
                                }
                            }
                        });
                    }
                    break;
                case "MessageHistoryReceived":
                    const message_history_response = decode(payload.Entity) as ChatItemViewModel[];
                    db.messages.bulkPut(message_history_response);
                    if (this.callbackMessageHistoryResponse) {
                        this.callbackMessageHistoryResponse(message_history_response);
                    }
                    break;
                case "DataQueryReceived":
                    const data_query_response = decode(payload.Entity) as DataQueryResult;
                    this.onHandleDataQueryResponse(data_query_response);
                    break;
                case "ChatItemSendStateReceived":
                    const chat_item_send_state_recevied = decode(payload.Entity) as ChatItemSendState;
                    db.messagesSendState.put(chat_item_send_state_recevied);
                    if (this.callbackMessageSendStateResponse) {
                        this.callbackMessageSendStateResponse(chat_item_send_state_recevied);
                    }
                    break;
                case "ReceiveMessage":
                    const received_message = decode(payload.Entity) as ChatItemViewModel;
                    db.messages.put(received_message);
                    if (this.callbackMessageHistoryResponse) {
                        this.callbackMessageHistoryResponse([received_message]);
                    }
                    if (received_message.IsCurrentUser === false) {
                        try {
                            if (this.callbackIncomingMessageCollection.has(received_message.ContextID)) {
                                const incoming_message_callback = this.callbackIncomingMessageCollection.get(received_message.ContextID);
                                if (incoming_message_callback) {
                                    incoming_message_callback(received_message);
                                }
                            }
                        } catch (error) {
                            logError(error);
                        }
                        this.onHandleMessageNotification(received_message);
                        this.onUpdateContextSort(received_message.ContextID, 50);
                        this.propagateReceivedMessage(received_message.ID);
                    }
                    break;
                case "ContextEntryResponse":
                    const context_entry_response = decode(payload.Entity) as ContextItemViewModel;
                    if (this.callbackContextEntryResponse) {
                        this.callbackContextEntryResponse(context_entry_response);
                    }
                    if (this.callbackContextEntryUserManagmentResponse) {
                        this.callbackContextEntryUserManagmentResponse(context_entry_response);
                    }
                    break;
                case "ContextEntiresServerSyncReceived":
                    const context_ids_response = decode(payload.Entity) as string[];
                    // update the local cache and if we remove some contextswe should update the ui
                    if (context_ids_response.length > 0) {
                        db.contexts.toArray().then((contexts) => {
                            let toRemove: string[] = [];
                            for (let index = 0; index < contexts.length; index++) {
                                const element = contexts[index];
                                var fIndex = context_ids_response.findIndex(x => {
                                    if (x == element.ID) {
                                        return true;
                                    }
                                });
                                if (fIndex === -1) {
                                    toRemove.push(element.ID);
                                }
                            }
                            if (toRemove.length > 0) {
                                // delete from local db
                                db.contexts.bulkDelete(toRemove);
                                // query current from server to auto refresh ui
                                this.contextListRequest();
                            }
                        });
                    }
                    break;
                case "ContextSearchReceived":
                    const context_search_response = decode(payload.Entity) as ContextItemViewModel[];
                    if (this.callbackContextSearchResponse) {
                        this.callbackContextSearchResponse(context_search_response);
                    }
                    break;
                case "UserManagmentReceived":
                    const users_response = decode(payload.Entity) as UserManagmentViewItem[];
                    if (this.callbackUsersResponse) {
                        this.callbackUsersResponse(users_response);
                    }
                    break;
                case "PermissionVisibleContextsReceived":
                    const permission_vis_response = decode(payload.Entity) as PermissionVisibleContextViewModel;
                    if (this.callbackPermissionVisibleResponse) {
                        this.callbackPermissionVisibleResponse(permission_vis_response);
                    }
                    break;
                case "PermissionManagmentReceived":
                    const permission_managment_response = decode(payload.Entity) as PermissionManagmentViewItem[];
                    if (this.callbackPermissionManagmentResponse) {
                        this.callbackPermissionManagmentResponse(permission_managment_response);
                    }
                    break;
                default:
                    break;
            }
        } catch (error) {
            logError(error);
        }
    }

    async onHandleMessageNotification(msg: ChatItemViewModel) {
        try {
            let msgID = this.newMessages.find(x => {
                if (x === msg.ID) {
                    return true;
                }
            });
            if (msgID) {
                return;
            }
            this.newMessages.push(msg.ID);
            playNotification();
            this.native.setNotificationCount(1);
            if (this.storage.getNativeNotificationMessage()) {
                let senderName = msg.Sender?.ID;
                if (msg.Sender && msg.Sender.Name) {
                    senderName = msg.Sender.Name;
                } else if (senderName) {
                    let context = await db.contexts
                        .filter(con => con.ID === senderName)
                        .limit(1)
                        .toArray();
                    if (context && context.length > 0) {
                        senderName = context[0].Title;
                    }
                }
                if (msg.RawContent) {
                    if (msg.Type === MessageType.Text) {
                        const document = new DOMParser().parseFromString(msg.RawContent, 'text/html');
                        console.log(document);
                        if (document && document.childNodes.length > 0) {
                            let txtContent = "";
                            document.childNodes.forEach(node => {
                                if (node.textContent) {
                                    txtContent += node.textContent + " ";
                                }
                            });
                            this.native.notification(senderName, txtContent.substring(0, 255))
                        } else {
                            this.native.notification(senderName, msg.RawContent.substring(0, 255))
                        }
                    } else if (msg.Type === MessageType.Image) {
                        this.native.notification(senderName, "Neues Bild")
                    }
                } else {
                    this.native.notification(senderName, "Neue Nachricht");
                }
            }
            if (this.newMessages.length > 25) {
                this.newMessages.splice(0, 15);
            }
        } catch (error) {
            logError(error);
        }
    }

    onHandleDataQueryResponse(response: DataQueryResult) {
        try {
            if (response.QueryName === "entityinfo") {
                if (response.Values?.length > 0) {
                    var query: string = "";
                    var entity: string = "";
                    var result: string = "";
                    var contextId: string = "";
                    response.Values.forEach(element => {
                        try {
                            if (element.startsWith("query=")) {
                                query = element.split("=")[1];
                            } else if (element.startsWith("entity=")) {
                                entity = element.split("=")[1];
                            } else if (element.startsWith("context_id=")) {
                                contextId = element.split("=")[1];
                            } else if (element.startsWith("result=")) {
                                result = element.split("=")[1];
                            }
                        } catch (error) {
                            logError(error);
                        }
                    });
                    if (query === "count") {
                        db.messageCount.put({
                            ContextID: contextId,
                            Count: +result,
                            Timestamp: new Date()
                        });
                    }
                }
            } else if (response.QueryName === "notreceived") {
                // query the message ids and report back to update received state
                if (response.Values?.length > 0) {
                    response.Values.forEach(messageId => {
                        this.loadMessages({
                            SearchKeyValues: [
                                `message=${messageId}`,
                                `redirect=ReceiveMessage`
                            ]
                        })
                    });
                }
            } else if (response.QueryName === "connectedsessions") {
                if (response.Values?.length > 0) {
                    var connectedSessions: ConnectSession[] = [];
                    response.Values.forEach(element => {
                        try {
                            var elementParts = element.split('|');
                            var type: string = "";
                            var serverId: string = "";
                            var clientId: string = "";
                            var deviceId: string = "";
                            if (elementParts[0].startsWith("type=")) {
                                type = elementParts[0].split("=")[1];
                            }
                            if (elementParts[1].startsWith("serverid=")) {
                                serverId = elementParts[1].split("=")[1];
                            }
                            if (elementParts[2].startsWith("clientid=")) {
                                clientId = elementParts[2].split("=")[1];
                            }
                            if (elementParts[3].startsWith("deviceid=")) {
                                deviceId = elementParts[3].split("=")[1];
                            }
                            connectedSessions.push({
                                Key: type + serverId + clientId + deviceId,
                                Type: type,
                                ServerId: serverId,
                                ClientId: clientId,
                                DeviceId: deviceId
                            });
                        } catch (error) {
                            logError(error);
                        }
                    });
                    if (this.callbackConnectedSessionsResponse) {
                        this.callbackConnectedSessionsResponse(connectedSessions);
                    }
                }
            } else if (response.QueryName === "messagecontextstatistic") {
                if (response.Values?.length > 0) {
                    var msgContextStatistics: MessageContextStatistic[] = [];
                    response.Values.forEach(element => {
                        try {
                            var elementParts = element.split('|');
                            var contextId: string = "";
                            var reverseContextId: string = "";
                            var rawType: string = "";
                            var messageCount: number = 0;
                            if (elementParts[0].startsWith("contextid=")) {
                                contextId = elementParts[0].split("=")[1];
                            }
                            if (elementParts[1].startsWith("reversecontextid=")) {
                                reverseContextId = elementParts[1].split("=")[1];
                            }
                            if (elementParts[2].startsWith("rawtype=")) {
                                rawType = elementParts[2].split("=")[1];
                            }
                            if (elementParts[3].startsWith("messagecount=")) {
                                messageCount = +elementParts[3].split("=")[1];
                            }
                            msgContextStatistics.push({
                                Key: contextId + reverseContextId + rawType,
                                ContextId: contextId,
                                ReverseContextId: reverseContextId,
                                RawType: rawType,
                                MessageCount: messageCount
                            });
                        } catch (error) {
                            logError(error);
                        }
                    });
                    if (this.callbackMessageContextStatisticResponse) {
                        this.callbackMessageContextStatisticResponse(msgContextStatistics);
                    }
                }
            } else if (response.QueryName === "contextmanagment") {
                if (response.Values?.length > 0) {
                    var contextEntries: ContextManagmentEntry[] = [];
                    response.Values.forEach(element => {
                        try {
                            var elementParts = element.split('|');
                            var id: string = "";
                            var title: string = "";
                            var type: string = "";
                            var domain: string = "";
                            var children: number = 0;
                            if (elementParts[0].startsWith("id=")) {
                                id = elementParts[0].split("=")[1];
                            }
                            if (elementParts[1].startsWith("title=")) {
                                title = elementParts[1].split("=")[1];
                            }
                            if (elementParts[2].startsWith("type=")) {
                                type = elementParts[2].split("=")[1];
                            }
                            if (elementParts[3].startsWith("children=")) {
                                children = +elementParts[3].split("=")[1];
                            }
                            if (elementParts[4].startsWith("domain=")) {
                                domain = elementParts[4].split("=")[1];
                            }
                            contextEntries.push({
                                Id: id,
                                Title: title,
                                Type: type,
                                Children: children,
                                Domain: domain
                            });
                        } catch (error) {
                            logError(error);
                        }
                    });
                    if (this.callbackContextManagmentResponse) {
                        this.callbackContextManagmentResponse(contextEntries);
                    }
                }
            } else {
                console.warn(`Unknown DataQuery result received:${response.QueryName}`);
            }

        } catch (error) {
            logError(error);
        }
    }

    callbackLoginResponse: ((response: UserLoginResponse) => void) | null = null;
    subReceiveLoginResult(callback: (response: UserLoginResponse) => void) {
        this.callbackLoginResponse = callback;
    }

    callbackContextListResponse: ((response: ContextItemViewModel[]) => void) | null = null;
    subReceiveContextListResult(callback: (response: ContextItemViewModel[]) => void) {
        this.callbackContextListResponse = callback;
    }

    callbackContextListChatViewResponse: ((response: ContextItemViewModel[]) => void) | null = null;
    subReceiveContextListChatViewResult(callback: (response: ContextItemViewModel[]) => void) {
        this.callbackContextListChatViewResponse = callback;
    }

    // Listener for context changed with sorted contexts are in the `App.tsx` for the Sidebar and `ChatView.tsx` for an open Chat view (Title ...).
    onContextsChanged(contexts: ContextItemViewModel[]) {
        const sorted = this.onSortContexts(contexts);
        if (this.callbackContextListResponse) {
            this.callbackContextListResponse(sorted);
        }
        if (this.callbackContextListChatViewResponse) {
            this.callbackContextListChatViewResponse(sorted);
        }
    }

    callbackContextClientStatesResponse: Map<String, ((state: ClientState) => void) | null> = new Map();
    subContextClientStatesResult(contextID: String, callback: (state: ClientState) => void) {
        this.callbackContextClientStatesResponse.set(contextID, callback);
    }

    callbackCurrentUserClientState: ((response: ClientState) => void) | null = null;
    subCurrentUserClientState(callback: (response: ClientState) => void) {
        this.callbackCurrentUserClientState = callback;
    }

    callbackMessageHistoryResponse: ((response: ChatItemViewModel[]) => void) | null = null;
    subReceiveMessageHistoryResult(callback: (response: ChatItemViewModel[]) => void) {
        this.callbackMessageHistoryResponse = callback;
    }

    callbackMessageSendStateResponse: ((response: ChatItemSendState) => void) | null = null;
    subReceiveMessageSendStateResult(callback: (response: ChatItemSendState) => void) {
        this.callbackMessageSendStateResponse = callback;
    }

    callbackIncomingMessageCollection: Map<String, ((message: ChatItemViewModel) => void) | null> = new Map();
    subIncomingMessageResult(contextID: String, callback: (message: ChatItemViewModel) => void) {
        this.callbackIncomingMessageCollection.set(contextID, callback);
    }

    callbackUnreadResetMessageCollection: Map<String, (() => void) | null> = new Map();
    subUnreadResetMessage(contextID: String, callback: () => void) {
        this.callbackUnreadResetMessageCollection.set(contextID, callback);
    }

    callbackConnectedSessionsResponse: ((response: ConnectSession[]) => void) | null = null;
    subConnectedSessionsResult(callback: (response: ConnectSession[]) => void) {
        this.callbackConnectedSessionsResponse = callback;
    }

    callbackMessageContextStatisticResponse: ((response: MessageContextStatistic[]) => void) | null = null;
    subMessageContextStatisticResult(callback: (response: MessageContextStatistic[]) => void) {
        this.callbackMessageContextStatisticResponse = callback;
    }

    callbackContextManagmentResponse: ((response: ContextManagmentEntry[]) => void) | null = null;
    subContextManagmentResult(callback: (response: ContextManagmentEntry[]) => void) {
        this.callbackContextManagmentResponse = callback;
    }

    callbackContextEntryResponse: ((response: ContextItemViewModel) => void) | null = null;
    subContextEntryResult(callback: (response: ContextItemViewModel) => void) {
        this.callbackContextEntryResponse = callback;
    }

    callbackContextEntryUserManagmentResponse: ((response: ContextItemViewModel) => void) | null = null;
    subContextEntryUserManagmentResult(callback: (response: ContextItemViewModel) => void) {
        this.callbackContextEntryUserManagmentResponse = callback;
    }

    callbackContextSearchResponse: ((response: ContextItemViewModel[]) => void) | null = null;
    subContextSearchResult(callback: (response: ContextItemViewModel[]) => void) {
        this.callbackContextSearchResponse = callback;
    }

    callbackUsersResponse: ((response: UserManagmentViewItem[]) => void) | null = null;
    subUsersResult(callback: (response: UserManagmentViewItem[]) => void) {
        this.callbackUsersResponse = callback;
    }

    callbackPermissionVisibleResponse: ((response: PermissionVisibleContextViewModel) => void) | null = null;
    subPermissionVisibleResult(callback: (response: PermissionVisibleContextViewModel) => void) {
        this.callbackPermissionVisibleResponse = callback;
    }

    callbackPermissionManagmentResponse: ((response: PermissionManagmentViewItem[]) => void) | null = null;
    subPermissionManagmentResult(callback: (response: PermissionManagmentViewItem[]) => void) {
        this.callbackPermissionManagmentResponse = callback;
    }

    callbackStartReconnecting: (() => void) | null = null;
    subStartReconnecting(callback: () => void) {
        this.callbackStartReconnecting = callback;
    }

    onSend(method: string, payload: any) {
        if (this.connection && this.connection.state === signalR.HubConnectionState.Connected) {
            this.connection.send(method, payload).then(() => {
                //console.log(`Send:${method}`);
            }).catch((err) => {
                logError(err);
            });
        } else {
            //logError(new Error("try to call send but no connected exists"));
        }
    }

    onSendEntity(method: string, entity: any) {
        let payload: ExchangeEntity = {
            TransferId: uuidv4(),
            Token: this.storage.getToken() || "",
            ClientId: this.storage.getClientId(),
            DeviceId: this.storage.getDeviceId(),
            HomeUrl: undefined,
            Key: method,
            Entity: encode(entity)
        };
        this.onSend("RedirectToExchange", payload);
    }

    login(login: LoginViewModel) {
        try {
            this.onSendEntity("LoginRequest", login);
        } catch (error) {
            logError(error);
        }
    }

    logout(logout: UserLogoutRequest) {
        this.onSendEntity("LogoutRequest", logout);
    }

    reconnect() {
        try {
            if (this.storage
                && !isEmptyOrSpaces(this.storage.getToken())) {
                if (this.callbackStartReconnecting) {
                    this.callbackStartReconnecting();
                }
                this.onSendEntity("ReconnectSession", this.storage.getToken());
            }
        } catch (error) {
            logError(error);
        }
    }

    loadMessages(query: MessageHistorySearch) {
        this.onSendEntity("MessageHistoryRequest", query);
    }

    contextListRequest() {
        this.onSendEntity("ContextUserListRequest", new ContextUserListSearch());
    }

    contextListRequestAndLoadCache(afterLogin: boolean) {
        let search: ContextUserListSearch = {
            AfterLogin: afterLogin
        };
        try {
            this.onTriggerSidebarContextChange().then(() => {
                this.onSendEntity("ContextUserListRequest", search);
            });
        } catch (error) {
            logError(error);
            this.onSendEntity("ContextUserListRequest", search);
        }
    }

    contextSaveRequest(context: ContextModel) {
        this.onSendEntity("ContextSaveRequest", context);
    }

    dataQueryRequest(query: DataQuerySearch) {
        this.onSendEntity("DataQueryRequest", query);
    }

    sendRequest(message: ChatItemViewModel) {
        try {
            this.onAddSendingMessage(message);
            this.onSendEntity("SendRequest", message);
            this.resetUnread(message.ContextID);
            this.onUpdateContextSort(message.ContextID, 50);
        } catch (error) {
            logError(error);
        }
    }

    onAddSendingMessage(message: ChatItemViewModel) {
        try {
            db.messages.put(message);
            if (this.callbackMessageHistoryResponse) {
                this.callbackMessageHistoryResponse([message]);
            }
            let state: ChatItemSendState = {
                ContextID: message.ContextID,
                IsSending: true,
                MessageID: message.ID,
                TransferOK: false
            };
            db.messagesSendState.put(state);
            if (this.callbackMessageSendStateResponse) {
                this.callbackMessageSendStateResponse(state);
            }
        } catch (error) {
            logError(error);
        }
    }

    resetUnread(contextID: String) {
        try {
            if (this.callbackUnreadResetMessageCollection.has(contextID)) {
                const unread_reset_callback = this.callbackUnreadResetMessageCollection.get(contextID);
                if (unread_reset_callback) {
                    unread_reset_callback();
                }
            }
        } catch (error) {
            logError(error);
        }
        try {
            this.native.setNotificationCount(0);
        } catch (error) {
            logError(error);
        }
    }

    propagateClientState() {
        try {
            if (this.storage
                && !isEmptyOrSpaces(this.storage.getClientId())
                && !isEmptyOrSpaces(this.storage.getToken())) {
                if (document.hidden === true && !this.native.isDesktop()) {
                    if (process.env.REACT_APP_API_BASE_URL) {
                        const state: SessionClientState = {
                            ContextId: this.storage.getClientId(),
                            DeviceId: this.storage.getDeviceId(),
                            State: "activebackground",
                            Timestamp: new Date(),
                            Token: this.storage.getToken() || "",
                            HomeUrl: this.storage.getHomeUrl() || "",
                        };
                        const requestOptions = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify(state)
                        };
                        fetch(process.env.REACT_APP_API_BASE_URL + '/propagateclientstate', requestOptions)
                            .then(response => {
                                if (response.ok === false) {
                                    console.log(response.json());
                                }
                            });
                    } else {
                        throw new Error("no api base url was provied in the environment variable:\"REACT_APP_API_BASE_URL\"");
                    }
                } else {
                    const clientState: ClientState = {
                        ContextId: this.storage.getClientId(),
                        Timestamp: new Date(),
                        State: "online"
                    };
                    this.onSendEntity("PropagateClientState", clientState);
                }
            }
        } catch (error) {
            logError(error);
        }
    }

    queryNotReceived() {
        try {
            if (this.storage
                && !isEmptyOrSpaces(this.storage.getClientId())
                && !isEmptyOrSpaces(this.storage.getToken())) {
                this.dataQueryRequest({
                    QueryName: "notreceived",
                    SearchKeyValues: []
                });
            }
        } catch (error) {
            logError(error);
        }
    }

    queryConnectedSessions() {
        try {
            this.dataQueryRequest({
                QueryName: "connectedsessions",
                SearchKeyValues: []
            });
        } catch (error) {
            logError(error);
        }
    }

    queryContextManagment() {
        try {
            this.dataQueryRequest({
                QueryName: "contextmanagment",
                SearchKeyValues: []
            });
        } catch (error) {
            logError(error);
        }
    }

    queryContextManagmentEntry(id: string) {
        try {
            this.dataQueryRequest({
                QueryName: "contextentry",
                SearchKeyValues: [
                    `id=${id}`
                ]
            });
        } catch (error) {
            logError(error);
        }
    }

    queryContextManagmentEntryDelete(id: string) {
        try {
            this.dataQueryRequest({
                QueryName: "contextentrydelete",
                SearchKeyValues: [
                    `id=${id}`
                ]
            });
        } catch (error) {
            logError(error);
        }
    }

    queryContextEntriesServerSync() {
        try {
            this.dataQueryRequest({
                QueryName: "contextentiresserversync",
                SearchKeyValues: []
            });
        } catch (error) {
            logError(error);
        }
    }

    queryMessageContextStatistics() {
        try {
            this.dataQueryRequest({
                QueryName: "messagecontextstatistic",
                SearchKeyValues: []
            });
        } catch (error) {
            logError(error);
        }
    }

    propagateReceivedMessage(messageId: string) {
        try {
            this.dataQueryRequest({
                QueryName: "updatemessagestate",
                SearchKeyValues: [
                    `message=${messageId}`,
                    `received=true`,
                ]
            });
        } catch (error) {
            logError(error);
        }
    }

    propagateReadMessage(messageId: string) {
        try {
            this.dataQueryRequest({
                QueryName: "updatemessagestate",
                SearchKeyValues: [
                    `message=${messageId}`,
                    `read=true`,
                ]
            });
        } catch (error) {
            logError(error);
        }
    }

    queryContextSearch(search: string) {
        try {
            this.dataQueryRequest({
                QueryName: "contextsearch",
                SearchKeyValues: [
                    `search=${search}`
                ]
            });
        } catch (error) {
            logError(error);
        }
    }

    queryUserManagment() {
        try {
            this.dataQueryRequest({
                QueryName: "usermanagment",
                SearchKeyValues: []
            });
        } catch (error) {
            logError(error);
        }
    }

    queryPermissionManagment() {
        try {
            this.dataQueryRequest({
                QueryName: "permissionmanagment",
                SearchKeyValues: []
            });
        } catch (error) {
            logError(error);
        }
    }

    localUserSaveRequest(userName: string, pw: string) {
        try {
            this.dataQueryRequest({
                QueryName: "addlocaluser",
                SearchKeyValues: [
                    `user=${userName}`,
                    `pw=${pw}`
                ]
            });
        } catch (error) {
            logError(error);
        }
    }

    permissionVisibleContextSaveRequest(context: string, visible: string) {
        try {
            this.dataQueryRequest({
                QueryName: "permissionvisiblecontextschange",
                SearchKeyValues: [
                    `context=${context}`,
                    `visible=${visible}`
                ]
            });
        } catch (error) {
            logError(error);
        }
    }

    permissionRoleSaveRequest(ids: string, newRole: number) {
        try {
            this.dataQueryRequest({
                QueryName: "permissionrolechange",
                SearchKeyValues: [
                    `newrole=${newRole}`,
                    `ids=${ids}`
                ]
            });
        } catch (error) {
            logError(error);
        }
    }

    permissionVisibleContextLoadRequest(context: string) {
        try {
            this.dataQueryRequest({
                QueryName: "permissionvisiblecontexts",
                SearchKeyValues: [
                    `context=${context}`
                ]
            });
        } catch (error) {
            logError(error);
        }
    }

    updateContextUserStateRequest(context: string, isPinned: boolean, orderBy: number, orderChanged: any, isHidden: boolean) {
        try {
            this.dataQueryRequest({
                QueryName: "updatecontextuserstate",
                SearchKeyValues: [
                    `contextid=${context}`,
                    `ispinned=${isPinned}`,
                    `orderby=${orderBy}`,
                    `orderchanged=${orderChanged}`,
                    `ishidden=${isHidden}`
                ]
            });
        } catch (error) {
            logError(error);
        }
    }

    async onUpdateContextSort(contextId: string, value: number) {
        try {
            const contextTmp = await db.contexts
                .where('ID')
                .equalsIgnoreCase(contextId)
                .first();
            if (contextTmp) {
                let serverStateUpdate = true;
                // throttle check for server context state updates
                if (contextTmp.OrderBy === value) {
                    // order value has't changed
                    // if the old `OrderChanged` is not today we should update the state on the server
                    let lastCtxtDate = getDateFromTicks(contextTmp.OrderChanged);
                    let now = new Date();
                    let day = lastCtxtDate.getDate();
                    let month = lastCtxtDate.getMonth();
                    let year = lastCtxtDate.getFullYear();
                    if (now.getDate() === day && now.getMonth() === month && now.getFullYear() === year) {
                        serverStateUpdate = false;
                    }
                }
                // we only need to send to the server if there is a major changes or time has gone by
                contextTmp.OrderBy = value;
                contextTmp.OrderChanged = getTicks(new Date());
                await db.contexts.put(contextTmp);
                await this.onTriggerSidebarContextChange();
                if (serverStateUpdate === true) {
                    // persist context order state on the server
                    this.updateContextUserStateRequest(contextId, contextTmp.IsPinned, contextTmp.OrderBy, contextTmp.OrderChanged, contextTmp.IsHidden);
                }
            }
        } catch (error) {
            logError(error);
        }
    }

    async onTriggerSidebarContextChange() {
        try {
            db.contexts.toArray().then(contexts => {
                if (contexts && contexts.length > 0) {
                    const now = new Date();
                    let currentContexts: ContextItemViewModel[] = [];
                    contexts.forEach(item => {
                        if (item.OrderChanged) {
                            const dif = Math.abs(now.getTime() - getDateFromTicks(item.OrderChanged).getTime());
                            let hours = dif / (1000 * 60 * 60);
                            if (hours >= 1 && hours < 24) {
                                // after one hour without a message in the context
                                item.OrderBy = 25;
                            } else if (hours >= 24 && hours < 48) {
                                // reduce sorting order after 1 day
                                item.OrderBy = 15;
                            } else if (hours >= 48 && hours < 168) {
                                // up to one week (7 days)
                                item.OrderBy = 10;
                            } else if (hours >= 168) {
                                item.IsHidden = true;
                                item.OrderBy = 0;
                            }
                        } else {
                            // not a valid order changed (could be inital value or new entry)
                            item.IsHidden = true;
                            item.OrderBy = 0;
                        }
                        if (item.IsHidden === false) {
                            currentContexts.push(item);
                        }
                    });
                    this.onContextsChanged(currentContexts);
                }
            });
        } catch (error) {
            logError(error);
        }
    }

    onSortContexts(contexts: ContextItemViewModel[]) {
        try {
            return contexts.sort((n1, n2) => {
                if (n1.OrderBy > n2.OrderBy) {
                    return -1;
                }
                if (n1.OrderBy < n2.OrderBy) {
                    return 1;
                }
                return 0;
            });
        } catch (error) {
            logError(error);
        }
        return contexts;
    }
}

class ExchangeEntity {
    TransferId!: string;
    ClientId!: string;
    DeviceId!: string;
    Token!: string;
    HomeUrl?: string;
    Key!: string;
    Entity!: ArrayBuffer;
}

class SessionClientState {
    Token!: string;
    ContextId!: string;
    DeviceId!: string;
    Timestamp!: Date;
    UserSelectedState?: string;
    State!: string;
    HomeUrl?: string;
}

const hubProxy: HubProxy = new HubProxy();

export function useHub() {
    const [hub] = useState(hubProxy);
    const store = useLocalStorage();
    hub.setStorage(store);
    return hub;
}
