import React, { useRef, useState } from 'react';

import { v4 as uuidv4 } from 'uuid';

import {
  Form, Input, Button, Spin, Row, Col, Divider, Slider, InputNumber
  , Avatar, Tooltip, Modal
} from 'antd';
import { EditOutlined, PhoneOutlined } from '@ant-design/icons';

import './ChatView.less'
import { getImageUrl, isEmptyOrSpaces, logError } from '../Services/Helpers';
import { useLocalStorage } from '../Services/LocalStorage';
import { ContextItemViewModel } from '../Context/Models';
import UserInput from './UserInput';
import Messages from './Messages';
import { useParams } from 'react-router-dom';
import { useHub } from '../Services/HubProxy';
import { db } from '../Services/DB';
import { ChatItemViewModel } from './Models';
import ContextEdit from '../Context/ContextEdit';
import { useCall } from '../Services/Call';

export default function ChatView() {
  const storage = useLocalStorage();
  const hub = useHub();
  const call = useCall();

  const { contextId } = useParams();
  const [context, setContext] = useState<ContextItemViewModel | undefined>();
  const [grpContext, setGrpContext] = useState<ContextItemViewModel[]>([]);
  const [title, setTitle] = useState("");
  const [quoteMessage, setQuoteMessage] = useState<ChatItemViewModel | undefined>();
  const [editMessage, setEditMessage] = useState<ChatItemViewModel | undefined>();
  const [showEditContext, setShowEditContext] = useState(false);


  React.useEffect(() => {
    if (contextId !== undefined) {
      if (contextId !== undefined) {
        if (context?.ID !== contextId) {
          try {
            db.contexts
              .where('ID')
              .equalsIgnoreCase(contextId)
              .first().then((contextTmp) => {
                if (contextTmp !== undefined) {
                  setTitle(contextTmp?.Title || "");
                  setContext(contextTmp);
                  if (contextTmp.ChildContexts?.length > 0) {
                    setGrpContext(contextTmp.ChildContexts);
                  } else {
                    setGrpContext([contextTmp]);
                  }
                }
              });
          } catch (error) {
            logError(error);
          }
        }
      }
    }
  }, [contextId]);

  hub.subReceiveContextListChatViewResult((contexts) => {
    try {
      if (contexts?.length > 0) {
        let newItem = contexts.find(x => {
          if (x.ID === contextId) {
            return true;
          }
        });
        if (newItem) {
          // update context data
          setTitle(newItem?.Title || "");
          setContext(newItem);
          if (newItem.ChildContexts?.length > 0) {
            setGrpContext(newItem.ChildContexts);
          } else {
            setGrpContext([newItem]);
          }
        }
      }
    } catch (error) {
      logError(error);
    }
  });

  const onQuote = (message: ChatItemViewModel) => {
    try {
      setQuoteMessage(message);
      setTimeout(() => {
        setQuoteMessage(undefined);
      }, 500);
    } catch (error) {
      logError(error);
    }
  }

  const onEditMsg = (message: ChatItemViewModel) => {
    try {
      setEditMessage(message);
      setTimeout(() => {
        setEditMessage(undefined);
      }, 500);
    } catch (error) {
      logError(error);
    }
  }

  const onEditContextClick = () => {
    setShowEditContext(true);
  }

  const onCallContextClick = () => {
    try {
      if (context) {
        const clientId = storage.getClientId();
        call.start(`${context.ID}|@|${clientId}`, [
            context.ID,
            clientId,
          ]);
      }
    } catch (error) {
      logError(error);
    }
  }

  const onCanEditContext = (context: ContextItemViewModel | undefined): boolean => {
    try {
      if (context && context.KnownType === "virtual") {
        return true;
      }
    } catch (error) {
      logError(error);
    }
    return false;
  }

  return (
    <div className="chat-view-container">
      <div className="chat-view-header" >
        <div className='chat-view-header-title-wrapper'>
          <span className='chat-view-header-title-text'>{title}</span>
        </div>
        <div className='chat-view-header-avatar-wrapper'>
          <Avatar.Group>
            {
              grpContext.map((entry, i) =>
                <Tooltip key={i} title={entry.Title} placement="bottom">
                  <Avatar key={i} src={!isEmptyOrSpaces(entry.Thumbnail) ? getImageUrl(entry.Thumbnail) : ''}
                    style={{ backgroundColor: !isEmptyOrSpaces(entry.Color) ? entry.Color : "orange", verticalAlign: 'middle' }}
                    size="default">
                    {
                      !isEmptyOrSpaces(entry.ShorthandSymbol) ? entry.ShorthandSymbol : entry.Title.substring(0, 2)
                    }
                  </Avatar>
                </Tooltip>
              )
            }
          </Avatar.Group>
        </div>
        <div className='chat-view-header-actions-wrapper'>
          {
            onCanEditContext(context) ?
              <Button onClick={onEditContextClick} type="primary" shape="default" icon={<EditOutlined />} />
              :
              ''
          }
          <Button style={{ marginLeft: '15px' }} onClick={onCallContextClick} type="primary" shape="default" icon={<PhoneOutlined />} />
        </div>
      </div>
      <div className="chat-view-content">
        <Messages model={context} quoteMessage={onQuote} editMessage={onEditMsg} />
      </div>
      <div className="chat-view-footer">
        <UserInput model={context} quoteMessage={quoteMessage} editMessage={editMessage} />
      </div>
      <ContextEdit hideContextSelect={false} model={context} show={showEditContext} closeCallback={() => setShowEditContext(false)} />
    </div >
  )
};