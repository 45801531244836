import React, { useState } from 'react';
import {
    Form, Input, Modal
} from 'antd';

import { getDigest, isEmptyOrSpaces, logError } from '../Services/Helpers';
import { useHub } from '../Services/HubProxy';

const { Search } = Input;

export default function AddLocalUser(props: { show: boolean; closeCallback: () => void; }) {
    const hub = useHub();
    const [showAddUser, setShowAddUser] = useState(props.show);
    const [userName, setUserName] = useState("");
    const [userPassword, setUserPassword] = useState("");

    const [confirmLoading, setConfirmLoading] = useState(false);
    const [allowedSafe, setAllowedSafe] = useState(false);
    const [pwRepeatValid, setPWRepeatValid] = useState(false);

    const [form] = Form.useForm();

    React.useEffect(() => {
        setShowAddUser(props.show);
        try {
            form.resetFields();    
        } catch (error) {
            logError(error);
        }
    }, [props.show]);

    const handleOk = async () => {
        try {
            setConfirmLoading(true);
            let userName = form.getFieldValue("username");
            let pw = form.getFieldValue("password");
            if (!isEmptyOrSpaces(userName) && !isEmptyOrSpaces(pw)) {
                hub.localUserSaveRequest(userName, await getDigest(pw));
                setTimeout(() => {
                    setShowAddUser(false);
                    setConfirmLoading(false);
                }, 100);
            }
        } catch (error) {
            logError(error);
            setConfirmLoading(false);
        }
    };

    const onAfterClose = () => {
        try {

        } catch (error) {
            logError(error);
        }
        props.closeCallback();
    }

    const onFormValuesChange = (changedValues: any, values: any) => {
        try {
            if (!isEmptyOrSpaces(values.username)
                && !isEmptyOrSpaces(values.password)
                && !isEmptyOrSpaces(values.password_match)) {
                    form.validateFields().then(value => {
                        //console.log("Success", value);
                    }).catch(info => {
                        //console.log("Failed", info);
                        if (info.errorFields.length === 0) {
                            if (values.password !== values.password_match) {
                                setPWRepeatValid(false);
                                setAllowedSafe(false);
                            } else {
                                setPWRepeatValid(true);
                                setAllowedSafe(true);
                            }
                            
                        }
                    });
                    
            }
        } catch (error) {
            logError(error);
        }
    }

    return (
        <Modal
            forceRender
            className='user-add-container'
            title="Benutzer hinzufügen"
            centered
            visible={showAddUser}
            onOk={handleOk}
            onCancel={() => setShowAddUser(false)}
            afterClose={() => onAfterClose()}
            confirmLoading={confirmLoading}
            okText="Speichern"
            cancelText="Abbrechen"
            destroyOnClose={true}
            okButtonProps={{ disabled: !allowedSafe }}
        >
            <div className='user-add-wrapper'>
                <Form
                    form={form}
                    onValuesChange={onFormValuesChange}
                    name="basic"
                    layout='vertical'
                    initialValues={{ remember: false }}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Benutzer (Login Name)"
                        name="username"
                        rules={[
                            { required: true, message: 'Benutzer name wird benötigt!' },
                            { min: 5, message: 'Benutzer name muss mindestens 4 Zeichen lang sein!' },
                            { whitespace: true, message: 'Benutzer name kann nicht aus Leerzeichen bestehen!' }
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Passwort"
                        name="password"
                        rules={[
                            { required: true, message: 'Passwort wird benötigt!' },
                            { min: 8, message: 'Passwort muss mindestens 8 Zeichen lang sein!' },
                            { whitespace: true, message: 'Benutzer name kann nicht aus Leerzeichen bestehen!' }
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        validateStatus={!pwRepeatValid ? 'error' : 'success'}
                        help={!pwRepeatValid ? "Passwort muss übereinstimmen!" : ""}
                        label="Passwort wiederholen"
                        name="password_match"
                        rules={[{ required: true, message: 'Passwort muss übereinstimmen!' }]}
                    >
                        <Input.Password />
                    </Form.Item>
                </Form>
                <p>Anzeige Name des Benutzers, Bild und Kürzel können nach dem Erstellen beim Bearbeiten Angepasst werden!</p>
            </div>
        </Modal>
    );
};