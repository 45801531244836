import Dexie, { Table } from 'dexie';
import { ChatItemSendState, ChatItemViewModel, MessageCount } from '../Chat/Models';
import { ContextItemViewModel } from '../Context/Models';
import { logError } from './Helpers';

export class Database extends Dexie {
  contexts!: Table<ContextItemViewModel>;
  messages!: Table<ChatItemViewModel>;
  messageCount!: Table<MessageCount>;
  messagesSendState!: Table<ChatItemSendState>;

  constructor() {
    super('croco-main');
    this.create();
  }

  create() {
    this.version(2).stores({
      contexts: 'ID, Title',
      messages: 'ID, ContextID, Time',
      messageCount: 'ContextID',
      messagesSendState: 'MessageID, ContextID',
  });
  }

  clean() {
    this.delete().then(() => {
      //console.log("recreate db");
      this.create();
      //console.log("open db");
      if (!this.isOpen()) {
        this.open().then((dix) => {
          //console.log("db is open");
        });
      } else {
        //console.log("db is already open");
      }
    });
  }

  async clearOldData() {
    try {
      const oneWeekAgo = new Date(Date.now() - 60*60*1000*24*14);
      await db.messages
        .where('Time').below(oneWeekAgo)
        .delete();
        await db.messagesSendState.clear();
      /*await db.logEntries
        .where('timestamp').below(oneWeekAgo)
        .delete();*/
    } catch (error) {
      logError(error);
    }
  }
}

export const db = new Database();
