import { useState, useMemo, useCallback, useEffect, useRef } from 'react'

import { Virtuoso, VirtuosoHandle } from 'react-virtuoso'

import { Form, Input, Button, Spin, Row, Col, Divider, Slider, InputNumber, Dropdown, Menu, Avatar, Tooltip } from 'antd';
import Icon, { DownOutlined, MoreOutlined, CodeOutlined, EditOutlined, LoadingOutlined, CheckCircleFilled, WarningFilled } from '@ant-design/icons';

import './Messages.less'
import { dateFormatToday, getImageUrl, isEmptyOrSpaces, logError } from '../Services/Helpers';
import { Active, ContextItemViewModel } from '../Context/Models';
import { useHub } from '../Services/HubProxy';
import React from 'react';
import { ChatItemSendState, ChatItemViewModel, MessageCount, MessageType } from './Models';
import { db } from '../Services/DB';
import MessageContentText from './MessageContentText';
import MessageContentImage from './MessageContentImage';
import { QuoteIcon } from '../Shared/CustomIcons';

export default function Messages(props: { model: ContextItemViewModel | undefined; quoteMessage: (msg: ChatItemViewModel) => void; editMessage: (msg: ChatItemViewModel) => void; }) {
    const usersToPrepend = 20;

    const virtuosoRef = useRef<VirtuosoHandle>(null);
    const [atBottom, setAtBottom] = useState(false);
    const showButtonTimeoutRef = useRef<any>(null);
    const [showButton, setShowButton] = useState(false);
    const [context, setContext] = useState(props.model);
    const [firstItemIndex, setFirstItemIndex] = useState(0);
    const [initialIndex, setInitialIndex] = useState(0)
    const [messages, setMessages] = useState<ChatItemViewModel[]>([])
    const [grpContext, setGrpContext] = useState<ContextItemViewModel[]>([]);
    const [msgSendState, setMsgSendState] = useState<ChatItemSendState[]>([]);
    const hub = useHub();

    React.useEffect(() => {
        let subscribed = true;
        if (props.model !== undefined) {
            if (context && context.ID === props.model.ID) {
                // no need to update this could happen if the sidebar changes
                return;
            }
            setMessages([]);
            setMsgSendState([]);
            setContext(props.model);
            if (props.model !== undefined) {
                hub.dataQueryRequest({
                    QueryName: "entityinfo",
                    SearchKeyValues: [
                        `context_id=${props.model.ID}`,
                        `context_type=${props.model.KnownType}`,
                        `entity=messages`,
                        `count=true`,
                    ]
                });
                hub.loadMessages({
                    SearchKeyValues: [
                        `context_id=${props.model.ID}`,
                        `context_type=${props.model.KnownType}`,
                        `last_msg_count=30`,
                    ]
                });
            }
            if (props.model.ID !== undefined) {
                if (context?.ID !== props.model.ID) {
                    db.contexts
                        .where('ID')
                        .equalsIgnoreCase(props.model.ID)
                        .first().then((contextTmp) => {
                            if (subscribed) {
                                if (contextTmp !== undefined) {
                                    if (contextTmp.ChildContexts?.length > 0) {
                                        setGrpContext(contextTmp.ChildContexts);
                                    } else {
                                        setGrpContext([contextTmp]);
                                    }
                                }
                            }
                        });
                        if (subscribed) {
                            onSetMessages(props.model.ID);
                            onSetSendState(props.model.ID);
                        }
                }
            }
        } else {
            // should not happen
        }
        return () => {
            subscribed = false;
        }
    }, [props.model]);

    useEffect(() => {
        return () => {
            if (showButtonTimeoutRef.current) {
                clearTimeout(showButtonTimeoutRef.current)
            }
        }
    }, [])

    useEffect(() => {
        if (showButtonTimeoutRef.current) {
            clearTimeout(showButtonTimeoutRef.current);
        }
        if (!atBottom) {
            showButtonTimeoutRef.current = setTimeout(() => setShowButton(true), 500)
        } else {
            setShowButton(false)
        }
    }, [atBottom, setShowButton])


    hub.subReceiveMessageHistoryResult(async (messages) => {
        try {
            if (context !== undefined) {
                // only refresh if there is any message for the current context in the received messages
                var msg = messages.find(x => {
                    if (x.ContextID === context.ID) {
                        return true;
                    }
                    return false;
                });
                if (msg) {
                    await onSetMessages(context.ID);
                }
            }
        } catch (error) {
            logError(error);
        }
    });

    hub.subReceiveMessageSendStateResult(async (state) => {
        try {
            // only update when we are still in the context window
            if (context && state.ContextID === context.ID) {
                await onSetSendState(context.ID)
            }
        } catch (error) {
            logError(error);
        }
    });

    const onGetSendState = (msgID: string): ChatItemSendState | undefined => {
        try {
            let state = msgSendState.find(x => {
                if (x.MessageID === msgID) {
                    return true;
                }
            });
            if (state) {
                return state;
            }
        } catch (error) {
            logError(error);
        }
        return undefined;
    }

    const onSetSendState = async (contextID: string) => {
        try {
            const states = await db.messagesSendState
                .where('ContextID')
                .equalsIgnoreCase(contextID).toArray();
            setMsgSendState(states);
        } catch (error) {
            logError(error);
        }
    }

    const onSetMessages = async (contextID: string) => {
        try {
            let offset = 0;
            const msgs1 = await db.messages
                .where('ContextID')
                .equalsIgnoreCase(contextID)
                .offset(offset)
                .sortBy('Time');
            var msgs = msgs1.sort((n1, n2) => {
                if (n1.Time > n2.Time) {
                    return 1;
                }
                if (n1.Time < n2.Time) {
                    return -1;
                }
                return 0;
            });
            msgs = msgs.slice(-30);
            const totalItems = await db.messageCount
                .where('ContextID')
                .equalsIgnoreCase(contextID)
                .first();
            if (totalItems !== undefined) {
                let nextFirstItemIndex = totalItems.Count - msgs.length;
                if (nextFirstItemIndex < 0) {
                    nextFirstItemIndex = 0;
                }
                setFirstItemIndex(nextFirstItemIndex);
                setInitialIndex(totalItems.Count);
            } else {
                setFirstItemIndex(0);
                setInitialIndex(msgs.length);
            }
            setMessages(msgs);

            try {
                setTimeout(() => {
                    if (virtuosoRef.current) {
                        virtuosoRef.current.scrollToIndex({ index: msgs.length - 1, behavior: 'smooth' });
                    }    
                }, 250);
            } catch (error) {
                logError(error);
            }
        } catch (error) {
            logError(error);
        }
    }

    const prependItems = useCallback(async () => {
        let nextFirstItemIndex = firstItemIndex - usersToPrepend;
        if (nextFirstItemIndex < 0) {
            nextFirstItemIndex = 0;
        }
        const firstMsg = messages[0];
        // check if messages before the first loaded message are already in the db
        const msgs1 = await db.messages
            .where('ContextID')
            .equalsIgnoreCase(firstMsg.ContextID)
            .and(x => x.Time < firstMsg.Time)
            .sortBy('Time');
        var msgs = msgs1.sort((n1, n2) => {
            if (n1.Time > n2.Time) {
                return 1;
            }
            if (n1.Time < n2.Time) {
                return -1;
            }
            return 0;
        });
        msgs = msgs.slice(-usersToPrepend);
        if (msgs?.length > 0) {
            if (nextFirstItemIndex < 0) {
                nextFirstItemIndex = 0;
            }
            setFirstItemIndex(() => nextFirstItemIndex);
            setMessages(() => [...msgs, ...messages]);
        } else {
            if (context !== undefined) {
                hub.dataQueryRequest({
                    QueryName: "entityinfo",
                    SearchKeyValues: [
                        `context_id=${context.ID}`,
                        `context_type=${context.KnownType}`,
                        `entity=messages`,
                        `count=true`,
                    ]
                });

                hub.loadMessages({
                    SearchKeyValues: [
                        `context_id=${context.ID}`,
                        `context_type=${context.KnownType}`,
                        `last_msg_id=${firstMsg.ID}`
                    ]
                });
            }
        }
    }, [firstItemIndex, messages, setMessages]);

    const onQuote = (message: ChatItemViewModel) => {
        props.quoteMessage(message);
    }

    const onEdit = (message: ChatItemViewModel) => {
        props.editMessage(message);
    }

    const onShowJSON = (message: ChatItemViewModel) => {
        const json = JSON.stringify(message);
        console.log(json);
        alert(json);
    }

    const onGetUserContext = (id: string): ContextItemViewModel => {
        try {
            let context = grpContext.find(x => {
                if (x.ID === id) {
                    return true;
                }
            });
            if (context) {
                return context;
            }
        } catch (error) {
            logError(error);
        }
        return {
            ID: id,
            ChildContexts: [],
            Domain: "",
            HomeServer: "",
            IsPinned: false,
            KnownType: "",
            OrderBy: 0,
            State: Active.Active,
            Title: id,
            UnreadMessages: 0,
            IsHidden: false,
            OrderChanged: 0,
        };
    }

    const onGetMsgHeader = (msg: ChatItemViewModel): any => {
        if (msg.IsCurrentUser === false && msg.Sender) {
            let msg_sender = onGetUserContext(msg.Sender.ID);
            return (
                <div>
                    <Avatar className='chat-item-header-other-user-avatar' src={!isEmptyOrSpaces(msg_sender.Thumbnail) ? getImageUrl(msg_sender.Thumbnail) : ''}
                        style={{ backgroundColor: !isEmptyOrSpaces(msg_sender.Color) ? msg_sender.Color : "orange", verticalAlign: 'middle' }}
                        size="small">
                        {
                            !isEmptyOrSpaces(msg_sender.ShorthandSymbol) ? msg_sender.ShorthandSymbol : msg_sender.Title.substring(0, 2)
                        }
                    </Avatar>
                    <span className='chat-text-user'>{!isEmptyOrSpaces(msg_sender.Title) ? msg_sender.Title : (msg.Sender?.Name ?? msg.Sender?.ID)}</span>
                </div>
            )
        } else {
            return '';
        }
    }

    const onGetMsgSendState = (msg: ChatItemViewModel): any => {
        if (msg.IsCurrentUser === true) {
            let state = onGetSendState(msg.ID);
            if (state) {
                return (
                    <div className={'chat-msg-send-state chat-msg-send-state-others'}>
                        <div className='chat-msg-send-state-items'>
                            {
                                state.IsSending === true ?
                                    (
                                        <LoadingOutlined spin style={{ fontSize: '16px', color: '#0f5e20' }} />
                                    ) :
                                    (state.TransferOK === true ?
                                        (
                                            <Tooltip title="Erfolgreich übertragen">
                                                <CheckCircleFilled style={{ fontSize: '16px', color: 'green' }} />
                                            </Tooltip>
                                        ) :
                                        (
                                            <Tooltip title={state.TransferMessage ?? "Fehler beim senden"}>
                                                <WarningFilled style={{ fontSize: '16px', color: 'red' }} />
                                            </Tooltip>
                                        ))
                            }

                        </div>
                    </div>
                );
            } else {
                return (<div className='chat-msg-send-state'></div>);
            }
        } else {
            return '';
        }
    }

    return (
        <div className='messages-container'>
            <Virtuoso
                className='virtuoso-list'
                ref={virtuosoRef}
                firstItemIndex={firstItemIndex}
                initialTopMostItemIndex={initialIndex - 1}
                data={messages}
                startReached={prependItems}
                atBottomStateChange={(bottom) => {
                    setAtBottom(bottom);
                }}
                itemContent={(index, msg) => {
                    return (
                        <div className={msg.IsCurrentUser ? (index === (initialIndex - 1) ? 'chat-msg chat-msg-current-user chat-msg-current-user-last' : 'chat-msg chat-msg-current-user') : 'chat-msg'}>
                            <div className='chat-msg-header'>
                                {
                                    onGetMsgHeader(msg)
                                }
                                {
                                    onGetMsgSendState(msg)
                                }
                                <span className={msg.IsCurrentUser ? 'chat-text-date chat-text-date-current-user' : 'chat-text-date chat-text-date-other-user'}>{dateFormatToday(msg.Time)}</span>
                                {
                                    !isEmptyOrSpaces(msg.EditClientId) ?
                                        (
                                            <div className='chat-text-date chat-text-edit-info'>
                                                (
                                                <EditOutlined />
                                                {msg.Edit ? dateFormatToday(msg.Edit) : ''}
                                                )
                                            </div>
                                        ) : ''
                                }
                            </div>
                            <div className='chat-msg-wrapper'>
                                {
                                    msg.Type === MessageType.Image ?
                                        (
                                            <MessageContentImage model={msg} />
                                        ) :
                                        (
                                            <MessageContentText model={msg} />
                                        )
                                }
                            </div>
                            <div className={msg.IsCurrentUser ? 'chat-msg-actions chat-msg-actions-right' : 'chat-msg-actions chat-msg-actions-left'}>
                                <Dropdown trigger={['click']} overlay={<Menu>
                                    {
                                        msg.Type === MessageType.Text ?
                                            (
                                                <Menu.Item key={"quoteMenu"} icon={<QuoteIcon />}>
                                                    <div onClick={() => onQuote(msg)}>
                                                        Zitieren
                                                    </div>
                                                </Menu.Item>
                                            ) : ''
                                    }
                                    {
                                        msg.IsCurrentUser && msg.Type === MessageType.Text ?
                                            (
                                                <Menu.Item key={"editMenu"} icon={<EditOutlined />}>
                                                    <div onClick={() => onEdit(msg)}>
                                                        Bearbeiten
                                                    </div>
                                                </Menu.Item>
                                            ) : ''
                                    }

                                    {
                                        /*<Menu.Item key={"jsonMenu"} icon={<CodeOutlined />}>
                                            <div onClick={() => onShowJSON(msg)}>
                                                JSON Daten Anzeigen
                                            </div>
                                        </Menu.Item>*/
                                    }
                                </Menu>}>
                                    <a onClick={e => e.preventDefault()}>
                                        <MoreOutlined />
                                    </a>
                                </Dropdown>
                            </div>
                        </div>
                    )
                }}
                followOutput={'smooth'}
            />
            {showButton && (
                <div className='chat-scroll-down-last-message'
                    onClick={() => {
                        if (virtuosoRef.current) {
                            virtuosoRef.current.scrollToIndex({ index: messages.length - 1, behavior: 'smooth' });
                        }
                    }}
                    style={{ float: 'right', transform: 'translate(-1rem, -2rem)' }}
                >
                    <DownOutlined />
                    Zur letzten Nachricht
                </div>
            )}
        </div>
    )
}