import { Collapse } from 'antd';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { logError } from '../Services/Helpers';
import { useHub } from '../Services/HubProxy';

import './Redirect.less'

export default function Redirect() {
    try {
        window.location.assign("/");    
    } catch (error) {
        logError(error);
        window.location.href = '/';
    }    

    return (
        <div className='redirect-wrapper'>
            <h3>Seite wird geladen</h3>
        </div>
    );
};