export class ContextUserListSearch {
    AfterLogin: boolean = false;
}

export enum Active {
    None,
    Active,
    AFK,
    DND,
    Offline,
    ActiveSlow,
    ActiveVerySlow,
    ActiveBackground,
    ConnectionError
}

export class ContextItemViewModel {
    ID!: string;
    Title!: string;
    Subtitle?: string;
    HomeServer!: string;
    KnownType!: string;
    Domain!: string;
    State: Active = Active.None;
    UnreadMessages: number = 0;
    IsPinned: boolean = false;
    ChildContexts: ContextItemViewModel[] = [];
    ContextGroup?: string;
    OrderBy: number = 0;
    OrderChanged: number = 0;
    ShorthandSymbol?: string;
    Thumbnail?: string;
    Color?: string;
    IsHidden: boolean = false;
}

export class ClientState {
    ContextId!: string;
    Timestamp!: Date;
    UserSelectedState?: string = undefined;
    State!: string;
}

export class ContextItemViewModelSelect extends ContextItemViewModel {
    Selected: boolean = false;
}

export class ContextRelatedModel {
    Id?: string;
    ContextId?: string;
    RelatedContextId!: string;
    IsForeign?: boolean = false;
    // use a flag for permissions but save it as int
    Permissions?: number = 99;
}

export class ContextModel {
    ID!: string;
    Title!: string;
    Subtitle?: string;
    Status?: string;
    HomeServer!: string;
    KnownType!: string;
    Domain!: string;
    ShorthandSymbol?: string;
    Thumbnail?: string;
    Color?: string;
    RelatedContexts: ContextRelatedModel[] = [];
    RemovedContextIds: string[] = [];
}