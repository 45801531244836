import React, { useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import Redirect from '../Info/Redirect';
import { isEmptyOrSpaces } from '../Services/Helpers';
import { useHub } from '../Services/HubProxy';
import { useLocalStorage } from '../Services/LocalStorage';

export default function RequiredAuth(props: { children: JSX.Element; validLogin: boolean; }) {
    const location = useLocation();
    let navigate = useNavigate();
    const hub = useHub();
    const storage = useLocalStorage();

    if (props.validLogin === false) {
        let token = storage.getToken();
        if (!isEmptyOrSpaces(token)) {
            hub.reconnect();
        } else {
            return (
                <Redirect />
            );
        }
    }

    return (
        props.children
    );
};