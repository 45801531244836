import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid';
import type { RootState } from './Store'

interface ClientState {
    deviceId: string,
    token?: string,
    clientId: string,
    homeUrl?: string,
}

const initialState: ClientState = {
    deviceId: uuidv4(),
    clientId: uuidv4(),
}

export const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload
    },
    setClientId: (state, action: PayloadAction<string>) => {
        state.clientId = action.payload
      },
  },
})

export const { setToken, setClientId } = clientSlice.actions

export const selectClientId = (state: RootState) => state.client.clientId
export const selectDeviceId = (state: RootState) => state.client.deviceId
export const selectHomeUrl = (state: RootState) => state.client.homeUrl
export const selectToken = (state: RootState) => state.client.token

export default clientSlice.reducer