import React, { useState } from 'react';
import { Image } from 'antd';

import './MessageContentImage.less'
import { ChatItemViewModel } from './Models';
import { secondIndex } from '../Services/Helpers';

export default function MessageContentImage(props: { model: ChatItemViewModel; }) {
    const [base64, setBase64] = useState("");
    const [fileName, setFileName] = useState("");

    React.useEffect(() => {
        if (props.model.RawContent) {
            if (props.model.RawContent.startsWith('|')) {
                const index = secondIndex(props.model.RawContent, '|');
                const file = props.model.RawContent.substring(1, index);
                const base = props.model.RawContent.substring(index + 1);
                setFileName(file);
                setBase64(base);
            } else {
                setBase64(props.model.RawContent);
            }
        }
    }, [props.model]);

    return (
        <div className='chat-image'>
            <Image src={base64} />
        </div>
    );
};

