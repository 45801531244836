import React, { useState } from 'react';
import { Form, Input, Modal } from 'antd';

import { getDigest, isEmptyOrSpaces, logError } from '../Services/Helpers';
import { useHub } from '../Services/HubProxy';

export default function EditUserPassword(props: { show: boolean; closeCallback: () => void; userName: string | undefined; }) {
    const hub = useHub();
    const [showAddUser, setShowAddUser] = useState(props.show);
    const [userName, setUserName] = useState(props.userName);

    const [confirmLoading, setConfirmLoading] = useState(false);
    const [allowedSafe, setAllowedSafe] = useState(false);
    const [pwRepeatValid, setPWRepeatValid] = useState(false);

    const [form] = Form.useForm();

    React.useEffect(() => {
        setShowAddUser(props.show);
        try {
            form.resetFields();
        } catch (error) {
            logError(error);
        }
    }, [props.show]);

    React.useEffect(() => {
        setUserName(props.userName);
    }, [props.userName]);

    const handleOk = async () => {
        try {
            setConfirmLoading(true);
            let pw = form.getFieldValue("password");
            if (!isEmptyOrSpaces(userName) && !isEmptyOrSpaces(pw) && userName) {
                const id = userName.replace("@sol-it", "");
                hub.localUserSaveRequest(id, await getDigest(pw));
                setTimeout(() => {
                    setShowAddUser(false);
                    setConfirmLoading(false);
                }, 100);
            }
        } catch (error) {
            logError(error);
            setConfirmLoading(false);
        }
    };

    const onAfterClose = () => {
        try {

        } catch (error) {
            logError(error);
        }
        props.closeCallback();
    }

    const onFormValuesChange = (changedValues: any, values: any) => {
        try {
            if (!isEmptyOrSpaces(values.password)
                && !isEmptyOrSpaces(values.password_match)) {
                form.validateFields().then(value => {
                    //console.log("Success", value);
                }).catch(info => {
                    //console.log("Failed", info);
                    if (info.errorFields.length === 0) {
                        if (values.password !== values.password_match) {
                            setPWRepeatValid(false);
                            setAllowedSafe(false);
                        } else {
                            setPWRepeatValid(true);
                            setAllowedSafe(true);
                        }

                    }
                });

            }
        } catch (error) {
            logError(error);
        }
    }

    return (
        <Modal
            forceRender
            className='user-add-container'
            title="Passwort ändern"
            centered
            visible={showAddUser}
            onOk={handleOk}
            onCancel={() => setShowAddUser(false)}
            afterClose={() => onAfterClose()}
            confirmLoading={confirmLoading}
            okText="Speichern"
            cancelText="Abbrechen"
            destroyOnClose={true}
            okButtonProps={{ disabled: !allowedSafe }}
        >
            <div className='user-add-wrapper'>
                <Form
                    form={form}
                    onValuesChange={onFormValuesChange}
                    name="basic"
                    layout='vertical'
                    initialValues={{ remember: false }}
                    autoComplete="off"
                >
                    <Form.Item
                    id="password"
                        label="Passwort"
                        name="password"
                        rules={[
                            { required: true, message: 'Passwort wird benötigt!' },
                            { min: 8, message: 'Passwort muss mindestens 8 Zeichen lang sein!' },
                            { whitespace: true, message: 'Benutzer name kann nicht aus Leerzeichen bestehen!' }
                        ]}
                    >
                        <Input.Password autoFocus />
                    </Form.Item>

                    <Form.Item
                    id="password_match"
                        validateStatus={!pwRepeatValid ? 'error' : 'success'}
                        help={!pwRepeatValid ? "Passwort muss übereinstimmen!" : ""}
                        label="Passwort wiederholen"
                        name="password_match"
                        rules={[{ required: true, message: 'Passwort muss übereinstimmen!' }]}
                    >
                        <Input.Password />
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
};