var currentCallKey;
var currentContextIDs;

var eventOfferCallback;

export function registerOfferEventCallback(callback) {
    eventOfferCallback = callback;
}

var eventLeaveCallback;

export function registerLeaveEventCallback(callback) {
    eventLeaveCallback = callback;
}

var eventAnswerCallback;

export function registerAnswerEventCallback(callback) {
    eventAnswerCallback = callback;
}

var startOfferCallback;

export function registerStartOfferCallback(callback) {
    startOfferCallback = callback;
}


export function incomingMessage(callerModel) {
    // TODO: should support waiting/switch
    // if we are already in a call drop other incoming offers
    if (currentCallKey) {
        if (currentCallKey !== callerModel.CallKey) {
            console.warn(`Received 'IncomingMessage' from a different context then the actual callee. Context:${JSON.stringify(callerModel.Context)}`);
            console.log("Dropped => Incoming WebRTC message:", callerModel.Data);
            // TODO: should return a busy anwser
            return;
        }
    }

    console.log("Incoming WebRTC message:", callerModel.Data);
    var data = JSON.parse(callerModel.Data);

    switch (data.type) {
        //when somebody wants to call us 
        case "offer":
            //handleOffer(data.offer, data.name);
            if (eventOfferCallback) {
                eventOfferCallback(data.offer, callerModel.ContextIDs, callerModel.CallKey)
            }
            break;
        case "answer":
            if (eventAnswerCallback) {
                eventAnswerCallback(data.offer, callerModel.ContextIDs, callerModel.CallKey)
            }
            handleAnswer(data.answer);
            break;
        //when a remote peer sends an ice candidate to us 
        case "candidate":
            handleCandidate(data.candidate);
            break;
        case "leave":
            if (eventLeaveCallback) {
                eventLeaveCallback(data.offer, callerModel.ContextIDs, callerModel.CallKey)
            }
            handleLeave();
            break;
        default:
            break;
    }
};

var sendCallback;

export function hubSendCallback(callback) {
    sendCallback = callback;
}

function send(message) {
    let model = {
        CallKey: currentCallKey,
        ContextIDs: currentContextIDs,
        Data: JSON.stringify(message)
    };
    sendCallback(model);
};

var remoteAudioSourceCallback;
var localAudioSourceCallback;

export function registerRemoteAudioCallback(callback) {
    remoteAudioSourceCallback = callback;
}

export function registerLocalAudioCallback(callback) {
    localAudioSourceCallback = callback;
}

var yourConn;

export async function initWebRTC() {
    var stream = await navigator.mediaDevices.getUserMedia({ video: false, audio: true });

    // using Google public stun server 
    var configuration = {
        "iceServers": [{ "url": "stun:stun2.1.google.com:19302" }]
    };

    yourConn = new RTCPeerConnection(configuration);

    // setup stream listening 
    yourConn.addStream(stream);

    //when a remote user adds stream to the peer connection, we display it 
    yourConn.onaddstream = function (e) {
        // add local stream when a remote is added
        localAudioSourceCallback(stream);

        // remote stream
        console.log('Remote audio stream');
        remoteAudioSourceCallback(e.stream);
    };

    // Setup ice handling 
    yourConn.onicecandidate = function (event) {
        if (event.candidate) {
            send({
                type: "candidate",
                candidate: event.candidate
            });
        }
    };
}

export function offer(callKey, callerContextIDs) {
    yourConn.createOffer(function (offer) {
        console.log(`Create caller offer for Context:${JSON.stringify(callerContextIDs)}`);
        currentCallKey = callKey;
        currentContextIDs = callerContextIDs;
        send({
            type: "offer",
            offer: offer
        });

        yourConn.setLocalDescription(offer);
        startOfferCallback({
            type: "offer",
            offer: offer
        }, callerContextIDs, callKey)
    }, function (error) {
        alert("Error when creating an offer");
    });
}

export function acceptOffer(callerModel) {
    handleOffer(callerModel);
}

function handleOffer(callerModel) {
    // TODO: should support waiting/switch
    // if we are already in a call drop other incoming offers
    if (currentCallKey) {
        if (currentCallKey !== callerModel.CallKey) {
            console.warn(`Received offer for call from a different context then the actual callee. Context:${JSON.stringify(callerModel.Context)}`);
            // TODO: should return a busy anwser
            return;
        }
    }

    yourConn.setRemoteDescription(new RTCSessionDescription(callerModel.Data));

    //create an answer to an offer 
    yourConn.createAnswer(function (answer) {
        yourConn.setLocalDescription(answer);

        if (!currentContextIDs) {
            currentContextIDs = callerModel.ContextIDs;
        }
        if (!currentCallKey) {
            currentCallKey = callerModel.CallKey;
        }

        send({
            type: "answer",
            answer: answer
        });
    }, function (error) {
        alert("Error when creating an answer");
    });

};

//when we got an answer from a remote user 
function handleAnswer(answer) {
    yourConn.setRemoteDescription(new RTCSessionDescription(answer));
};

//when we got an ice candidate from a remote user 
function handleCandidate(candidate) {
    yourConn.addIceCandidate(new RTCIceCandidate(candidate));
};


export async function hangup() {
    send({
        type: "leave"
    });

    await handleLeave();
}

async function handleLeave() {
    //connectedUser = null;
    //remoteAudio.src = null; 
    currentCallKey = null;
    currentContextIDs = null;

    yourConn.close();
    yourConn.onicecandidate = null;
    yourConn.onaddstream = null;

    await initWebRTC();
};