import React, { useState } from 'react';
import { Tabs } from 'antd';

import './AdminView.less'
import SessionView from './SessionView';
import MessageContextStatisticsView from './MessageContextStatisticsView';
import ContextManagmentView from './ContextManagmentView';
import UserManagmentView from './UserManagmentView';
import PremissionManagmentView from './PremissionManagmentView';

const { TabPane } = Tabs;

export default function AdminView() {

    const callback = (key: any) => {
        //console.log(key);
    }

    return (
        <div className='admin-wrapper'>
            <h3>Administration</h3>
            <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab="Kontext Nachrichten Statistik" key="1">
                    <MessageContextStatisticsView/>
                </TabPane>
                <TabPane tab="Kontext Verwaltung" key="2">
                    <ContextManagmentView/>
                </TabPane>
                <TabPane tab="Lokale Benutzer" key="3">
                    <UserManagmentView/>
                </TabPane>
                <TabPane tab="Berechtigungen" key="4">
                    <PremissionManagmentView/>
                </TabPane>
                <TabPane tab="Session Verwaltung" key="5">
                    <SessionView/>
                </TabPane>
            </Tabs>
        </div>
    );
};