import React, { FocusEventHandler, useState, useMemo, useCallback } from 'react';

import { v4 as uuidv4 } from 'uuid';

import { Button, Spin, Popover, Upload, Image } from 'antd';
import { LoadingOutlined, SendOutlined, SmileOutlined, UploadOutlined, CloseOutlined } from '@ant-design/icons';

import './UserInput.less'
import { dateFormatToday, getBase64, isEmptyOrSpaces, logError, secondIndex } from '../Services/Helpers';
import { useLocalStorage } from '../Services/LocalStorage';
import { ContextItemViewModel } from '../Context/Models';
import { useHub } from '../Services/HubProxy';
import { ChatItemSendState, ChatItemViewModel, MessageType } from './Models';
import Picker from 'emoji-picker-react';

import { createEditor, Descendant, Transforms, Node, Point, Text, BaseEditor } from 'slate'
import { Slate, Editable, withReact, ReactEditor } from 'slate-react'
import { withHistory, HistoryEditor } from 'slate-history'
import { deserialize, Element, Leaf, serialize } from '../Shared/slate-serialize';
import { db } from '../Services/DB';


type CustomElement = { type: 'paragraph' | 'block-quote'; children: CustomText[] }
type CustomText = { text: string; bold?: true }

declare module 'slate' {
    interface CustomTypes {
        Editor: BaseEditor & ReactEditor & HistoryEditor
        Element: CustomElement
        Text: CustomText
    }
}

export default function UserInput(props: { model: ContextItemViewModel | undefined; quoteMessage: ChatItemViewModel | undefined; editMessage: ChatItemViewModel | undefined; }) {
    const storage = useLocalStorage();
    const hub = useHub();
    const [context, setContext] = useState(props.model);
    const [loading, setLoading] = useState(false);
    const [currentEditMessage, setCurrentEditMessage] = useState<ChatItemViewModel | undefined>(undefined);
    const [currentQuoteMessage, setCurrentQuoteMessage] = useState<ChatItemViewModel | undefined>(undefined);
    const [currentQuotedUser, setCurrentQuotedUser] = useState<string | undefined>(undefined);
    const [currentFileMessage, setCurrentFileMessage] = useState<ChatItemViewModel | undefined>(undefined);
    const [upMsgIndex, setUpMsgIndex] = useState(0);

    const withFile = (editor: BaseEditor & ReactEditor & HistoryEditor) => {
        try {
            editor.insertData = data => {
                try {
                    if (data.files.length > 0) {
                        const file: File = data.files[0] as File;
                        if (file) {
                            var reader = new FileReader();
                            reader.onload = function (event) {
                                if (event.target && event.target.result) {
                                    onPrepareFileSend(event.target.result.toString(), file.name, file.size);
                                }
                            };
                            reader.readAsDataURL(file);
                        }
                        return;
                    } else if (data.types.includes("text/html")) {
                        const html = data.getData("text/html");
                        const parsed = new DOMParser().parseFromString(html, 'text/html');
                        const fragment = deserialize(parsed.body);
                        Transforms.insertFragment(editor, fragment);
                        return;
                    } else if (data.types.includes("text/plain")) {
                        const text = data.getData("text/plain");
                        Transforms.insertText(editor, text);
                        return;
                    }
                } catch (error) {
                    logError(error);
                }
            }
        } catch (error) {
            logError(error);
        }
        return editor;
    }

    const editor1 = useMemo(() => withFile(withHistory(withReact(createEditor()))), [])
    const renderElement = useCallback(props => <Element {...props} />, [])
    const renderLeaf = useCallback(props => <Leaf {...props} />, [])

    React.useEffect(() => {
        if (props.model !== undefined) {
            try {
                setContext(props.model);
                if (currentEditMessage !== undefined) {
                    setCurrentEditMessage(undefined);
                }
            } catch (error) {
                logError(error);
            }
        }
    }, [props.model]);

    React.useEffect(() => {
        if (props.quoteMessage !== undefined) {
            try {
                setCurrentQuotedUser(undefined);
                setCurrentQuoteMessage(props.quoteMessage);
                if (props.quoteMessage) {
                    console.log(props.quoteMessage);
                    onGetUserContextName(props.quoteMessage).then(title => {
                        setCurrentQuotedUser(title);
                    });
                }
            } catch (error) {
                logError(error);
            }
        }
    }, [props.quoteMessage]);

    const onSetEdit = (msg: ChatItemViewModel, keepEditIndex: boolean) => {
        try {
            setCurrentEditMessage(msg);
            const document = new DOMParser().parseFromString(msg.RawContent, 'text/html');
            const slateElement = deserialize(document.body);

            if (slateElement !== null) {
                clearEditor(false, keepEditIndex);
                editor1.insertNode(slateElement);
            }
        } catch (error) {
            logError(error);
        }
    }

    React.useEffect(() => {
        if (props.editMessage !== undefined) {
            try {
                const msg = props.editMessage;
                onSetEdit(msg, false);
            } catch (error) {
                logError(error);
            }
        }
    }, [props.editMessage]);

    const clearEditor = (setEmpty: boolean, keepEditIndex: boolean) => {
        try {
            editor1.children.forEach((node) => Transforms.removeNodes(editor1));
            Transforms.removeNodes(editor1);
            editor1.children = [];
            if (setEmpty) {
                Transforms.insertNodes(editor1, [
                    {
                        type: 'paragraph',
                        children: [
                            { text: '' },
                        ],
                    },
                ]);
            }
        } catch (error) {
            logError(error);
        }
        if (keepEditIndex === false) {
            setUpMsgIndex(0);
        }
    }

    const hasShiftModifier = (e: any) => {
        return e.shiftKey === true;
    }

    const onSendClick = () => {
        if (context) {
            const html = serialize(editor1);
            if (html === "" || html === "<p><br></p>") {
                return;
            }

            try {
                setLoading(true);

                let richData: string | undefined = undefined;
                try {
                    var urls = urlify(html);
                    if (urls.length > 0) {
                        richData = "";
                        for (let index = 0; index < urls.length; index++) {
                            const element = urls[index];
                            richData += element;
                            if (index + 1 < urls.length) {
                                richData += "|@|";
                            }
                        }
                    }
                } catch (error) {
                    logError(error);
                }
                if (currentEditMessage !== undefined) {
                    const prevRaw = currentEditMessage.RawContent;

                    currentEditMessage.RawContent = html;
                    currentEditMessage.Edit = new Date();
                    currentEditMessage.EditPreviousRawContext = prevRaw;
                    currentEditMessage.EditClientId = storage.getClientId();
                    currentEditMessage.RichData = richData;

                    hub.sendRequest(currentEditMessage);
                } else if (currentFileMessage !== undefined) {
                    currentFileMessage.ContextID = context.ID;
                    currentFileMessage.Time = new Date();
                    currentFileMessage.RichData = richData;

                    hub.sendRequest(currentFileMessage);
                } else {
                    let rawContent = html;
                    if (currentQuoteMessage !== undefined) {
                        rawContent = currentQuoteMessage.RawContent + `<p class='quote-mark-paragraph'>—${currentQuotedUser} ${dateFormatToday(currentQuoteMessage.Time)}—</p>` + html;
                    }
                    let msg: ChatItemViewModel = {
                        ID: `${uuidv4()}|@|${storage.getClientId()}`,
                        ContextID: context.ID,
                        IsCurrentUser: true,
                        RawContent: rawContent,
                        ShowHeaderInfo: false,
                        Time: new Date(),
                        Type: MessageType.Text,
                        RichData: richData,
                    };
                    hub.sendRequest(msg);
                }

                clearEditor(true, false);
                setCurrentEditMessage(undefined);
                setCurrentQuoteMessage(undefined);
                setCurrentQuotedUser(undefined);
                setCurrentFileMessage(undefined);

                setLoading(false);
            } catch (error) {
                logError(error);
                setLoading(false);
            }
        }
    }

    const onGetUserContextName = async (msg: ChatItemViewModel): Promise<string> => {
        try {
            if (msg.Sender?.Name) {
                return msg.Sender.Name;
            } else {
                // try resolve user from db
                let context = await db.contexts
                .filter(con => con.ID === msg.Sender?.ID)
                .limit(1)
                .toArray();
                if (context && context.length > 0) {
                    return context[0].Title;
                }
                // check agains own user
                let user = storage.getUser();
                if (user) {
                    if (msg.Sender?.ID === user.ID) {
                        return user?.Name;
                    }
                }
                // fallback to ID
                if (msg.Sender?.ID) {
                    return msg.Sender?.ID;
                }
            }
        } catch (error) {
            logError(error);
        }
        return "";
    }

    const urlify = (text: string): string[] => {
        const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
        var matches = text.match(urlRegex);
        if (matches && matches !== null) {
            let result: string[] = [];
            matches.forEach(match => {
                if (!result.includes(match)) {
                    result.push(match);
                }
            });
            return result;
        }
        return [];
    }

    const onPrepareFileSend = (base64: string, fileName: string, fileSize: number) => {
        try {
            clearEditor(true, false);
            setCurrentEditMessage(undefined);
            setCurrentQuoteMessage(undefined);
            setCurrentQuotedUser(undefined);
            setCurrentFileMessage(undefined);

            let dataKey = base64.substring(0, base64.indexOf(';'));
            let itemKeys = dataKey.split('/');
            let fileExtension = itemKeys[itemKeys.length - 1];
            let normfileName: string | undefined = fileName;
            if (!normfileName || isEmptyOrSpaces(normfileName)) {
                normfileName = uuidv4() + "." + fileExtension;
            }
            let rawContent: string = `|${normfileName}|` + base64;
            let msg: ChatItemViewModel = {
                ID: `${uuidv4()}|@|${storage.getClientId()}`,
                ContextID: "",
                IsCurrentUser: true,
                RawContent: rawContent,
                ShowHeaderInfo: false,
                Time: new Date(),
                Type: MessageType.Image,
            };
            setCurrentFileMessage(msg);
        } catch (error) {
            logError(error);
        }
    }

    const onEmojiClick = (event: any, emojiObject: any) => {
        try {
            editor1.insertFragment([{
                type: 'paragraph',
                children: [{ text: emojiObject.emoji }]
            }]);
        } catch (error) {
            logError(error);
        }
    };

    const beforeUpload = (file: any): boolean => {
        try {
            getBase64(file, (imageUrl: any) => {
                onPrepareFileSend(imageUrl, file.name, file.size);
            });
        } catch (error) {
            logError(error);
        }
        return false;
    }

    const handleChange = (info: any) => {
        return;
    };

    const initialValue: Descendant[] = [
        {
            type: 'paragraph',
            children: [
                { text: '' },
            ],
        },
    ]

    const editMessage = async (up: boolean) => {
        if (context) {
            try {
                let useOffset = upMsgIndex + 1;
                if (up === false && upMsgIndex > 1) {
                    useOffset = upMsgIndex - 1;
                }
                let msgs = await db.messages
                    .orderBy('Time')
                    .reverse()
                    .filter(msg => msg.ContextID === context.ID && msg.IsCurrentUser === true && msg.Type === MessageType.Text)
                    .offset(useOffset - 1)
                    .limit(1)
                    .toArray();
                if (msgs && msgs.length > 0) {
                    const msg = msgs[0];
                    let next = useOffset;
                    setUpMsgIndex(next);
                    // set as current edit
                    onSetEdit(msg, true);
                } else {
                    setUpMsgIndex(0);
                }
            } catch (error) {
                logError(error);
            }
        }
    }

    const onEditorKeyDown = (event: any) => {
        if (event.keyCode === 13 && !hasShiftModifier(event)) {
            // enter key and not shift modifier
            onSendClick();
            event.preventDefault();
        } else if (event.keyCode === 38 && !hasShiftModifier(event)) {
            // arrow key up
            editMessage(true);
        } else if (event.keyCode === 40 && !hasShiftModifier(event)) {
            // arrow key down
            editMessage(false);
        }
    }

    const onCancelMsgActionClick = () => {
        try {
            setCurrentEditMessage(undefined);
            setCurrentQuoteMessage(undefined);
            setCurrentQuotedUser(undefined);
            setCurrentFileMessage(undefined);
            clearEditor(true, false);
        } catch (error) {
            logError(error);
        }
    }

    const onGetPreview = (msg: ChatItemViewModel) => {
        try {
            if (msg.Type === MessageType.Image) {
                let base64 = undefined;
                if (msg.RawContent.startsWith('|')) {
                    const index = secondIndex(msg.RawContent, '|');
                    const file = msg.RawContent.substring(1, index);
                    base64 = msg.RawContent.substring(index + 1);
                } else {
                    base64 = msg.RawContent;
                }
                return (
                    <div className='chat-image-quote-preview'>
                        <Image src={base64} />
                    </div>
                );
            } else {
                return (
                    <div dangerouslySetInnerHTML={{ __html: msg.RawContent }}></div>
                );
            }
        } catch (error) {
            logError(error);
        }
        return '';
    }

    return (
        <div className="user-input-container">
            <Spin spinning={loading} indicator={<LoadingOutlined spin />} tip="Nachricht wird übertragen" style={{ width: "100%" }}>
                <div className='input-controls-wrapper'>
                    <div id="input-editor-parent" className='input-editor-parent'>
                        <div className='input-editor-msg-info'>
                            {
                                currentQuoteMessage !== undefined ?
                                    (
                                        <div className="ant-input chat-text-content">
                                            {onGetPreview(currentQuoteMessage)}
                                            <p className='quote-mark-paragraph'>—{currentQuotedUser ?? currentQuoteMessage.Sender?.ID} {dateFormatToday(currentQuoteMessage.Time)}—</p>
                                        </div>
                                    ) : ''
                            }
                            {
                                currentFileMessage !== undefined ?
                                    (
                                        <>
                                            <div className="ant-input chat-text-content">
                                                {onGetPreview(currentFileMessage)}
                                            </div>
                                            <div className='input-editor-msg-cancel'>
                                                <Button onClick={onCancelMsgActionClick} type="primary" shape="circle" icon={<CloseOutlined />} size='small' />
                                            </div>
                                        </>
                                    ) : ''
                            }
                        </div>
                        {
                            currentFileMessage === undefined ?
                                (
                                    <>
                                        <Slate editor={editor1} value={initialValue}>
                                            <Editable placeholder="Nachricht eingeben..." onKeyDown={onEditorKeyDown}
                                                renderElement={renderElement}
                                                renderLeaf={renderLeaf}
                                                className="ant-input"
                                                spellCheck />
                                        </Slate>
                                        <div className='input-editor-msg-cancel'>
                                            {
                                                currentEditMessage !== undefined || currentQuoteMessage !== undefined ?
                                                    (
                                                        <Button onClick={onCancelMsgActionClick} type="primary" shape="circle" icon={<CloseOutlined />} size='small' />
                                                    ) : ''
                                            }
                                        </div>
                                    </>
                                ) : ''
                        }

                    </div>

                    <div className='input-action-parent'>
                        <Button style={{ margin: "12px" }} onClick={onSendClick} type="primary" shape="circle" icon={<SendOutlined />} size='large' />

                        <Popover overlayClassName='input-help-popover' className='input-help-popover' trigger="click" content={
                            <Picker onEmojiClick={onEmojiClick} native={true}
                                groupNames={{
                                    smileys_people: 'Smiley & Personen',
                                    animals_nature: 'Tiere',
                                    food_drink: 'Essen & Trinken',
                                    travel_places: 'Plätze',
                                    activities: 'Aktivitäten',
                                    objects: 'Objekte',
                                    symbols: 'Symbole',
                                    flags: 'Flaggen',
                                    recently_used: 'Kürzlich Verwendet',
                                }}
                            />} title="Eingabe Hilfe">
                            <Button style={{ margin: "12px" }} type="primary" shape="circle" icon={<SmileOutlined />} size='large' />
                        </Popover>
                        <div className='input-file-picker-wrapper'>
                            <Upload
                                showUploadList={false}
                                beforeUpload={beforeUpload}
                                onChange={handleChange}
                                customRequest={() => { }}
                            >
                                <Button style={{ margin: "12px" }} type="primary" shape="circle" icon={<UploadOutlined />} size='large' />
                            </Upload>
                        </div>
                    </div>
                </div>
            </Spin>
        </div >
    )
};