import React, { useState } from 'react';
import { Select, Modal } from 'antd';

import { getBase64, getDigest, getImageUrl, isEmptyOrSpaces, logError } from '../Services/Helpers';
import { useHub } from '../Services/HubProxy';
import { LocalStorage } from '../Services/LocalStorage';
import { db } from '../Services/DB';
import { ContextItemViewModelSelect } from '../Context/Models';
import { PermissionManagmentViewItem, PermissionVisibleContextViewModel } from './Models';

const { Option } = Select;

export default function ChangePermissionRole(props: { permissions: PermissionManagmentViewItem[], show: boolean; closeCallback: () => void; }) {
    const hub = useHub();
    const [showPermissionRoleVisibility, setShowPermissionRoleVisibility] = useState(props.show);
    const [permissions, setPermissions] = useState(props.permissions);
    const [selectedRole, setSelectedRole] = useState(1);

    const [confirmLoading, setConfirmLoading] = useState(false);
    const [allowedSafe, setAllowedSafe] = useState(true);

    React.useEffect(() => {
        setShowPermissionRoleVisibility(props.show);
    }, [props.show]);

    React.useEffect(() => {
        setPermissions(props.permissions);
    }, [props.permissions]);

    const handleOk = async () => {
        try {
            setConfirmLoading(true);
            if (permissions.length > 0) {
                var ids = "";
                if (permissions.length > 0) {
                    for (let index = 0; index < permissions.length; index++) {
                        const element = permissions[index];
                        ids += element.Id;
                        if (index + 1 < permissions.length) {
                            ids += "|@|";
                        }
                    }
                }
                
                hub.permissionRoleSaveRequest(ids, selectedRole)
                setTimeout(() => {
                    setShowPermissionRoleVisibility(false);
                    setConfirmLoading(false);
                }, 100);
            } else {
                setConfirmLoading(false);    
            }
        } catch (error) {
            logError(error);
            setConfirmLoading(false);
        }
    };

    const onAfterClose = () => {
        try {

        } catch (error) {
            logError(error);
        }
        props.closeCallback();
    }

    const onRoleValueChanged = (value: string) => {
        try {
            setSelectedRole(+value);
        } catch (error) {
            logError(error);
        }
    }

    return (
        <Modal
            className='context-visibility-edit-container'
            title="Neue Berechtigungs Rolle festlegen"
            centered
            visible={showPermissionRoleVisibility}
            onOk={handleOk}
            onCancel={() => setShowPermissionRoleVisibility(false)}
            afterClose={() => onAfterClose()}
            confirmLoading={confirmLoading}
            okText="Speichern"
            cancelText="Abbrechen"
            destroyOnClose={true}
            okButtonProps={{ disabled: !allowedSafe }}
        >
            <div className='permission-role-edit-wrapper'>
                <Select defaultValue="1" onChange={onRoleValueChanged} style={{ width: '100%' }}>
                    <Option value="0">Extern (keine erstellung von Kontexten)</Option>
                    <Option value="1">User (normale verwendung)</Option>
                    <Option value="2">Moderator (bearbeiten von allen Kontexten und Nachrichten)</Option>
                    <Option value="4">Administrator</Option>
                </Select>
                <p>Neue Rolle für {permissions.length} Benutzer festlegen.</p>
            </div>
        </Modal>
    );
};