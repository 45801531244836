import React, { useState } from 'react';
import {
    Input, Modal, 
    Transfer
} from 'antd';

import { isEmptyOrSpaces, logError } from '../Services/Helpers';
import { useHub } from '../Services/HubProxy';
import { ContextItemViewModelSelect } from '../Context/Models';
import { PermissionVisibleContextViewModel } from './Models';

const { Search } = Input;

export default function EditContextVisibility(props: { contextId: string, show: boolean; closeCallback: () => void; }) {
    const hub = useHub();
    const [showEditContextVisibility, setShowEditContextVisibility] = useState(props.show);
    const [currentContextId, setCurrentContextId] = useState(props.contextId);
    const [clientContextList, setClientContextList] = useState<ContextItemViewModelSelect[]>([]);
    const [selectedClientContextList, setSelectedClientContextList] = useState<string[]>([]);

    const [confirmLoading, setConfirmLoading] = useState(false);
    const [allowedSafe, setAllowedSafe] = useState(true);

    React.useEffect(() => {
        setShowEditContextVisibility(props.show);
        setSelectedClientContextList([]);
        setClientContextList([]);
        hub.permissionVisibleContextLoadRequest(currentContextId);
    }, [props.show]);

    React.useEffect(() => {
        setCurrentContextId(props.contextId);
        hub.permissionVisibleContextLoadRequest(props.contextId);
    }, [props.contextId]);

    hub.subPermissionVisibleResult((data: PermissionVisibleContextViewModel) => {
        try {
            setClientContextList(data.Contexts as ContextItemViewModelSelect[]);
            if (data.Active) {
                if (!isEmptyOrSpaces(data.Active)) {
                    let selected = data.Active.split('|@|');
                    setSelectedClientContextList(selected);
                }
            }
        } catch (error) {
            logError(error);
        }
    });

    const handleOk = async () => {
        try {
            setConfirmLoading(true);
            if (!isEmptyOrSpaces(currentContextId)) {
                var visibleContextIds = "";
                if (selectedClientContextList.length > 0) {
                    for (let index = 0; index < selectedClientContextList.length; index++) {
                        const element = selectedClientContextList[index];
                        visibleContextIds += element;
                        if (index + 1 < selectedClientContextList.length) {
                            visibleContextIds += "|@|";
                        }
                    }
                }
                
                hub.permissionVisibleContextSaveRequest(currentContextId, visibleContextIds);
                setTimeout(() => {
                    setShowEditContextVisibility(false);
                    setConfirmLoading(false);
                }, 100);
            } else {
                setConfirmLoading(false);    
            }
        } catch (error) {
            logError(error);
            setConfirmLoading(false);
        }
    };

    const onAfterClose = () => {
        try {

        } catch (error) {
            logError(error);
        }
        props.closeCallback();
    }

    const filterOption = (inputValue: any, option: ContextItemViewModelSelect) => {
        return option.Title.toLowerCase().indexOf(inputValue) > -1;
    }


    const handleChange = (targetKeys: any) => {
        //this.setState({ targetKeys });
        setSelectedClientContextList(targetKeys);
    };

    const handleSearch = (dir: any, value: any) => {
        //console.log('search:', dir, value);
    };

    return (
        <Modal
            className='context-visibility-edit-container'
            title="Sichtbare Kontexte bearbeiten"
            centered
            visible={showEditContextVisibility}
            onOk={handleOk}
            onCancel={() => setShowEditContextVisibility(false)}
            afterClose={() => onAfterClose()}
            confirmLoading={confirmLoading}
            okText="Speichern"
            cancelText="Abbrechen"
            destroyOnClose={true}
            okButtonProps={{ disabled: !allowedSafe }}
        >
            <div className='context-visibility-edit-wrapper'>
                <Transfer
                titles={['Nicht Sichtbar', 'Sichtbar']}
                    rowKey={record => record.ID}
                    dataSource={clientContextList}
                    showSearch
                    filterOption={filterOption}
                    targetKeys={selectedClientContextList}
                    onChange={handleChange}
                    onSearch={handleSearch}
                    render={item => item.Title}
                    listStyle={{
                        width: 250,
                        height: 300,
                      }}
                />
                <p style={{ marginTop: '10px', textAlign: 'center' }}>Wenn die "Sichtbar" Liste leer ist (keine Einträge enthält), dann werden alle Kontexte angezeigt.</p>
            </div>
        </Modal>
    );
};