import { useState } from 'react';

import { Button, Row, Col, Divider, Slider, InputNumber, Switch, Space, Select } from 'antd';
import { ContainerOutlined, LogoutOutlined, SoundOutlined, ReloadOutlined, ToolOutlined, SettingOutlined, DownloadOutlined } from '@ant-design/icons';

import './Dashboard.less'
import { isLinux, isWindows, logError, playNotificationVolume, playRingingVolume } from '../Services/Helpers';
import { useLocalStorage } from '../Services/LocalStorage';
import { useNavigate } from 'react-router-dom';
import { useNative } from '../Shared/NativeWrapper';
import EditUserPassword from './EditUserPassword';
import ContextEdit from '../Context/ContextEdit';
import { ContextItemViewModel } from '../Context/Models';
import { useHub } from '../Services/HubProxy';
import { PermissionRoleRights } from './Model';

const { Option } = Select;

export default function Dashboard(props: { onLogout: () => void | undefined; }) {
    const hub = useHub();
    const storage = useLocalStorage();
    let navigate = useNavigate();
    const native = useNative();
    const [volumeInputValue, setVolumeInputValue] = useState(storage.getNotificationVolume() * 100);
    const [soundInputValue, setSoundInputValue] = useState(storage.getNotificationSound());
    const [ringingVolumeInputValue, setRingingVolumeInputValue] = useState(storage.getRingingVolume() * 100);
    const [ringingSoundInputValue, setRingingSoundInputValue] = useState(storage.getRingingSound());
    const [showNotification, setShowNotification] = useState(storage.getNativeNotificationMessage());
    const [currentUser, setCurrentUser] = useState(storage.getUser());
    const [showEditUserPW, setShowEditUserPW] = useState(false);
    const [context, setContext] = useState<ContextItemViewModel | undefined>();
    const [showEditContext, setShowEditContext] = useState(false);

    storage.subUserChange("dashboard", () => {
        try {
            setCurrentUser(storage.getUser());
        } catch (error) {
            logError(error);
        }
    });

    const onLogout = () => {
        try {
            if (props.onLogout) {
                props.onLogout();
            }
        } catch (error) {
            logError(error);
        }
    }

    const onChangeVolume = (value: number) => {
        try {
            setVolumeInputValue(value);
            storage.setNotificationVolume(value / 100);
        } catch (error) {
            logError(error);
        }
    }

    const onTestVolume = () => {
        try {
            if (volumeInputValue > 0) {
                playNotificationVolume(volumeInputValue / 100);
            }
        } catch (error) {
            logError(error);
        }
    }

    const onChangeShowNotification = (value: boolean) => {
        try {
            setShowNotification(value);
            storage.setNativeNotificationMessage(value);
        } catch (error) {
            logError(error);
        }
    }

    const onChangeLogClick = () => {
        navigate("/changelog");
    }

    const onReloadClick = () => {
        native.reload();
    }

    const onDevtoolsClick = () => {
        native.devtools();
    }

    const onAdmin = () => {
        navigate("/administration");
    }

    hub.subContextEntryUserManagmentResult((data) => {
        try {
            if (currentUser) {
                if (data.ID === currentUser?.ID) {
                    setContext(data);
                    setShowEditContext(true);
                }
            }
        } catch (error) {
            logError(error);
        }
    });

    const onChangeUserContext = () => {
        try {
            if (currentUser) {
                hub.queryContextManagmentEntry(currentUser?.ID);
            }
        } catch (error) {
            logError(error);
        }
    }

    const onChangeUserPassword = () => {
        setShowEditUserPW(true);
    }

    const onHandleSoundChange = (value: any) => {
        try {
            setSoundInputValue(value);
            storage.setNotificationSound(value);
        } catch (error) {
            logError(error);
        }
    }

    const onHandleRingingSoundChange = (value: any) => {
        try {
            setRingingSoundInputValue(value);
            storage.setRingingSound(value);
        } catch (error) {
            logError(error);
        }
    }

    const onRingingChangeVolume = (value: number) => {
        try {
            setRingingVolumeInputValue(value);
            storage.setRingingVolume(value / 100);
        } catch (error) {
            logError(error);
        }
    }

    const onRingingTestVolume = () => {
        try {
            if (ringingVolumeInputValue > 0) {
                playRingingVolume(ringingVolumeInputValue / 100);
            }
        } catch (error) {
            logError(error);
        }
    }

    return (
        <div className="dashboard-wrapper">
            <Row>
                <Col xs="24" sm="16" span="16" style={{ textAlignLast: "left" }}>
                    <img alt='Croco Logo' src="/logo_name.png" style={{ maxWidth: "250px" }} />
                </Col>
                <Col xs="24" sm="8" span="8" style={{ textAlignLast: "end", alignSelf: "end" }}>
                    <div>
                        <Button type="primary" icon={<LogoutOutlined />} onClick={onLogout}>
                            Abmelden
                        </Button>
                        {
                            (currentUser?.RoleRights === PermissionRoleRights.Administrator
                                || currentUser?.RoleRights === PermissionRoleRights.RootAdministrator) ?
                                (
                                    <Button style={{ marginLeft: "15px" }} shape="default" icon={<SettingOutlined />} onClick={onAdmin} />
                                ) : ''
                        }
                    </div>
                </Col>
            </Row>
            {
                isWindows() && !native.isDesktop() ?
                    (
                        <>
                            <Row style={{ marginBottom: '25px' }}>
                                <Col span="24">
                                    <Button size="large" type="primary" icon={<DownloadOutlined />} target="_blank" href={process.env.REACT_APP_WIN_INSTALLER_LINK}>
                                        App herunterladen
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    ) : ''
            }
            {
                isLinux() && !native.isDesktop() ?
                    (
                        <>
                            <Row style={{ marginBottom: '25px' }}>
                                <Col span="24">
                                    <Button size="large" type="primary" icon={<DownloadOutlined />} target="_blank" href={process.env.REACT_APP_LINUX_APPINFO_LINK}>
                                        App herunterladen
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    ) : ''
            }
            {
                currentUser?.IsLocalUser === true ?
                    (
                        <>
                            <Row>
                                <Col span="24">
                                    <h3 style={{ marginTop: "50px", paddingBottom: "0px", marginBottom: "0px" }}>Profil</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="24">
                                    <Space>
                                        <Button type="primary" onClick={onChangeUserContext}>
                                            Benutzer bearbeiten
                                        </Button>
                                        <Button type="primary" onClick={onChangeUserPassword}>
                                            Passwort ändern
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </>

                    ) : ''
            }
            <Row>
                <Col span="24">
                    <h3 style={{ marginTop: "50px", paddingBottom: "0px", marginBottom: "0px" }}>Einstellungen</h3>
                </Col>
            </Row>
            <Row>
                <Col span="24">
                    <Divider orientation="left" plain>
                        Benachrichtigung
                    </Divider>
                </Col>
            </Row>
            <Row>
                <Col xs="24" sm="8" style={{ textAlign: "left" }}>
                    <label>Lautstärke</label>
                </Col>
                <Col xs="8" sm="8">
                    <Slider
                        style={{ minWidth: '100px', marginLeft: '25px' }}
                        min={0}
                        max={100}
                        onChange={onChangeVolume}
                        value={typeof volumeInputValue === 'number' ? volumeInputValue : 50} />
                </Col>
                <Col xs="12" sm="4">
                    <InputNumber
                        min={0}
                        max={100}
                        style={{ margin: '0 16px' }}
                        value={volumeInputValue}
                        onChange={onChangeVolume}
                    />
                </Col>
                <Col xs="4" sm="4">
                    <Button type="primary" shape="circle" icon={<SoundOutlined />} onClick={onTestVolume} />
                </Col>
            </Row>
            <Row style={{ margin: '16px 0' }}>
                <Col xs="24" sm="8" style={{ textAlign: "left", marginRight: '20px' }}>
                    <label>Nachrichten Ton</label>
                </Col>
                <Col xs="24" sm="16">
                    <Select defaultValue={soundInputValue} style={{ minWidth: '200px' }} onChange={onHandleSoundChange}>
                        <Option value="notification.mp3">Standard</Option>
                        <Option value="notification_1.mp3">Ton 1</Option>
                        <Option value="notification_2.mp3">Ton 2</Option>
                        <Option value="notification_3.mp3">Ton 3</Option>
                    </Select>
                </Col>
            </Row>
            <Row>
                <Col span="24">
                    <Divider orientation="left" plain>
                        Klingelton
                    </Divider>
                </Col>
            </Row>
            <Row>
                <Col xs="24" sm="8" style={{ textAlign: "left" }}>
                    <label>Lautstärke</label>
                </Col>
                <Col xs="8" sm="8">
                    <Slider
                        style={{ minWidth: '100px', marginLeft: '25px' }}
                        min={0}
                        max={100}
                        onChange={onRingingChangeVolume}
                        value={typeof ringingVolumeInputValue === 'number' ? ringingVolumeInputValue : 50} />
                </Col>
                <Col xs="12" sm="4">
                    <InputNumber
                        min={0}
                        max={100}
                        style={{ margin: '0 16px' }}
                        value={ringingVolumeInputValue}
                        onChange={onRingingChangeVolume}
                    />
                </Col>
                <Col xs="4" sm="4">
                    <Button type="primary" shape="circle" icon={<SoundOutlined />} onClick={onRingingTestVolume} />
                </Col>
            </Row>
            <Row style={{ margin: '16px 0' }}>
                <Col xs="24" sm="8" style={{ textAlign: "left", marginRight: '20px' }}>
                    <label>Ton</label>
                </Col>
                <Col xs="24" sm="16">
                    <Select defaultValue={ringingSoundInputValue} style={{ minWidth: '200px' }} onChange={onHandleRingingSoundChange}>
                        <Option value="ringing_1.mp3">Standard</Option>
                    </Select>
                </Col>
            </Row>
            {
                native.isDesktop() ?
                    (
                        <>
                            <Row style={{ marginTop: "25px" }}>
                                <Col xs="24" sm="8" style={{ textAlign: "left" }}>
                                    <label>Desktop Benachrichtigungen anzeigen</label>
                                </Col>
                                <Col xs="8" sm="16" style={{ marginLeft: "25px" }}>
                                    <Switch checked={showNotification} onChange={onChangeShowNotification} />
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "25px" }}>
                                <Col style={{ textAlign: "left" }}>
                                    <Button icon={<ReloadOutlined />} onClick={onReloadClick}>
                                        Fenster neuladen
                                    </Button>
                                    <Button style={{ marginLeft: "25px" }} icon={<ToolOutlined />} onClick={onDevtoolsClick}>
                                        Entwicklerwerkzeug öffnen
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    ) : ''
            }
            {
                native.isPWA() ?
                    (
                        <>
                            <Row style={{ marginTop: "25px" }}>
                                <Col xs="24" sm="8" style={{ textAlign: "left" }}>
                                    <label>System Benachrichtigungen anzeigen</label>
                                </Col>
                                <Col xs="8" sm="16" style={{ marginLeft: "25px" }}>
                                    <Switch checked={showNotification} onChange={onChangeShowNotification} />
                                </Col>
                            </Row>
                        </>
                    ) : ''
            }
            <Divider />
            <Row>
                <Col span="24">
                    <Button type="primary" icon={<ContainerOutlined />} onClick={onChangeLogClick}>
                        Änderungsprotokoll
                    </Button>
                </Col>
            </Row >
            <Row>
                <Col span="24">
                    <div style={{ marginTop: '30px' }}>
                        <img alt='Cubes QR Code' src="/cudeqr.jpg" style={{ maxWidth: "150px" }} />
                        <Button size="large" type="primary" target="_blank" href="https://intra.sol-it.at/CubesWebService/D/UI/vuwLJE5OTk7CMw">
                            Informationen zu Croco
                        </Button>
                    </div>

                </Col>
            </Row>
            <Row>
                <Col span="24">
                    {/*<SolIT.Client.Chat.Client.Health.ServiceHealthState />*/}
                </Col>
            </Row>
            <EditUserPassword show={showEditUserPW} closeCallback={() => setShowEditUserPW(false)} userName={currentUser?.ID} />
            <ContextEdit hideContextSelect={true} model={context} show={showEditContext} closeCallback={() => setShowEditContext(false)} />
        </div >
    );
};